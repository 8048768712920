import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button, Spinner, Modal, InputGroup  } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';
import XLSX from 'xlsx';

import PsContext from '../../../context/PsContext';
import { momentDate, upperCase, typeName, startDateOfMonth, endDateOfMonth } from '../../../utils';
import { Api } from '../../../utils/Variables';

import BankAdjust from './bankAdjust';
import PSDropDown from '../components/PSDropDown';
import NoDataFound from '../components/NoDataFound';



const DayBook=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [reportDate, setReportDate] = useState(getToday());
	const [toDate, setToDate] = useState(getToday());
	const [loader, setLoader] = useState(false);
	
	const [data, setData] = useState([]);
	
	const loadReport=()=>{
		setData([]);
		
		setLoader(true);
		
		var form = new FormData();
		form.append('api', context.state.loggedApi);
		form.append('from_date',reportDate);
		form.append('to_date',reportDate);
		
		form.append('supplier', '');
		form.append('i__accountingYear',context.state.loggedAcyear.id);
		form.append('hospital_id',context.state.loggedCompany.id);
		
		
		axios.post(Api.Url+'v1/phar/daybook', form).then(res=>{
			if(res['data'].errorcode == '200'){
				setData(res['data'].data);
				setLoader(false);
			}
			else{
				//message.error(res['data'].message || 'Error');
				setLoader(false);
			}
		});
		
	};
	
	const currentAmount=(item)=>{
		if(parseFloat(item.debit_amount)>0){
			return <span className="text-danger">{item.debit_amount}</span>;
		}
		else{
			return <span className="text-success">{item.credit_amount}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {amt}</span>;
		}
		else{
			return <span className="text-danger">&#8377; {amt}</span>;
		}
	};
	
	
	
	useEffect(()=>{
		
		loadReport();
	},[]);
	
	const exportToExcel=()=>{
		var aoo = [];
		//for(var i = 0; i < 100; ++i) aoo.push({x:i, y:2 * i + Math.random()});
		data.map((item,i)=>{
			aoo.push({
				TYPE: typeName(item.voucher_type),
				TXN_NO: item.invoice_id,
				NAME: item.to_name || item.suppliername,
				DATE: item.voucher_date,
				AMOUNT: item.debit_amount>0 ? item.debit_amount : item.credit_amount,
			});
		});
		
		
		var ws = XLSX.utils.json_to_sheet(aoo);
		var wb = XLSX.utils.book_new(); XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		var today = momentDate(reportDate,'DD_MM_YYYY');
		
		XLSX.writeFile(wb, "DAY_BOOK_"+today+".xlsx");
	}
	
	return(
	<Row>
	<Col md={10} >
	
		<div className="panel panel-form">
		
			<div className="panel-header">
				<Row>
					<Col md={8} >
						Day Book
					</Col>
					<Col md={4} >
						<div className="text-right" >
							
						</div>
					</Col>
				</Row>
				
			</div>
			
			<div className="panel-body">
					
					<Row>
						<Col md={3} >
							<InputGroup size="sm" >
								<InputGroup.Text>Date</InputGroup.Text>
								<Form.Control
									type="date"
									size="sm"
									value={reportDate}
									max={getToday()}
									onChange={(e)=> setReportDate(e.target.value) }
								/>
							</InputGroup>
						</Col>
						<Col md={1} >
							<Button
								type="button"
								size="sm"
								variant="default"
								block
								onClick={()=> loadReport() }
							>
								<i className="icofont-search pe-1 me-1 border-right border-dark"></i>VIEW
							</Button>
						</Col>
						<Col md={8} >
						<div className="text-right" >
							<Button
								type="button"
								size="sm"
								variant="default"
								onClick={ e => exportToExcel() }
							>
								<i className="icofont-file-excel pe-1 me-1 border-right"></i> Export to Excel
							</Button>
						</div>
					</Col>
					</Row>
					
					<div className="table-responsive mt-10" >
						<table className="table table-striped-lite table-hover tableFixHead">
							<thead>
								<tr>
									<th  width="200" >TYPE</th>
									<th  width="110" >TXN NO</th>
									<th>NAME</th>
									<th width="180"  >DATE</th>
									<th width="120" >AMOUNT</th>
								</tr>
							</thead>
							<tbody>
							{data && data.length>0 && (data.map((item,i)=> {
								return <tr key={i} >
									<td>{typeName(item.voucher_type)}</td>
									<td>{item.invoice_id}</td>
									<td>{item.to_name || item.suppliername}</td>
									<td>{momentDate(item.voucher_date, 'DD/MM/YYYY')}</td>
									<td align="right">{currentAmount(item)}</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
				  </center>)}
				
				  <NoDataFound data={data} loader={loader} />
				
			</div>
					
		</div>
		
	</Col>
	</Row>
	);
};
export default DayBook;