import React, { Component } from 'react';


class AccountingYear extends Component{
		
	constructor(props){
		super(props);
		this.state = {
			
		}
		
	}
	
	
	render(){
		
		return(
			<div style={{position:'sticky',top:'52px',zIndex:'1', backgroundColor: '#e1e7ef', paddingTop: '10px'}} >
				<div className="row">
					<div className="col-md-12" >
						<div className="float-left">
							<b>{this.props.title}</b>
						</div>
						<div className="float-right" >
						{this.props.rightButtons}
						</div>
					</div>
				</div>
				<div className="mt-10" >
				{this.props.children}
				</div>
			</div>
		);
	}
	
}
export default AccountingYear;
