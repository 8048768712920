import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';


import PsContext from '../../../context/PsContext';


class Sidebar extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	render(){

		const menus = [
			{name: 'Item Group', to:'/pharmacy/products/item-group', exact:true},
			{name: 'Brand', to:'/pharmacy/products/brand', exact:true},
			{name: 'Items', to:'/pharmacy/products', exact:true},
		];
		
		return(
			<div className="inner-sidebar ">
				<div className="inner-sidebar-header ">
					<Row>
						<Col md={12} >
							Products
						</Col>
					</Row>
				</div>
				<div className="inner-sidebar-body px-0 py-0">
					<ul className="list-group ">
						{menus.map((item,i)=>{
							return  <NavLink to={item.to} exact={item.exact} key={i} className="list-group-item" >{item.name}
								<span style={{float: 'right'}} >
									<i className="icofont-curved-right"></i>
								</span>
							</NavLink>;
						})
						}
					</ul>
				</div>
			</div>
			
		);
	}
	
}
export default Sidebar;
