import React, { useState, useContext, useEffect } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { Row,Col, Card, Form, FormCheck, Button, Modal, Spinner, } from 'react-bootstrap';
import {Spin, message, Select} from 'antd';

import PsContext from '../../../context/PsContext';
import { getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase } from '../../../utils';
import {Api}  from '../../../utils/Variables';

const EditBatch=(props)=>{
	
	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [loader, setLoader] = useState(false);
		
	  const handleSubmit = (event) => {
		  event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {		  
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		//setValidated(false);
		setLoader(true);
		axios.post(`${Api.Url}v1/phar/batch/update`, $("#frm__cb__edit_Batch").serialize()).then(res=>{
			if(res['data'].errorcode == '200' ){
				message.success(res['data'].content || 'Success');
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				message.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});

	  };
	  
	 
	  const getValue=(field)=>{
		let val = props.editData;
		return val[field] || '';
	  };
	  
	return(
	<>
		
		<Form noValidate validated={validated} onSubmit={handleSubmit} id="frm__cb__edit_Batch" >
		
			<div className="cb__modal__body" >
			
			<input type="hidden" name="api" value={context.state.api} />
			<input type="hidden" name="i__id" value={getValue('batch_uid')} />
			<input type="hidden" name="i__pid" value={getValue('product_id')} />
			
			<Row>
				<Col md={2} ><label className="control-label" >Item Name <span className="text-danger">*</span></label></Col>
				<Col md={10}>
					<Form.Control
						name="i__itemName"
						size="sm"
						className="text-uppercase"
						required
						autoFocus={true}
						value={getValue('item_name')}
					/>
				</Col>
			</Row>
			
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Batch <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						type="text"
						name="i__batch"
						id=""
						size="sm"
						required
						defaultValue={getValue('batch')}
					/>
				</Col>
				
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Expiry <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						type="month"
						name="i__expiry"
						id=""
						size="sm"
						required
						defaultValue={getValue('expiry')}
					/>
				</Col>
				
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >PUR. Rate <span className="text-danger">*</span></label></Col>
				<Col md={3}>
					<Form.Control
						name="i__purchaseRate"
						id="i__purchaseRate"
						size="sm"
						className="text-right"
						required
						onKeyPress={decimalKeyPress}
						defaultValue={getValue('purchase_rate')}
					/>
				</Col>
				
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >MRP <span className="text-danger">*</span></label></Col>
				<Col md={3}>
					<Form.Control
						name="i__mrp"
						id="i__mrp"
						size="sm"
						className="text-right"
						onKeyPress={decimalKeyPress}
						defaultValue={getValue('mrp')}
						required
					/>
				</Col>
				
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Sale Rate <span className="text-danger">*</span></label></Col>
				<Col md={3}>
					<Form.Control
						size="sm"
						name="i__salesPrice"
						id="i__salesPrice"
						className="text-right"
						defaultValue={getValue('sales_rate')}
						required
					/>
				</Col>
				
			</Row>
			
			
			
			</div>
			
			<div className="cb__modal__footer" >
				<div className="text-right" >
					<Button
						type="submit"
						size="sm"
						variant="default"
						disabled={loader}
					>
					{loader ? <>
						<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
					</> : <>
						<i className="icofont-check pe-2 border-right" ></i> UPDATE ITEM
					</>}
					</Button>
				</div>
			</div>
				
	
		</Form>
	</>
	);
};
export default EditBatch;