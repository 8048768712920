import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Spin, message, Select } from 'antd';
import PsContext from '../../../context/PsContext';

import NewSalesEntryScreen from './NewSalesEntryScreen';
import SalesView from './SalesView';

class Sales extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showPrintModal: false,
			salesList : [],
			billid : '',
		}
		this.loadData = this.loadData.bind(this);
	}
	
	showPrint(billid){
		
		this.setState({showPrintModal : true, billid : billid});
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('date','1');

			axios.post(Api.Url+'v1/phar/sales/listall', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({salesList : res['data'].data,showLoader : false});
				}
				else{
					//message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		
		return(
			<div className="" >
				
					<Row>
						<Col md={10} >
					<NewSalesEntryScreen afterFinish={this.loadData} {...this.props} />
				</Col>
			</Row>
					
					{/*<Modal show={this.state.showPrintModal} onHide={() => {this.setState({billid : '' ,showPrintModal : false})} } backdrop="static" className="cb__modal" size="lg" >
						<Modal.Header closeButton >
							Print
						</Modal.Header>
						<Modal.Body>
							<SalesView billid={this.state.billid} afterFinish={() => {this.setState({billid : '' ,showPrintModal : false})} } autoPrint />
						</Modal.Body>
					</Modal>*/}
				
				
			</div>
		);
	}
	
}
export default Sales;
