import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../../utils';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {Spin, message, Table} from 'antd';

import PsContext from '../../../../context/PsContext';
import Sidebar from '../Sidebar';
import AddTax from './AddTax';
import EditTax from './EditTax';

class TaxSettings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddModal :false,
			dataList: [],
			dataView: [],
			paginationPageSize : 20,
			currentPage: 1,
			showEditModal: false,
			editData: [],
		}
		
		this.handleAddModalOpen = this.handleAddModalOpen.bind(this);
		this.handleAddModalClose = this.handleAddModalClose.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handleEditModalClose = this.handleEditModalClose.bind(this);
		
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleTablePageChange = this.handleTablePageChange.bind(this);
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}
	
	handleTablePageChange(current){
		this.setState({cureentPage: current});
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	handleAddModalOpen(){
		this.setState({showAddModal: true});
	}
	
	handleAddModalClose(){
		this.setState({showAddModal: false});
	}
	
	loadData()
	{
		try{
			
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/phar/tax/listall?api='+this.context.state.api+'&status=all').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
						showEditModal: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearch(e){
		try{
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var s = d.filter(function(obj){
				return( (upperCase(obj.tax_value).indexOf(v)>-1) ||
				(upperCase(obj.tax_name).indexOf(v)>-1) );
			});
			this.setState({dataView: s});
		}
		catch(er){console.log(er); }
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to delete the record ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/phar/tax/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showLoader: false});
		}
	}
	
	handleEditModalOpen(record){
		this.setState({
			editData: record,
			showEditModal: true,
		});
	}
	
	handleEditModalClose(){
		this.setState({
			editData: [],
			showEditModal: false,
		});
	}
	
	render(){
		
		const columns = [
		  {
			title: 'S.No',
			dataIndex: 'id',
			key: 'id',
			width: '60px',
			render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
			)
		  },
		  {
			title: 'Tax Name',
			dataIndex: 'tax_name',
			key: 'tax_name',
		  },
		  {
			title: 'Tax Value',
			dataIndex: 'tax_value',
			key: 'tax_value',
		  },
		  {
			title : 'Manage',
			dataIndex : 'id',
			key : 'id',
			width: '130px',
			render : (text,record)=>(
				<center>
					<Button type="button" size="xs" variant="purple" onClick={this.handleEditModalOpen.bind(this, record)} > 
						<i className="icofont-edit"></i>
					</Button>
					&nbsp;
					<Button type="button" size="xs" variant="default" onClick={this.handleDeleteActionClick.bind(this, record)} > 
						<i className="icofont-trash"></i>
					</Button>
				</center>
			)
		  }
		];
		
		return(
			<React.Fragment>
				<div className="panel panel-form" >
				
				
					<div className="panel-header" >
						<Row>
							<Col md={7} >
								Tax
							</Col>
							<Col md={5} >
								<div className="text-right" >
									<Button
										type="button"
										size="sm" 
										variant="primary"
										onClick={this.loadData} 
									>
										<i className="icofont-refresh pe-2 border-right"></i> Refresh
									</Button>
									&nbsp;
									<Button
										type="button"
										size="sm" 
										variant="primary"
										onClick={this.handleAddModalOpen} 
									>
										<i className="icofont-plus pe-2 border-right"></i> New
									</Button>
								</div>
							</Col>
						</Row>
					</div>
					
					<div className="panel-body" >
						
						<Row>
							<Col md={4} >
								<Form.Control 
									type="text"
									size="sm"
									placeholder="Search by Tax Name & Value.."
									onChange={this.handleSearch} 
								/>
							</Col>
						</Row>
						<div className="ps-table" >
							<Table 
							size="small"
							columns={columns} 
							bordered
							footer={() => `Total ${Object.keys(this.state.dataView).length} records in list`} 
							loading={this.state.showLoader}
							dataSource={this.state.dataView}
							rowClassName={ (record, index) => {  return record.active_status=='0' ? 'bg-danger-light' : null; }   }
							pagination={{ 
								pageSize: this.state.paginationPageSize,
								onShowSizeChange: this.handlePaginationSizeChange,
								position: ['bottomRight'],
								onChange: this.handleTablePageChange,
							}} />
						</div>
					
					</div>
				
				</div>
				
				
				<Modal show={this.state.showAddModal} onHide={this.handleAddModalClose} backdrop="static" className="cb__modal" >
					<Modal.Header closeButton >
						Add Tax
					</Modal.Header>
					<Modal.Body>
						<AddTax afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditModal} onHide={this.handleEditModalClose} backdrop="static" className="cb__modal" >
					<Modal.Header closeButton >
						Edit Tax
					</Modal.Header>
					<Modal.Body>
						<EditTax editData={this.state.editData} afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default TaxSettings;
