import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';

import { upperCase } from '../../../utils';
import AddBatch from './AddBatch';
import useKeypress from './useKeypress';

const SearchBatch=(props)=>{
	
	const propsBatchs=()=>{
		
		return props.batches || [];
	};
	
	const [batches, setBatches] = useState(propsBatchs());
	const [productView, setProductView] = useState(propsBatchs());
	const [currentRow, setCurrentRow] = useState(0);
	const [addBatch, setAddBatch] = useState(false);
			
	const handleKeyDownPress=()=>{		
		
		if(currentRow < productView.length-1){	
			setCurrentRow((currentRow) => currentRow + 1);		
			if(currentRow>7)
			{
				var row = (currentRow - 8);
				$("tr.row-"+row).hide();				
			}
		}
	};
	
	const handleKeyUpPress=()=>{
		
		if(parseFloat(currentRow) > 0){	
			setCurrentRow((currentRow) => currentRow - 1);	
			if(currentRow>6)
			{
				var row = (currentRow - 7);
				$("tr.row-"+row).show();
			}
		}
	};
	
	const handleSearch=(e)=>{
		try{
			$("#tbl_batch tbody tr").show();
			//this.setState({productView: []});
			var v = upperCase(e.target.value);
			var d = batches;
			
			if(v=='' || v.length < 1 ){
				setProductView(d);
				return;
			}
			var s = d.filter(function(obj){
				return( (upperCase(obj.batch).indexOf(v)>-1));
			});
			
			setProductView(s);
			setCurrentRow(0);
		}
		catch(error){ console.log(error); }
	}
	
	const handleEnterButtonPress=()=>{
		try{
			var d = productView;
			var selectedItem = d[currentRow];
			
			if(props.afterFinish)
				props.afterFinish(selectedItem);
		}
		catch(er){ }
	};
	
	const addBatchClose=(bt=false)=>{

		if( !bt ){
			document.addEventListener('keydown', keyPressHandler);
			setCurrentRow((currentRow) => 0);	
			setAddBatch(false);
			return;
		}
		
		if(props.afterFinish){
			document.removeEventListener('keydown', keyPressHandler);
			props.afterFinish(bt);
		}
	};
	
	useEffect(()=>{
		$("#txt__cb__batch__mod__search").focus();
	},[]);
	
	const keyPressHandler = (e) => {
		 
		 if(e.keyCode===70 && e.ctrlKey){ //focus on search
			e.preventDefault(0);
			$("#txt__cb__batch__mod__search").focus();
		}
		else if(e.keyCode==40){ // Handle down arrow press
			handleKeyDownPress();
		}
		else if(e.keyCode==38){ // Handle up arrow press
			handleKeyUpPress();
		}
		else if(e.keyCode==13){ // Handle Enter button press
			document.removeEventListener('keydown', keyPressHandler);
			handleEnterButtonPress();
		}
		else if(e.keyCode == 113 && !props.sales){
			document.removeEventListener('keydown', keyPressHandler);
			setAddBatch(true);
		}
	};
	
  useEffect(() => {
		 
	 document.addEventListener('keydown', keyPressHandler);
	 
	 return () => {
		document.removeEventListener('keydown', keyPressHandler);
	 };
  }, [currentRow]);
  

	return(
	<>
		<div className="cb__modal__header" >
			<Row>
				<Col md={10} >
					{props.item.item_name} > Search Batch
				</Col>
				<Col md={2} >
					<div className="text-right" >
						Esc
					</div>
				</Col>
			</Row>
		</div>
		<div className="cb__modal__body" style={{height: '410px' }} >
			<table className="table table-bordered tableFixHead " id="tbl_batch" >
				<thead>
					<tr>
						<th>BATCH</th>
						<th>EXPIRY</th>
						<th width="90"  >MRP</th>
						<th width="90"  >RATE</th>
						<th width="60" >STOCK</th>
						<th width="60" >#</th>
					</tr>
				</thead>
				<tbody>
				{productView && productView.length>0 && productView.map((item,i)=>{
					return <tr key={i} className={currentRow==(i) ? `row-${i} tr-selected` : `row-${i}`}  >
						<td>{item.batch}</td>
						<td>{item.expiry}</td>
						<td align="right" >{item.mrp}</td>
						<td align="right" >{props.sales ? (item.piece_sale_rate || item.mrp) : item.purchase_rate}</td>
						<td align="right" >{item.stock}</td>
						<td align="center" >
							<Button
								type="button"
								size="xs"
								variant="default"
								onClick={()=> handleEnterButtonPress() }
							>
								<i className="icofont-pencil-alt-2"></i>
							</Button>	
						</td>
					</tr>;
				})
				}
				</tbody>
			</table>
		</div>
		<div className="cb__modal__footer d-flex flex-row" >
			{!props.sales && (<Button
				type="button"
				size="sm"
				variant="default"
				className="me-2" 
				onClick={()=> setAddBatch(true) }
			>
				F2 | Add Batch
			</Button>)}
			<Button
				type="button"
				size="sm"
				variant="default"
				className="me-2" 
			>
				Show All Batches
			</Button>
			<Form.Control 
				type="text"
				size="sm"
				style={{ width: '60%' }} 
				id="txt__cb__batch__mod__search"
				placeholder=" [CTRL + F]  Search Bathces.."
				onChange={(e)=> handleSearch(e) }
				autoFocus={true}
			/>
		</div>
		
		<Modal show={addBatch} className="cb__modal cb__modal__search" >
			<Modal.Body>
				<AddBatch 
					productDetail={props.item}
					afterFinish={addBatchClose}
				/>
			</Modal.Body>
		</Modal>
	</>
	);
};

export default SearchBatch;