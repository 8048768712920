import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';

import PharmacyLayout from '../modules/Admin/Layout';
import EmptyLayout from '../modules/Admin/EmptyLayout';
import Login from '../views/pages/Login';

import Account from '../views/pages/account';

export default () => (

  <HashRouter>
    <Switch>
      
		<Route path="/app/pos" component={EmptyLayout} />
		<Route path="/app" component={PharmacyLayout} />
	
		<Route path="/account/:type" component={Account} />	
		
		<Route path="/" component={Login} />	

    </Switch>
  </HashRouter>

);
