import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Spinner, Card } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';
import { Api } from '../../../utils/Variables';

const ZeroStock=()=>{
	
	const context = useContext(PsContext);
	const [loader, setLoader] = useState(false);
	
	const [data, setData] = useState([]);
		
	const loadCount=()=>{
		setLoader(true);
		axios.get(`${Api.Url}v1/phar/reports/stock_lessthan_min?api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setData(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadCount();
	},[]);
	
	
	return(
	<>
		<div className="panel panel-form" >
			<div className="panel-header">
				Stock less than Minimum Qty
			</div>
			<div className="panel-body" >
				<div className="tableFixHead">
					<table className="table table-sm">
						<thead>
							<tr>
								<th>Item Name</th>
								<th width="60" >Stock</th>
							</tr>
						</thead>
						<tbody>
						{data.map((item,i)=>{
							return <tr key={i} >
								<td>{item.item_name}</td>
								<td>{item.stock}</td>
							</tr>;
						})
						}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</>
	);
};

export default ZeroStock;