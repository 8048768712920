import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, upperCase } from '../../../utils';
import { Api } from '../../../utils/Variables';
import SalesView from '../Sales/SalesView';

import AddPaymentOut from './addPaymentOut';
import NoDataFound from '../components/NoDataFound';

const PaymentOut=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [fromDate, setFromDate] = useState(getToday());
	const [toDate, setToDate] = useState(getToday());
	const [loader, setLoader] = useState(false);
	const [cashInHand, setCashInHand] = useState(0);
	const [data, setData] = useState([]);
	
	const [billId, setBillId] = useState(null);
	const [print, setPrint] = useState(false);
	const [view, setView] = useState(false);
	const [add, setAdd] = useState(false);
	
	const loadReport=()=>{
		setData([]);
		setLoader(true);
		
		var form = new FormData();
		form.append('api', context.state.loggedApi);
		form.append('from_date',fromDate);
		form.append('to_date', toDate);
		
		axios.post(Api.Url+'v1/phar/paymentout/all', form).then(res=>{
			if(res['data'].errorcode == '200'){
				setData(res['data'].data);
				setLoader(false);
			}
			else{
				
				setLoader(false);
			}
		});
		
	};
	
	const currentAmount=(item)=>{
		if(parseFloat(item.credit_amount)>0){
			return <span className="text-danger">{item.credit_amount}</span>;
		}
		else{
			return <span className="text-success">{item.debit_amount}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {Math.abs(amt).toFixed(2)} (Cr)</span>;
		}
		else{
			return <span className="text-danger">&#8377; {Math.abs(amt).toFixed(2)} (Dr)</span>;
		}
	};
	
	useEffect(()=>{
		
		loadReport();
	},[]);
	
	const removePayment=(record)=>{
		if(!window.confirm('Do you want to remove?')){
			return false;
		}
		setLoader(true);
		var form = new FormData();
		form.append('api', context.state.api);
		form.append('uuid', record.uuid);
		axios.post(`${Api.Url}v1/phar/paymentout/remove`,form).then(res=>{
			if(res['data'].errorcode=='200'){
				toast.success(res['data'].content || 'Success');
				loadReport();
			}
			else{
				toast.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});
	}
	
	return(
	<Row>
		<Col md={10} >
		
		<div className="panel panel-form">
		
			<div className="panel-header">
				<Row>
					<Col md={8} >
						Payment Out
					</Col>
					<Col md={4} >
						<div className="text-right" >
							<Button 
								type="button"
								size="sm"
								variant="primary"
								onClick={()=> setAdd(true) }
							>
								<i className="icofont-plus pe-2 border-right"></i> Add Payment Out
							</Button>
						</div>
					</Col>
				</Row>
				
			</div>
			
			<div className="panel-body">
					
				<Row>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={fromDate}
							max={getToday()}
							onChange={(e)=> setFromDate(e.target.value) }
						/>
					</Col>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={toDate}
							max={getToday()}
							onChange={(e)=> setToDate(e.target.value) }
						/>
					</Col>
					<Col md={1} >
						<Button
							type="button"
							size="sm"
							variant="default"
							block
							onClick={()=> loadReport() }
						>
							VIEW
						</Button>
					</Col>
				</Row>
					
					<div className="table-responsive mt-10" >
						<table className="table table-striped-lite table-hover tableFixHead">
							<thead>
								<tr>
									<th width="60" >S.NO</th>									
									<th>DATE</th>
									<th>REF.NO</th>
									<th>PARTY NAME</th>
									<th>TYPE</th>
									<th width="120"  >TOTAL</th>
									<th width="120"  >REC/PAID</th>
									<th width="120"  >BALANCE</th>
									<th width="100" >MANAGE</th>
								</tr>
							</thead>
							<tbody>
							{data && data.length>0 && (data.map((item,i)=> {
								return <tr key={i} >
									<td>{i+1}</td>
									<td>{momentDate(item.payment_date, 'DD/MM/YYYY')}</td>
									<td>{item.ref_no}</td>
									<td>{item.to_name}</td>
									<td>{item.from_name}</td>
									<td align="right">{item.total}</td>
									<td align="right">{item.amount}</td>
									<td align="right">{showCashInHand(item.balance)}</td>
									<td align="center">
										<Button type="button" size="xs" variant="default" onClick={()=> removePayment(item) } >
											<i className="icofont-trash"></i>
										</Button>
									</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
					 </center>)}
					
					<NoDataFound data={data} loader={loader} />
				
			</div>
			
		
			
			 {print && (<SalesView 
				billid={billId} 
				afterFinish={()=>{
					setBillId(null);
					setPrint(false);
				}}
				autoPrint 
			/>)}
			
			<Modal show={view} size="lg" onHide={()=>{
				setBillId(null);
				setView(false);
			}} >
				<Modal.Header closeButton>
					VIEW
				</Modal.Header>
				<Modal.Body>
					<SalesView 
						billid={billId} 
						afterFinish={()=>{
							setBillId(null);
							setView(false);
						}}								
					/>
				</Modal.Body>
			</Modal>
			
			<Modal show={add} onHide={()=> setAdd(false) } className="cb__modal__search" size="md" backdrop="static" >
				<Modal.Header closeButton >
					Add Payment Out
				</Modal.Header>
				<Modal.Body>
					<AddPaymentOut afterFinish={()=> loadReport() } />
				</Modal.Body>
			</Modal>
			
		</div>
		
		</Col>
	</Row>
	);
};
export default PaymentOut;