import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { Table } from 'antd';

import $ from 'jquery';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Spin, message, Select } from 'antd';
import{ LineLoader} from '../../../views/elements';
import PsContext from '../../../context/PsContext';

import NewPurchaseEntryScreen from './NewPurchaseEntryScreen';
import PurchasePrint from './PurchasePrint';
import PurchasePaymentView from './PurchasePaymentView';

const { Option } = Select;

class PurchasePaymentEntry extends Component{


static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showPrintModal: false,
			showViewModal: false,
			billid : '',
			fromDate : '',
			supplier : '',
			supplierList: [],
			dataList: [],
			dataView: [],
			checkedrows: [],
			totalAmount : 0,
			paginationPageSize : 20,
			currentPage: 1,
		}
		this.handleSearch = this.handleSearch.bind(this);
		this.loadData = this.loadData.bind(this);
		this.loadSuppliers = this.loadSuppliers.bind(this);
		this.handleSupplierChange = this.handleSupplierChange.bind(this);
		//this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleTablePageChange = this.handleTablePageChange.bind(this);
		this.onFromDateChange = this.onFromDateChange.bind(this);
	}  
	
	onViewClick(){
		this.loadData()
	}
	
	clickAfterFinish(){
		
		this.setState({checkedrows:[] ,showViewModal : false, totalAmount : 0});
		this.loadData();
	}
	
	onFromDateChange(e){
		
		$("#todate").val(momentDate((new Date()),'YYYY-MM-DD'));
		this.setState({fromDate : e.target.value});
		
	}
	
	showPrint(billid){
		
		this.setState({showPrintModal : true, billid : billid});
		
	}
	
	showView(billid){
		
		this.setState({showViewModal : true});
		
	}

	handleTablePageChange(current){
		this.setState({currentPage: current});
	}
	
	handleSupplierChange(value){
		var s = this.state.supplierList;
		var sdata = s.filter(function(obj){
			return(obj.id == value);
		});
		if(sdata.length > 0) {
			this.setState({selectedSupplier : sdata[0]});
		}
		this.setState({supplier : value});
	}

	componentDidMount(){
		this.loadSuppliers();
	} 

	loadData(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('from_date',$("#fromdate").val());
			form.append('to_date',$("#todate").val());
			form.append('date_filter',"1");
			form.append('supplier',this.state.supplier);
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('hospital_id',getls('loggedCompany','id'));
			this.setState({dataList : [],dataView : []});
			axios.post(Api.Url+'v1/phar/purchase/credit', form).then(res=>{
				if(res['data'].status=='1'){
				this.setState({purchaseList : res['data'].data,showLoader : false});
					this.setState({
					dataList: res['data'].data,
					dataView: res['data'].data,
					showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	loadSuppliers(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));

			axios.post(Api.Url+'v1/phar/get_supplier', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({supplierList : res['data'].data,showLoader : false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}


	handleSearch(e){
		try{
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var s = d.filter(function(obj){
			return( (upperCase(obj.billno).indexOf(v)>-1) );
			});
			this.setState({dataView: s});
		}
		catch(er){console.log(er); }
	}

	viewData(record,e){
		var newArr = this.state.checkedrows;
		var amount = this.state.totalAmount;
		if(e.target.checked){
			newArr.push({"supplier" : record.supplier,"billid" : record.billid , "billno" : record.billno ,  "amount" : record.net_amount, "billdate" : record.billdate});
			amount+=parseFloat(record.net_amount);
		}
		else{
			var index = newArr.findIndex(function(obj) {
				return(obj.billid==record.billid);
			});
			if(index >-1){
				newArr.splice(index,1);
			}
			amount-=parseFloat(record.net_amount);
		}
		this.setState({ checkedrows: newArr , totalAmount: amount });
	}

	render(){

		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render : (text,record)=>(
					<center>
						<input type="checkbox" onChange={this.viewData.bind(this,record)} />
					</center>
				)
			},

			{
				title: 'S.No',
				dataIndex: 'id',
				key: 'id',
				render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
				)
			},
			
			{
				title: 'Bill No',
				dataIndex: 'billno',
				key: 'billno',
			},
			
			{
				title: 'Bill Date',
				dataIndex: 'billdate',
				key: 'billdate',
			},
			
			
			{
				title: 'Supplier Name',
				dataIndex: 'suppliername',
				key: 'suppliername',
			},
			{
				title: 'Supplier Mobile',
				dataIndex: 'mobile',
				key: 'mobile',
			},
			
			
			{
				title: 'Amount',
				dataIndex: 'net_amount',
				key: 'net_amount',
			},
			
			{/*
				title : 'Manage',
				dataIndex : 'id',
				key : 'id',
				width: '150px',
				render : (text,record)=>(
					<center>
						 <Button type="button" size="xs" variant="warning" onClick={this.showPrint.bind(this,record.billid)}>
							<i className="icofont-print"></i>
							</Button>&nbsp; 
						<Button type="button" size="xs" variant="success" onClick={this.showView.bind(this,record.billid)}>
							<i className="icofont-credit-card"></i>
						</Button>
					</center>
				)
			*/}
		];


		return(
			<div className="px-15 py-15">
				<LineLoader loading={this.state.showLoader} />
				<Card style={{position:'sticky',top:'56px',zIndex:'1',width:'100%'}} >
					<Card.Body>
						<Row>
						 <Col md={2}>
										<Select
										  showSearch
										  style={{ width: 180 }}
										  title="Supplier"
										  placeholder="Select a Supplier"
										  optionFilterProp="children"
										  onChange={this.handleSupplierChange}
										  style={{width: '100%'}}
										  value={this.state.supplier}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.supplierList && this.state.supplierList.map((item) => (
												<Option key={item.id} value={item.id}>{item.name}</Option>
										  ))}
										</Select>
										</Col> 
							<Col md={3}>
										<table width="100%" >
									<tr>		
										<td>
											<label className="font-13">From Date</label>
										</td>
										<td>
											<Form.Control
											type="date"
											size="sm"
											name="i__billDate"
											id="fromdate"
											max={momentDate((new Date()),'YYYY-MM-DD')}
											onChange={this.onFromDateChange}
											/>
										</td>
									</tr>
								</table>
							</Col>
							<Col md={3}>
								<table width="100%" >
									<tr>
										<td>
											<label className="font-13">To Date</label>
										</td>
										<td>
											<Form.Control
											type="date"
											size="sm"
											name="i__billDate"
											id="todate"
											min={momentDate((this.state.fromDate),'YYYY-MM-DD')}
											max={momentDate((new Date()),'YYYY-MM-DD')}
											/>
										</td>
									</tr>
								</table>
							</Col>
							<Col md={1}>
								<Button
									type="button"
									size="sm"
									variant="primary"
									onClick={this.onViewClick.bind(this)}
								>
								View
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				<div className="mt-10 ps-table" >
					<Table
						size="small"
						columns={columns}
						bordered
						//footer={() => `Total ${Object.keys(this.state.dataView).length} Purchase in list`}
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						rowClassName={ (record, index) => {  return record.status=='0' ? 'bg-danger-light' : null; }   }
						pagination={false} />
				</div>
				<div>
					{this.state.totalAmount >0 ? <span className="mt-10" style={{float: 'right'}}><Button variant="success" size="sm" onClick={this.showView.bind(this,'show')}>Pay Now</Button></span> : ''} 
					{this.state.totalAmount >0 ?
					<span className="mt-10" style={{float:'right' , width: '200px'}}> <h3><b>Amount:</b> {this.state.totalAmount}</h3> </span>
					: ''}
				</div>
				<Modal show={this.state.showViewModal} onHide={() => {this.setState({billid : '' ,showViewModal : false})} } backdrop="static" className="cb__modal" size="lg" >
					<Modal.Header closeButton >
						View
					</Modal.Header>
					<Modal.Body>
						<PurchasePaymentView dataView={this.state.checkedrows} payment={true} afterFinish={this.clickAfterFinish.bind(this)} />
					</Modal.Body>
                </Modal> 
			</div>
		);
	}
}

export default PurchasePaymentEntry;