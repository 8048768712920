import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal, } from 'react-bootstrap';
import{ Radio, Spin, message, DatePicker, Checkbox } from 'antd';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, nameWithDotAndHypen, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, integerGst
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import moment from 'moment';

import PsContext from '../../../context/PsContext';

class AddCustomer extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save',
			showLoader: false,
			showAddModal: false,
			balanceType: 'receive',
			openingDate: '',
			openingBalance: '',
			openingDateMoment: '',
			showInPurchase: false,
		}
		
		this.handleBalanceChange = this.handleBalanceChange.bind(this);
		this.handleOpeningDateChange = this.handleOpeningDateChange.bind(this);
		this.handleShowPurchaseChange = this.handleShowPurchaseChange.bind(this);
		this.handleOpeningBalanceChange = this.handleOpeningBalanceChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleBalanceChange(e){
		
		this.setState({balanceType: e.target.value});
	}
	
	handleOpeningDateChange(date, dateString){
		
		this.setState({openingDateMoment: date, openingDate: dateString});
	}
	
	handleShowPurchaseChange(e){
		
		this.setState({showInPurchase: e.target.checked});
	}
	
	handleOpeningBalanceChange(e){
		
		this.setState({openingBalance: e.target.value});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if($("#cb__txtCustomerMobile").val().length <10){
				message.error('Enter Valid Mobile No...' || 'Error');
				return;
			}
			/* if($("#cb__customerGstNo").val().length <15){
				message.error('Enter Valid GST No...' || 'Error');
				return;
			} */
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/phar/customer/save', $("#cb__frmAddCustomer").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Error');
					document.getElementById("cb__frmAddCustomer").reset();
					this.setState({
						balanceType: 'receive',
						openingDate: '',
						openingBalance: '',
						openingDateMoment: '',
						showInPurchase: false,
						showLoader: false,
					});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		return(
			<>
				<form 
					action=""
					method="post"
					encType="multipart/form-data"
					id="cb__frmAddCustomer"
					onSubmit={this.handleFormSubmit} 
				>
				{/*<div className="cb__modal__header" >
					<Row>
						<Col md={10}>Add Party</Col>
						<Col md={2}>
							<div className="text-right" >ESC</div>
						</Col>
					</Row>
				</div>*/}
				<div className="cb__modal__body" >
				<Spin spinning={this.state.showLoader} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="acyear" value={this.context.state.loggedAcyear.id} />
					<input type="hidden" name="cb__openingBalance" value={this.state.openingBalance} />
					<input type="hidden" name="cb__openingType" value={this.state.balanceType} />
					<input type="hidden" name="cb__openingDate" value={this.state.openingDate} />
					<input type="hidden" name="cb__showInPurchase" value={this.state.showInPurchase ? '1' : '0'} />
					
					<Row>
						<Col md={7} >
							<label className="" >Party Name <span className="text-danger">*</span> </label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__txtCustomerName"
								id="cb__txtCustomerName"
								className="text-uppercase"
								required
								onKeyPress={nameWithDotAndHypen}
							/>
						</Col>
						<Col md={5} >
							<label className="" >Phone No <span className="text-danger">*</span></label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__txtCustomerMobile"
								id="cb__txtCustomerMobile"
								onKeyPress={integerIndMobile}
								required
							/>
						</Col>
					</Row>

					<Row className="mt-" >
						<Col md={7} >
							<label className="" >Place <span className="text-danger">*</span> </label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__txtCustomerPlace"
								id="cb__txtCustomerPlace"
								className="text-uppercase"
								required
								onKeyPress={nameWithDotAndHypen}
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={7} >
							<label className="" >Billing Address</label>
							<Form.Control
								as="textarea"
								size="sm"
								rows="3"
								name="cb__txtBillingAddress"
								id="cb__txtBillingAddress"
							/>
						</Col>
						<Col md={5} >
							<label className="" >GST.No</label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__customerGstNo"
								id="cb__customerGstNo"
								//onKeyPress={integerGst}
							/>
						</Col>
						<Col md={7} >
							<label className="" >Email Id</label>
							<Form.Control
								type="email"
								size="sm"
								name="cb__customerEmail"
								id="cb__customerEmail"
							/>
						</Col>
					</Row>
			
			
					<Row className="mt-15" >
						<Col md={7} >
							<label className="" >Opening Balance</label>
							<Form.Control
								type="number"
								step="any"
								size="sm"
								name="cb__customerOpeningAmount"
								id="cb__customerOpeningAmount"
								value={this.state.openingBalance}
								onChange={this.handleOpeningBalanceChange}
							/>
						</Col>
						<Col md={5} >
							<label className="" >As Of Date</label>
							<DatePicker
								value={this.state.openingDateMoment}
								onChange={this.handleOpeningDateChange} 
								style={{width: '100%'}} 
								disabledDate={(current) => {
								  let customDate = moment().add(1, 'days').format("YYYY-MM-DD");
								  return current > moment(customDate, "YYYY-MM-DD");
								}} 
							/>
						</Col>
						{this.state.openingBalance ? <Col md={7} className="mt-15" >
							  <Radio.Group value={this.state.balanceType} onChange={this.handleBalanceChange} >
							  <Radio value="receive" >To Receive</Radio>
							  <Radio value="pay" >To Pay</Radio>
							</Radio.Group>
						</Col> : null}
						<Col md={7} className="mt-15 font-12" >
							<Checkbox 
							onChange={this.handleShowPurchaseChange}	
							checked={this.state.showInPurchase} >
								Show In Purchase Entry
							</Checkbox >
						</Col>
					</Row>
									
				</Spin>
			</div>
			
			<div className="cb__modal__footer" >			
				<Button
					type="submit"
					size="sm"
					variant="default"
					disabledDate={this.state.showLoader}
				>
					<i className="icofont-check pe-2 border-right"></i> Save Party
				</Button>		
			</div>
				
			</form>
			</>
		);
	}
	
}
export default AddCustomer;