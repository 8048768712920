import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

class ModalCreate extends Component{
		
	constructor(props){
		super(props);
		this.state = {
			
		}
		
		
	}
	
	cancelClick(){
		if(this.props.cancelClick)
			this.props.cancelClick();
	}
	
	okClick(){
		if(this.props.okClick)
			this.props.okClick();
	}
	
	onClose(){
		if(this.props.onClose)
			this.props.onClose();
	}
	
	render(){
		
		return(
			<div >
				<Modal 
					show={this.props.show} 
					onHide={this.onClose.bind(this)} 
					className="cb__modal"  
					backdrop="static" 
					size={this.props.size || 'md'}
				>
				<Modal.Header closeButton >
						{this.props.title}
				</Modal.Header>
					<Modal.Body style={{ maxHeight: 'calc(80vh)', overflowY: 'auto' }} >
						{this.props.component}
						{this.props.content}

						{!this.props.noButtons && (<Row className="mt-20" >
						<Col md={12} >
							<div className="text-right" >
							{this.props.type=='confirm' && (<>
								<Button type="button" size="sm" variant="light" className="rounded-5 px-10" onClick={this.cancelClick.bind(this)}  >
									{this.props.cancelText || 'Cancel'}	
								</Button>
								&nbsp;
								<Button type="button" size="sm" className="rounded-5 px-10" onClick={this.okClick.bind(this)}  >
									{this.props.okText || 'Ok'}
								</Button>
							</>)}
							{this.props.type != 'confirm'&& (
								<Button type="button" size="sm" className="rounded-5 px-10" onClick={this.okClick.bind(this)} >
									{this.props.okText || 'Ok'}
								</Button>
							)}
							</div>
						</Col>
						</Row>)}
					
					</Modal.Body>
				</Modal>
			</div>
		);
	}
	
}
export default ModalCreate;
