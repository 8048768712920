import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';

const CategoryName=(props)=>{
	
	const context = useContext(PsContext);
	
	const { item, currentCategory } = props;
	
	const handleClick=()=>{
		if(props.onClick)
			props.onClick(item);
	};
	
	return(
		<>
		<Button 
			className="btn-block" 
			variant={`${currentCategory==item.item_group ? 'secondary' : 'outline-dark'} mt-2`} 
			onClick={e=>handleClick()}
			>
				{item.surname || item.group_name}
		</Button>
		</>
	);
};
export default CategoryName;