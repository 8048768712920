import React, { Component } from 'react';
import './index.css';

class LineLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: this.props.loading,
    };
  }

  render() {
    if (!this.props.loading) {
	   return ('');
    }
    return (
      <div>
        <div className="line-loader-container">
          <div className="line-loader" />
        </div>

        {this.props.showText ? <div className="line-loader-text-container">
          Loading..
        </div> : null}
      </div>
    );
  }
}
export default LineLoader;
