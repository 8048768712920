import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, upperCase } from '../../../utils';
import { Api } from '../../../utils/Variables';

const StockSummary=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [fromDate, setFromDate] = useState(getToday());
	const [toDate, setToDate] = useState(getToday());
	const [loader, setLoader] = useState(false);
	const [cashInHand, setCashInHand] = useState(0);
	const [data, setData] = useState([]);
	const [viewData, setViewData] = useState([]);
	
	const [billId, setBillId] = useState(null);
	const [print, setPrint] = useState(false);
	const [view, setView] = useState(false);
	
	const loadReport=()=>{
		setData([]);
		setViewData([]);
		setLoader(true);
		axios.get(Api.Url+'v1/phar/reports/stock_summary?api='+context.state.loggedApi).then(res=>{
			if(res['data'].errorcode=='200'){
				setData(res['data'].data);
				setViewData(res['data'].data);
				//setCashInHand(res['data'].cash_in_hand);
				setLoader(false);
			}
			else{
				message.error(res['data'].message || 'Error');
				setLoader(false);
			}
		});
		
	};
	
	const showStock=(item)=>{
		if(parseFloat(item.stock)<0){
			return <span className="text-danger">{item.stock}</span>;
		}
		else{
			return <span className="text-success">{item.stock}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {amt}</span>;
		}
		else{
			return <span className="text-danger">&#8377; {amt}</span>;
		}
	};
	
	useEffect(()=>{
		
		loadReport();
	},[]);
	
	const handleSearch=(e)=>{
		var v = upperCase(e.target.value);
		var s = data.filter(function(obj){
			return(upperCase(obj.item_name).indexOf(v)>-1);
		});
		setViewData(s);
	};
	
	const sumValues=(field)=>{
		
		var t = 0;
		viewData.map((item,i)=>{
			t = parseFloat(t)+parseFloat(item[field]);
		});
		return parseFloat(t).toFixed(2);
	}
	
	return(
	<Row>
		<Col md={10} >
		
		<div className="panel panel-form">
		
			<div className="panel-header">
				<Row>
					<Col md={6} >
						Stock Summary
					</Col>
					<Col md={6} >
						<div className="text-right" >
							<Button
								type="button"
								size="xs"
								variant="primary"
								onClick={()=> loadReport() }
							>
								<i className="icofont-refresh pe-1 border-right"></i> Refresh
							</Button>
						</div>
					</Col>
				</Row>
			</div>
			
			<div className="panel-body" >
			<Row>
					<Col md={4} >
						<Form.Control
							type="text"
							size="sm"
							placeholder="Search by Item Name"
							onChange={(e)=> handleSearch(e) }
						/>
					</Col>
					{/*<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={toDate}
							max={getToday()}
							onChange={(e)=> setToDate(e.target.value) }
						/>
					</Col>
					<Col md={1} >
						<Button
							type="button"
							size="sm"
							variant="default"
							block
							onClick={()=> loadReport() }
						>
							VIEW
						</Button>
					</Col>*/}
				</Row>
			
				
					
					<div className="table-responsive mt-10 tableFixHead" >
						<table className="table table-striped-lite table-hover   ">
							<thead>
								<tr>
									<th width="60" >S.NO</th>
									<th>Item Name</th>
									<th width="140"  >Purchase Price</th>
									<th width="120"  >Sale Price</th>
									<th width="80"  >Stock Qty</th>
									<th width="120" >Stock Value</th>
								</tr>
							</thead>
							<tbody>
							{viewData && viewData.length>0 && (viewData.map((item,i)=> {
								return <tr key={i} >
									<td>{i+1}</td>
									<td>{item.item_name}</td>
									<td align="" >
										{parseFloat(item.packing_total)>1 && (<span>({parseFloat(item.packing_total).toFixed(0)}'s) </span>)}
										<span style={{float: 'right'}} >&#8377;  {item.purchase_rate}</span>
									</td>
									<td align="right" >&#8377; {item.piece_sale_rate || item.sales_rate}</td>
									<td align="right" >{showStock(item)}</td>
									<td align="right" >&#8377;  {item.stock_value}</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
					 </center>)}
					
					
				
			</div>
			
			<div className="panel-footer" >
				<table className="table table-striped-lite table-hover  tableFixHead ">
					<tbody>
						<tr>
							<td width="60" ></td>
							<td> Total</td>
							<td width="140" align="right" >&#8377; {sumValues('purchase_value')}</td>
							<td width="120"  ></td>
							<td width="80" align="right" >{sumValues('stock')}</td>
							<td width="120" align="right" >&#8377; {sumValues('stock_value')}</td>
						</tr>
					</tbody>
				</table>
			</div>
			
		</div>
		
		</Col>
		</Row>
	);
};
export default StockSummary;