import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message, Select } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, decimalKeyPress } from '../../../utils';
import { Api } from '../../../utils/Variables';
import SalesView from '../Sales/SalesView';

const AddPaymentOut=(props)=>{
	
	const context = useContext(PsContext);
	
	const { Option } = Select;
	
	const [validated, setValidated] = useState(false);
	const [loader, setLoader] = useState(false);
	const [paymentType, setPaymentType] = useState(null);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [paymentDate, setPaymentDate] = useState(getToday());
	const [parties, setParties] = useState([]);
	const [banks, setBanks] = useState([]);
	const [selectedParty, setSelectedParty] = useState([]);
	
	 const handleSubmit = (event) => {
		 event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {		  
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoader(true);
		axios.post(`${Api.Url}v1/phar/paymentout/new`,$("#frm__Cb__AddPayment_Out").serialize()).then(res=>{
			if(res['data'].errorcode == '200' ){
				message.success(res['data'].content || 'Error');
				document.getElementById("frm__Cb__AddPayment_Out").reset();
				setSelectedParty([]);
				loadParties();
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				message.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});		
	};
	
	const loadParties=()=>{
		setLoader(true);
		setParties([]);
		axios.get(`${Api.Url}v1/phar/customer/listall?api=${context.state.loggedApi}&status=all`).then(res=>{
			if(res['data'].status=='1'){
				setParties(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadBanks=()=>{
		setLoader(true);
		setBanks([]);
		axios.get(`${Api.Url}v1/phar/bankaccounts?api=${context.state.loggedApi}&status=all`).then(res=>{
			if(res['data'].errorcode=='200'){
				setBanks(res['data'].data);
			}
			setLoader(false);
		});
	};
	 
	useEffect(()=>{
		
		loadParties();
		loadBanks();
	},[]);
	
	const partyChange=(e)=>{
		let id = e;
		let party = parties.filter(item => item.id==id);
		if(party && party.length>0){
			setSelectedParty(party[0]);
		}
	};
	
	const showBalance=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success font-weight-600">BAL :  &#8377; {Math.abs(amt).toFixed(2)}</span>;
		}
		else{
			return <span className="text-danger font-weight-600">BAL :  &#8377; {Math.abs(amt).toFixed(2)}</span>;
		}
	};
	
	return(
		<>
			<Form noValidate validated={validated} onSubmit={handleSubmit} id="frm__Cb__AddPayment_Out" >
				
				<div className="cb__modal__body" >
				
				<input type="hidden" name="api" value={context.state.loggedApi} />
				<input type="hidden" name="acyear" value={context.state.loggedAcyear.id} />
				<input type="hidden" name="i__party" value={JSON.stringify(selectedParty)} />
				<input type="hidden" name="i__party_id" value={selectedParty ? selectedParty.id : ''} />
			
				<Row>
					<Col md={8} >
						
						<Row>
							<Col md={11}>
								<label>Party <span className="text-danger">*</span></label>
								<Select
								showSearch
								style={{ width: '100%' }}
								placeholder="Select a Party"
								optionFilterProp="children"
								onChange={partyChange} 
								value={selectedParty ? selectedParty.id : ''}
								filterOption={(input, option) =>
								  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							  >
							  {parties && parties.length>0 && (parties.map((item,i)=>{
								return <Option value={item.id}>{`${item.name}  - ${item.place || ''}`}</Option>;
							  }))
							  }
							  </Select>
							  {selectedParty && selectedParty.id && (showBalance(selectedParty.current_balance))}
							</Col>
						</Row>
						
						<Row className="mt-3" >
							<Col md={8} >
								<label>Payment Type <span className="text-danger">*</span></label>
								<Form.Control
									as="select"
									name="i__type"
									required
									onChange={(e)=> setPaymentType(e.target.value) }
								>
									<option value=""> Select </option>
									<option value="1"> Cash </option>
									<option value="-1"> Cheque </option>
									{banks && banks.length>0 && (banks.map((item,i)=>{
										return <option value={item.id} key={i} > {item.name} </option>;
									}))
									}
								</Form.Control>
							</Col>
						</Row>
						
						{paymentType && paymentType=='-1' && (<Row className="mt-2" >
							<Col md={8} >
								<label>Reference No <span className="text-danger"></span></label>
								<Form.Control
									type="text"
									name="cheque_no"
								/>
							</Col>
						</Row>)}
						
						<Row className="mt-3" >
							<Col md={10} >
								<label>Description <span className="text-danger"></span></label>
								<Form.Control
									as="textarea"
									name="i__description"
									rows="3"
								/>
							</Col>
						</Row>
						
					</Col>
					
					<Col md={4} >
						
						<Row>
							<Col md={12} >
								<label>Receipt No</label>
								<Form.Control
									type="text"
									name="i__receiptNo"
									size="sm"
								/>
							</Col>
						</Row>
						
						<Row className="mt-" >
							<Col md={12} >
								<label>Date</label>
								<Form.Control
									type="date"
									name="i__receiptDate"
									size="sm"
									defaultValue={paymentDate}
									max={getToday()}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={9} >
					</Col>
					<Col md={3} >
						<label>Paid Amout <span className="text-danger">*</span></label>
						<Form.Control
							type="text"
							name="i__amount"
							onKeyPress={decimalKeyPress}
							className="text-right font-16"
							required
						/>
					</Col>
				</Row>
				
				</div>
				<div className="cb__modal__footer" >
					<Row>
						<Col md={12} >
							<div className="text-right" >
								<Button
									type="submit"
									size="sm"
									variant="default"
									disabled={loader}
								>
								{loader ? <>
									<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
								</> : <>
									<i className="icofont-check pe-2 border-right" ></i> SAVE ENTRY
								</>}
								</Button>
							</div>
						</Col>
					</Row>
				</div>
				
			</Form>
		</>
	);
};
export default AddPaymentOut;