import axios from 'axios';
import { Api } from './Variables';
import { getls } from '.';

export default axios.create({
  baseURL: Api.Url,
  headers: {
    'Api-Token': getls('user', 'api_token'),
  },
});
