import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Row, Col, Card, Form, Button, Modal, } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import {
	getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import { Api } from '../../../utils/Variables';
import axios from 'axios';

import PsContext from '../../../context/PsContext';
import SearchBatch from './searchBatch';

class SearchProducts extends Component {

	static contextType = PsContext;

	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showItemModal: true,
			showBatch: false,
			billedItems: [],
			currentRow: 0,
			currentData: [],
			productList: [],
			productView: [],
			productBatches: [],
		}

		this.keydownHandler = this.keydownHandler.bind(this);
		this.handleEnterButtonPress = this.handleEnterButtonPress.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleCombinationSearch = this.handleCombinationSearch.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
	}

	componentDidMount() {

		document.addEventListener('keydown', this.keydownHandler);
		if (this.props.productList && Object.keys(this.props.productList).length > 0) {
			this.setState({
				productList: this.props.productList,
				productView: this.props.productList,
			});
			return;
		}

	}

	componentWillUpdate() {
		document.getElementById("txt__search__bill__product_mod").focus();
	}

	componentWillUnmount() {

		document.removeEventListener('keydown', this.keydownHandler);

	}

	keydownHandler(e) {
		//e.preventDefault(0);
		//console.log(e.keyCode);

		if (e.keyCode === 70 && e.ctrlKey) { //focus on search
			e.preventDefault(0);
			$("#txt__search__bill__product_mod").focus();
		}
		else if (e.keyCode == 40) { // Handle down arrow press
			this.handleKeyDownPress();
		}
		else if (e.keyCode == 38) { // Handle up arrow press
			this.handleKeyUpPress();
		}
		else if (e.keyCode == 13) { // Handle Enter button press
			this.handleEnterButtonPress();
		}
		else if (e.keyCode == 27) { // Handle Esc button press
			this.handleModalClose();
		}
	}

	handleKeyDownPress() {
		if (this.state.currentRow < Object.keys(this.state.productView).length - 1) {
			this.setState({
				currentRow: this.state.currentRow + 1
			});

			if (this.state.currentRow > 9) {
				var row = (this.state.currentRow - 10);
				$("tr.row-" + row).hide();
			}
		}
	}

	handleKeyUpPress() {
		if (this.state.currentRow > 0) {
			this.setState({
				currentRow: this.state.currentRow - 1
			});
			if (this.state.currentRow > 8) {
				var row = (this.state.currentRow - 9);
				$("tr.row-" + row).show();
			}
		}
	}

	handleEnterButtonPress() {
		try {
			var d = this.state.productView;
			var selectedItem = d[this.state.currentRow];

			if (this.props.sales && parseFloat(selectedItem.stock) <= 0 && selectedItem.zero_stock == '0') {
				alert('Zero stock');
			}
			else {

				if (selectedItem.with_batch == '1') {

					var b = this.props.batchList;
					var bl = b.filter(item => item.product_id == selectedItem.id);
					document.removeEventListener('keydown', this.keydownHandler);
					this.setState({
						currentData: selectedItem,
						productBatches: bl,
						showBatch: true
					});
				}
				else {
					if (this.props.afterFinish)
						this.props.afterFinish(selectedItem);
				}
			}
		}
		catch (er) { }
	}

	handleCombinationSearch(e) {
		try {
			$("#tbl tbody tr").show();
			//this.setState({productView: []});
			var v = upperCase(e.target.value);
			var d = this.state.productList;

			if (v == '' || v.length < 1) {
				this.setState({ productView: d });
				return;
			}
			var s = d.filter(function (obj) {
				return ((upperCase(obj.combination).indexOf(v) > -1) ||
					upperCase(obj.id).indexOf(v) > -1);
			});

			this.setState({
				productView: s,
				currentRow: 0,
			});
		}
		catch (error) { console.log(error); }
	}

	handleSearch(e) {
		try {
			$("#tbl tbody tr").show();
			//this.setState({productView: []});
			var v = upperCase(e.target.value);
			var d = this.state.productList;

			if (v == '' || v.length < 1) {
				this.setState({ productView: d });
				return;
			}
			var s = d.filter(function (obj) {
				return ((upperCase(obj.item_name).indexOf(v) > -1) ||
					upperCase(obj.id).indexOf(v) > -1);
			});

			this.setState({
				productView: s,
				currentRow: 0,
			});
		}
		catch (error) { console.log(error); }
	}

	handleModalClose() {
		if (this.props.afterFinish)
			this.props.afterFinish([]);
	}

	handleSelectClick(index) {
		try {
			var d = this.state.productView;
			var selectedItem = d[index];

			if (this.props.sales && parseFloat(selectedItem.stock) < 1 && selectedItem.zero_stock == '0') {
				alert('Zero stock');
			}
			else {
				if (selectedItem.with_batch == '1') {

					var b = this.props.batchList;
					var bl = b.filter(item => item.product_id == selectedItem.id);
					document.removeEventListener('keydown', this.keydownHandler);
					this.setState({
						currentData: selectedItem,
						productBatches: bl,
						showBatch: true
					});
				}
				else {
					if (this.props.afterFinish)
						this.props.afterFinish(selectedItem, false);
				}
			}
		}
		catch (er) { }
	}

	handleBatchModalClose() {

		document.addEventListener('keydown', this.keydownHandler);
		this.setState({ showBatch: false });
	}

	handleBatchSelect(batch) {

		var d = this.state.productView;
		var selectedItem = d[this.state.currentRow];


		if (this.props.afterFinish)
			this.props.afterFinish(selectedItem, batch);
	}

	render() {

		return (
			<React.Fragment>

				<div className="cb__modal__header" >
					<Row>
						<Col md={10} >
							Search Products
						</Col>
						<Col md={2} >
							<div className="text-right" >
								ESC
							</div>
						</Col>
					</Row>
				</div>

				<div className="cb__modal__body tableFixHead" style={{ height: '410px' }} >
					<table className="table table-sm table-bordered mb-0" >
						<thead className="bg-primary-transparent-2 text-dark" >
							<tr>
								<th width="90" className="font-weight-600" >Code</th>
								<th className="font-weight-600" >Name</th>
								<th width="150" className="font-weight-600" >Combination</th>
								<th width="60" className="font-weight-600" >Stock</th>
								<th width="90" className="font-weight-600" >MRP</th>
								<th width="90" className="font-weight-600" >Rate</th>
								<th width="60" className="font-weight-600" >Manage</th>
							</tr>
						</thead>
					</table>
					<div style={{ height: 'calc(100vh - 268px)', overflowY: 'auto' }} >
						<table className="table table-sm table-bordered" id="tbl" >
							<tbody id="s__bill__product" >
								{this.state.productView.map((item, i) => {
									let combList = item.combination.split(',');
									return <tr key={i} className={this.state.currentRow == (i) ? `row-${i} tr-selected` : `row-${i}`} >
										<td width="90" >{item.code}</td>
										<td >
											{parseFloat(item.stock) < 1 ? <span className="text-danger">{item.print_name || item.item_name}</span> : <span>{item.print_name || item.item_name}</span>}
										</td>
										<td width="150" align="center" >
											{combList.map((items) =>
												<span>{items}, </span>
											)}
										</td>
										<td width="60" align="center" >{item.stock}</td>
										<td width="90" align="right" >{item.mrp}</td>
										<td width="90" align="right" >
											{this.props.sales ?
												(this.props.wholesale? (item.piece_whole_sale_rate || item.mrp): (item.piece_sale_rate || item.mrp))
												:
												item.purchase_rate
											}

										</td>
										<td width="60" align="center" >
											<Button
												type="button"
												size="xs"
												variant="default"
												onClick={this.handleSelectClick.bind(this, i)}
											>
												<i className="icofont-check"></i>
											</Button>
										</td>
									</tr>;
								})
								}
							</tbody>
						</table>
					</div>
				</div>

				<div className="cb__modal__footer" >
					<Row>
						<Col md={1} className="ps-0 pe-0" >
							<button className="btn btn-default btn-sm " >
								CTRL + F
							</button>
						</Col>
						<Col md={6} >
							<Form.Control
								type="text"
								placeholder="Search By Product Name"
								size="sm"
								id="txt__search__bill__product_mod"
								autoFocus={true}
								onChange={this.handleSearch}
							/>
						</Col>
						<Col md={5} >
							<Form.Control
								type="text"
								placeholder="Search by only combination ..."
								size="sm"
								onChange={this.handleCombinationSearch}
							/>
						</Col>
					</Row>
				</div>


				<Modal show={this.state.showBatch} onHide={this.handleBatchModalClose.bind(this)} className="cb__modal__search" size="lg" backdrop="static" >
					<Modal.Body>
						<SearchBatch
							batches={this.state.productBatches}
							afterFinish={this.handleBatchSelect.bind(this)}
							item={this.state.currentData}
							sales={this.props.sales || false}
						/>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}

}
export default SearchProducts;
