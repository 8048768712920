import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {getls, upperCase} from '../../../utils';
import {Tooltip} from 'antd';
import AOS from 'aos';

import PsContext from '../../../context/PsContext';

import { ModalConfirm, AccountingYear } from '../../../modules/components';

class Header extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			showModal: false,
		}		
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
		this.handleLogoutOk = this.handleLogoutOk.bind(this);
		this.handleSidebarCollapse = this.handleSidebarCollapse.bind(this);
	}
	
	componentDidMount(){
		
		AOS.init({
		  offset: 200,
		  duration: 600,
		  easing: 'ease-in-sine',
		  delay: 100,
		});
		
		 //window.addEventListener('scroll', this.handleWindowScroll);
	}
	
	handleLogOut(){
		
		this.setState({showModal: true});
		/* if(!window.confirm('Do you want to logout?')){
			return;
		}
		
		this.context.logout() */;
	}
	
	handleFormSubmit(e){
		e.preventDefault();
	}
	
	handleLogoutOk(){
		
		this.setState({showModal: false});		
		this.context.logout();
	}
	
	
	getPageTitle = () => {
		let name;
		this.props.routes.map(prop => {	

		  if (prop.path === this.props.location.pathname) {
			name = prop.name;
		  }
		  return null;
		});
		return name;
	};
	
	handleSidebarCollapse(){
		$("body").addClass("sidebar-expand");
		try{
			if($("body").hasClass("sidebar-collapse")){
				$("body").removeClass("sidebar-collapse");
				$("body").addClass("sidebar-expand");
			}
			else{
				$("body").removeClass("sidebar-expand");
				$("body").addClass("sidebar-collapse");
			}
		}
		catch(error){}
	}

	
	render(){
		
		if(this.context.state.isLogged != 'YES'){
			return(<Redirect to="/" />);
			
		}
		
		return(
			<div className="main-header">
				<div className="font-17" >
					<i className="icofont-ui-note"></i> &nbsp; 
					{this.context.state.loggedCompany.name}
				</div>
				<div className="mr-auto ml-auto cmp_name " ></div>
			  <ul className="nav nav-circle ml-auto">
			  
				{this.context.state.loggedUser.login_with_acyear=='1' && this.context.state.loggedAcyear && (<li>
					<a className="btn font-14 font-weight-600" >
						{this.context.state.loggedAcyear.name}  <i className="icofont-caret-down" ></i>
					</a>
				</li>)}
				
				<li>
					<Tooltip title="Logout" >
					<a className="btn btn-sm bg-light-20 border-radius-3 rounded-7" onClick={this.handleLogOut} tooltip="Logout" > 
					&nbsp;<i className="icofont-logout" ></i> Log-out&nbsp;
					</a>
					</Tooltip>
				</li>
			  </ul>
			  
			  <iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
			  
			
			</div>
			);
	}
	
}
export default Header;
