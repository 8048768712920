/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import toast, { Toaster } from 'react-hot-toast';
import routes from './routes';


import {getls, lowerCase} from '../../utils';
import nav from './_nav';

import PsContext from '../../context/PsContext';

class EmptyLayout extends Component {
	
	static contextType = PsContext;
	
  componentDidMount() {

  }

  render() {
	  
    return (

      <div>
			
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
		
        <Notifications />
          

            <Switch>
              {routes.map((page) => (
				  page.layout && page.layout=='empty' && page.allowed && lowerCase(page.allowed).indexOf(lowerCase(this.context.state.loggedUser.role)) > -1 ? <Route exact path={page.path} component={page.component} key={page} /> : null
              ))}
            </Switch>


		
		<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
		
      </div>

    );
  }
}
export default EmptyLayout;
