import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal, InputGroup, ButtonGroup , } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import PsContext from '../../../context/PsContext';
import {Spin, message, Select } from 'antd';
import SearchProducts from './SearchProducts';
import AddBatch from './AddBatch';
import $ from 'jquery';

const { Option } = Select;

class NewPurchaseEntryScreen extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showItemModal: false,
			showBatchModal: false,
			billedItems: [],
			selectedSupplier: [],
			productList: [],
			patientList: [],
			supplierList: [],
			productItem: [],
			batchList: [],
			supplier : '',
			billNo : '',
			billType: 'Bill',
			billDiscount: 0,
			roundOff: 0,
		}
		
		this.handleProductSearch = this.handleProductSearch.bind(this);
		this.handleItemSelect = this.handleItemSelect.bind(this);
		this.handleBatchSelect = this.handleBatchSelect.bind(this);
		this.handleSupplierChange = this.handleSupplierChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleAddBatch = this.handleAddBatch.bind(this);
		this.handleBatchChange = this.handleBatchChange.bind(this);
		this.resetForm = this.resetForm.bind(this);
	}
	
	resetForm(e){
		this.setState({billedItems : [],supplier : '',selectedSupplier : [], billDiscount: 0, roundOff: 0,});
		document.getElementById('frm_addPurchase').reset();
	}
	
	handleBatchChange(index,e){
			
		try{
			var s = this.state.billedItems;
			s[index]['batch'] = e;
			var b = this.state.batchList;
			
			var bindex = b.findIndex(function(obj){
				return(obj.id == e);
			});
			
			if(bindex > -1){
				var rate = s[index]['qty']*b[bindex].purchase_rate;
				var gst = rate*s[index]['gst_percentage']/100;
				var total = parseFloat(rate)+parseFloat(gst);
				s[index]['amount'] = b[bindex].purchase_rate;
				s[index]['sales_rate'] = b[bindex].sales_rate;
				s[index]['mrp'] = b[bindex].mrp;
				s[index]['gst'] = parseFloat(gst).toFixed(2);
				s[index]['total'] = parseFloat(total).toFixed(2);
			}
			this.setState({
				billedItems: s
			});
		}
		catch(er){ }
	}
	
	handleAddBatch(item,e){
	
		this.setState({productItem : item, showBatchModal : true});
	}
	
	handleSupplierChange(value){
		var s = this.state.supplierList;
		var sdata = s.filter(function(obj){
			return(obj.id == value);
		});
		if(sdata.length > 0) {
			this.setState({selectedSupplier : sdata[0]});
		}
		this.setState({supplier : value});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			if(this.state.supplier == ''){
				message.error('Please select supplier' || 'Error');
				return;
			}
			if(this.state.billedItems.length == 0){
				message.error('Please select product' || 'Error');
				return;
			}
			this.setState({showLoader: true});
			
			let url = this.props.type && this.props.type=='return'
					? `${Api.Url}v1/phar/purchase_return/save` 
					: `${Api.Url}v1/phar/purchase/save`;
					
			axios.post(url, $("#frm_addPurchase").serialize()).then(res=>{
				if(res['data'].status=='1'){
					this.setState({showLoader : false,billedItems : [],supplier : '',selectedSupplier : []});
					message.success(res['data'].message || 'Success');
					document.getElementById('frm_addPurchase').reset();
					this.loadBillNo();
					this.loadProducts();
					this.loadBatches();
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	componentDidMount(){
		
		this.loadProducts();
		this.loadBatches();
		//this.loadPatients();
		this.loadSuppliers();
		this.loadBillNo();
	}
	
	loadBillNo(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('hospital_id',getls('loggedCompany','id'));
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			
			let url = this.props.type && this.props.type=='return'
					? `${Api.Url}v1/phar/purchase_return/billno` 
					: `${Api.Url}v1/phar/purchase/getbillno`;
			
			axios.post(url, form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({billNo : res['data'].data,showLoader : false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	loadPatients(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));

			axios.post(Api.Url+'v1/get_active_patient', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({patientList : res['data'].data,showLoader : false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	loadSuppliers(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));

			axios.post(Api.Url+'v1/phar/get_supplier', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({supplierList : res['data'].data,showLoader : false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	loadProducts(){
		try{
			
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/phar/item/getactive?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						productList: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadBatches(){
		try{
			
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/phar/batch/listall?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						batchList: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleProductSearch(){
		this.setState({showItemModal: true});
	}
	
	handlePatientSearchClick(){
		this.setState({showPatientModal: true});
	}
	
	handleRemoveItems(index,e){
		try{
			var s = this.state.billedItems;
			s.splice(index,1);
			this.setState({billedItems : s});
		}	
		catch(er){ }
	}
	
	handleDescChange(index,field,e){
		try{
			var s = this.state.billedItems;
			var val = e.target.value;
			s[index][field] = val;
			this.setState({
				billedItems: s
			});
		}
		catch(er){ }
	}
	
	handleQtyChange(index,e){
		try{
			var s = this.state.billedItems;
			var item = s[index];
			var qty = e.target.value;
			var gst_amt = ((parseFloat(item.purchase_rate) * parseFloat(item.gst_percentage))/100).toFixed(2);
			var amt = (parseFloat(item.purchase_rate) + parseFloat(gst_amt)).toFixed(2);
			if(item.purchase_with_tax=='1'){
				amt = parseFloat(item.purchase_rate).toFixed(2);
			}
			var total_amount = (parseFloat(amt)*parseFloat(qty)).toFixed(2);
			var total_gst = (parseFloat(gst_amt)*parseFloat(qty)).toFixed(2);
			
			s[index]['qty'] = qty;
			s[index]['gst'] = parseFloat(total_gst).toFixed(2);
			s[index]['total'] = parseFloat(total_amount).toFixed(2);
			this.setState({
				billedItems: s
			});
		}
		catch(er){ }
	}
	
	handleDiscount(field,e){
		try{
			var val = e.target.value;
			var total = parseFloat(this.getTotal()-val).toFixed(2);
			$("#i__netAmount").val(total);
		}
		catch(er){ }
	}
	
	handleItemSelect(item, batch=false){
		try{
			var s = this.state.billedItems;
			
			if(Object.keys(item).length>0){
				//s.push(item);
				var gst = item.purchase_rate*item.tax_value/100;
				var total = parseFloat(item.purchase_rate)+parseFloat(gst);
				if(item.purchase_with_tax=='1'){
					total = parseFloat(item.purchase_rate);
				}
				
				if(batch)
				{
					gst = batch.purchase_rate* item.tax_value/100;
					total = parseFloat(batch.purchase_rate)+parseFloat(gst);
						if(item.purchase_with_tax=='1'){
						total = parseFloat(batch.purchase_rate);
					}
				}
				
				s.push({
					id: item.id,
					add_notes: 0,
					notes: '',
					batch: (batch ?  batch.id : ''),
					batch_name: (batch ? batch.batch : ''),
					expiry: (batch ? batch.expiry : ''),
					name: item.item_name,
					print_name: item.print_name,
					purchase_rate: (batch ? batch.purchase_rate : item.purchase_rate),
					amount: (batch ? batch.purchase_rate : item.purchase_rate),
					piece_sale_rate: (batch ? batch.piece_sale_rate : item.piece_sale_rate),
					mrp: (batch ? batch.mrp : item.mrp),
					sales_rate: (batch ? batch.sales_rate : item.sales_rate),
					tax: item.tax,
					gst_percentage: item.tax_value,
					tax_value: item.tax_value,
					gst: parseFloat(gst).toFixed(2),
					qty: 1,
					fqty: 0,
					purchase_with_tax: item.purchase_with_tax,
					mrp_with_tax: item.mrp_with_tax,
					with_batch: item.with_batch,
					packing: item.packing,
					packing_count: item.packing_count,
					packing_total: item.packing_total,
					total: parseFloat(total).toFixed(2),
				});
			}
			
			this.setState({
				billedItems: s,
				showItemModal: false,
			});
			
		}
		catch(er){ }
	}
	
	handleBatchSelect(item){
		
		try{
			
			var s = this.state.billedItems;
			var b = this.state.batchList;
			
			if(Object.keys(item).length>0){
				b.push(item[0]);
				var index = s.findIndex((obj)=>{
					return(obj.id == item[0].product_id);
				});
				if(index > -1){
					var qty = s[index]['qty'];
					var gst = (((item[0].purchase_rate*s[index].tax_value)/100) * parseFloat(qty));
					var total = ((parseFloat(item[0].purchase_rate) * parseFloat(qty))+parseFloat(gst));
					s[index]['batch'] = item[0].id;
					s[index]['amount'] = item[0].purchase_rate;
					s[index]['sales_rate'] = item[0].sales_rate;
					s[index]['mrp'] = item[0].mrp;
					s[index]['gst'] = parseFloat(gst).toFixed(2);
					s[index]['total'] = parseFloat(total).toFixed(2);
				}
			}
			this.setState({
				billedItems: s,
				batchList: b,
				showBatchModal: false,
			});
		}
		catch(er){ }
	}
	
	setAddNotes(index){
		try{
			var s = this.state.billedItems;
			s[index]['add_notes'] = s[index]['add_notes']=='0' ? '1' : '0';
			this.setState({
				billedItems: s,
			});
		}
		catch(er){}
	}
	
	getTotal(){
		try{
			var s = this.state.billedItems;
			var t = 0;
			//var discount = $("#i__discount").val();
			s.map((item,i)=>{
				t = parseFloat(t) + parseFloat(item.total);
			});
			
			//var total = t-discount;
			//$("#i__netAmount").val(total.toFixed(2));
			
			return t.toFixed(2);
		}
		catch(er){}
	}
	
	
	getNetAmount(){
		try{
			var s = this.state.billedItems;
			var t = 0;
			//var discount = $("#i__discount").val();
			s.map((item,i)=>{
				t = parseFloat(t) + parseFloat(item.total);
			});
			
			var total = parseFloat(t) - parseFloat(this.state.billDiscount);
			var ro = Math.round(total);
			
			return ro.toFixed(2);
		}
		catch(er){}
	}
	
	
	getRoundOff(){
		try{
			
			if(parseFloat(this.state.roundOff) != 0){
				return parseFloat(this.state.roundOff);
			}
			
			var s = this.state.billedItems;
			var t = 0;
			//var discount = $("#i__discount").val();
			s.map((item,i)=>{
				t = parseFloat(t) + parseFloat(item.total);
			});
			
			var total = parseFloat(t) - parseFloat(this.state.billDiscount);
			var ro = Math.round(total);
			
			return (parseFloat(ro)-parseFloat(total)).toFixed(2);
		}
		catch(er){console.log(er);}
	}
	
	handleDiscountChange(e){
		this.setState({
			billDiscount: e.target.value
		});
	}
	
	getSupplier(field){
		try{
			var d = this.state.selectedSupplier;
			return d[field];
		}
		catch(er){ return ''; }
	}
	
	getTotalByField(field){
		var total = 0;
		this.state.billedItems.map((item,i)=>{
			total = parseFloat(total) + parseFloat(item[field]);
		});
		return parseFloat(total).toFixed(2);
	}
	
	render(){
		
		let gstTotal = 0;
		
		return(
			<div className="panel panel-form"  >
				<div className={this.props.type && this.props.type=='return' ? 'panel-header bg-gradiant-danger' : "panel-header"} >
				{this.props.type && this.props.type=='return' ? 'Purchase Return Entry' : 'Purchase Entry'}
				</div>
				<form action="" method="post" encType="multipart/form-data" id="frm_addPurchase" onSubmit={this.handleFormSubmit}>
				
				<div className="panel-body">
				
					<input type="hidden" name="api" value={this.context.api()} />
					<input type="hidden" name="hospital_id" value={getls('loggedCompany','id')} />
					<input type="hidden" name="user_id" value={getls('loggedUser','id')} />
					<input type="hidden" name="i__accountingYear" value={getls('loggedAcyear','id')} />
					<input type="hidden" name="i__billItems" value={JSON.stringify(this.state.billedItems)} />
					<input type="hidden" name="supplier" value={this.state.supplier} />
				<Spin spinning={this.state.showLoader} >
					<Row>
						<Col md={6} >
							<table width="100%" >
								<tr>
									<td  width="60" ><label className="">Supplier</label></td>
									<td width="" >
										<Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a Supplier"
										  optionFilterProp="children"
										  onChange={this.handleSupplierChange}
										  style={{width: '100%'}}
										  value={this.state.supplier}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.supplierList && this.state.supplierList.map((item) => (
												<Option key={item.id} value={item.id}>{item.name}</Option>
										  ))}
										</Select>
									</td>
								
									<td width="50" align="right" className="pr-10" >
										<label className=""> Type  </label>
									</td>
									<td width="150" >
										<select name="purchase_type" className="form-control form-control-sm">
											<option value="credit">Credit Purchase</option>
											{this.props.type && this.props.type!='return' && (<option value="cash">Cash Purchase</option>)}
										</select>
									</td>
								
								</tr>
							</table>
						</Col>
						<Col md={3} >
							<table width="100%" >
								<tr>
									<td width="80">
										<label className="">INV.No</label>
									</td>
									<td>
										<Form.Control
											type="text"
											size="sm"
											name="i__invNo"
										/>
									</td>
								</tr>
							</table>
						</Col>
						<Col md={3} >
							<table width="100%" >
								<tr>
									<td width="80">
										<label className="">PR.No</label>
									</td>
									<td>
										<Form.Control
											type="text"
											size="sm"
											name="i__billNo"
											value={this.state.billNo}
											readOnly
										/>
									</td>
								</tr>
							</table>
						</Col>
					</Row>
					
					<Row>
						<Col md={6} >
							<table width="100%" >
								<tr>
									<td  width="60" ><label className="">Mobile</label></td>
									<td width="125" >
										<Form.Control
											type="text"
											size="sm"
											value={this.getSupplier('mobile')}
										/>
									</td>
									<td  width="50" align="" ><label className=""> Place  </label></td>
									<td>
										<Form.Control
											type="text"
											size="sm"
											value={this.getSupplier('address')}
										/>
									</td>
								</tr>
							</table>
						</Col>
						<Col md={3} >
							<table width="100%" >
								<tr>
									<td width="80" >
										<label className="">INV.Date</label>
									</td>
									<td>
										<Form.Control
											type="date"
											size="sm"
											name="i__invDate"
											max={momentDate((new Date()),'YYYY-MM-DD')}
											defaultValue={momentDate((new Date()),'YYYY-MM-DD')}
										/>
									</td>
								</tr>
							</table>
						</Col>
						<Col md={3} >
							<table width="100%" >
								<tr>
									<td width="80" >
										<label className="">PR.Date</label>
									</td>
									<td>
										<Form.Control
											type="date"
											size="sm"
											name="i__billDate"
											max={momentDate((new Date()),'YYYY-MM-DD')}
											defaultValue={momentDate((new Date()),'YYYY-MM-DD')}
										/>
									</td>
								</tr>
							</table>
						</Col>
					</Row>
					
					<Card className="mt-10" >
						<Card.Body className="px-0 py-0" >
							
							<table className="table table-sm table-bordered mb-0 table-invoice" >
								<thead className="thead-primary" >
									<tr>
										<th width="60" className="font-weight-600" >S.No</th>
										<th className="font-weight-600" >Item</th>
										<th width="120" className="font-weight-600" >Batch</th>
										<th width="70" className="font-weight-600" >Expiry</th>
										<th width="50" className="font-weight-600" >Qty</th>
										<th width="50" className="font-weight-600" >F.Qty</th>
										<th width="90" className="font-weight-600" >PR</th>
										<th width="90" className="font-weight-600" >MRP</th>
										<th width="90" className="font-weight-600" >GST(%)</th>
										<th width="90" className="font-weight-600" >GST(Rs)</th>
										<th width="90" className="font-weight-600" >Amount</th>
										<th width="60" className="font-weight-600" >Manage</th>
									</tr>
								</thead>
							</table>
							
							<div style={{height: 'calc(100vh - 360px)', overflowY: 'auto'}} >
								<table className="table table-sm table-bordered table-invoice" >
									<tbody>
									{this.state.billedItems.map((item, i)=>{
										gstTotal+=parseFloat(item.gst);
										return <tr key={i} >
											<td width="60" >{i+1}</td>
											<td >
											<span className="font-12" >{item.print_name || item.name}</span>
											{/*	<a href="javascript:;" className="font-11 font-weight-600" style={{float : 'right'}} onClick={this.setAddNotes.bind(this,i)} >Add Notes</a> 
												{item.add_notes==1 ? <Form.Control
												type="text"
												size="sm"
												className="font-12" 
												value={item.description}
												onChange={this.handleDescChange.bind(this,i,'description')}
												placeholder="Description.."
												/> : null}
											*/}
											</td>
											<td width="120">
												{/*<Select
												  showSearch
												  style={{ width: '110px' }}
												  placeholder="Select a Batch"
												  optionFilterProp="children"
												  onChange={this.handleBatchChange.bind(this,i)}
												  value={item.batch}
												  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
												  {this.state.batchList && this.state.batchList.map((bitem) => {
													if(bitem.product_id == item.id) {
														return <Option key={bitem.id} value={bitem.id}>{bitem.batch}</Option>
													}
												  })}
												</Select>*/}
												{/*<select name="batch" className="form-control form-control-sm" style={{ width: '80%',float : 'left' }} defaultValue={item.batch} required onChange={this.handleDescChange.bind(this,i,'batch')}>
													<option value="">Select Batch</option>
													{this.state.batchList && this.state.batchList.map((bitem) => {
														if(bitem.product_id == item.id) {
															return <option key={bitem.id} value={bitem.id}>{bitem.batch}</option>
														}
													})}
												</select>
												<Button
													type="button"
													size="xs"
													variant="purple"
													style={{float : 'right', width : '30px'}}
													onClick={this.handleAddBatch.bind(this,item)}
												>
													<i className="icofont-plus"></i>
												</Button>*/}
												{item.batch_name}
											</td>
											<td width="70" align="center" >{item.expiry}</td>
											<td width="50" align="center" >
												<Form.Control
													type="text"
													size="sm"
													onKeyPress={decimalKeyPress}
													value={item.qty}
													onChange={this.handleQtyChange.bind(this,i)}
												/>
											</td>
											<td width="50" align="center" >
												<Form.Control
													type="text"
													size="sm"
													onKeyPress={decimalKeyPress}
													value={item.fqty}
													onChange={this.handleDescChange.bind(this,i,'fqty')}
												/>
											</td>
											<td width="90" align="right" >{item.amount}</td>
											<td width="90" align="right" >{item.mrp}</td>
											<td width="90" align="right" >{item.gst_percentage}</td>
											<td width="90" align="right" >{item.gst}</td>
											<td width="90" align="right" >{item.total}</td>
											<td width="60" align="center" >
												<Button
													type="button"
													size="xs"
													variant="danger"
													onClick={this.handleRemoveItems.bind(this,i)}
												>
													<i className="icofont-trash"></i>
												</Button>
											</td>
										</tr>;
									})
									}
										<tr>
											<td width="60" ></td>
											<td colSpan="" >
												<Form.Control
													type="text"
													size="sm"
													placeholder="Search Product.."
													value=""
													onChange={this.handleProductSearch} 
												/>
											</td>
											<td width="120" ></td>
											<td width="70" ></td>
											<td width="50" ></td>
											<td width="50" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="60" ></td>										
										</tr>
									</tbody>
									<input type="hidden" name="gst_total" value={gstTotal} />
								</table>
							</div>
						</Card.Body>
					</Card>
					
					<table className="table table-bordered" >
						<tbody>
							<tr className="font-weight-600" style={{ backgroundColor: '#aee9ff' }} >
								<td width="60" ></td>
								<td></td>
								<td width="120" ></td>
								<td width="70" ></td>
								<td width="100" align="right"  >{(parseFloat(this.getTotalByField('qty')) + parseFloat(this.getTotalByField('fqty'))).toFixed(2)}</td>
								<td width="90" ></td>
								<td width="90" ></td>
								<td width="90" ></td>
								<td width="90" align="right" >{this.getTotalByField('gst')}</td>
								<td width="90" align="right"  >{this.getTotalByField('total')}</td>
								<td width="60" ></td>
							</tr>
						</tbody>
					</table>
					
										
					
					<input type="hidden" name="i__netAmount" value={this.getNetAmount()} />
					<input type="hidden" name="grand_total" value={this.getTotal()} />
					
						<Row>
							<Col md={8} >
							
							</Col>
							<Col md={1} >
								<InputGroup size="sm" >
									<InputGroup.Text style={{fontSize: '11px', padding: '3px'}} >Discount</InputGroup.Text>
									<Form.Control
										type="text"
										name="i__discount"
										value={this.state.billDiscount}
										onChange={this.handleDiscountChange.bind(this)}
										className="text-right"
										style={{fontSize:'11px'}}
									/>
								</InputGroup>
							</Col>
							<Col md={1} >
								<InputGroup size="sm" >
									<InputGroup.Text style={{fontSize: '11px', padding: '3px'}} >Round</InputGroup.Text>
									<Form.Control
										type="text"
										name="round_off"
										value={this.getRoundOff()}
										onChange={(e)=> this.setState({roundOff: e.target.value})}
										className="text-right"
										style={{fontSize:'11px'}}
									/>
								</InputGroup>
							</Col>
							<Col md={2} >
								<div className="text-right font-weight-600 pe-3 " style={{fontSize: '25px'}}>
									&#8377; {this.getNetAmount()}
								</div>
							</Col>
						</Row>
					
				</Spin>
				
				</div>
				<div className="panel-footer ">
					<Row className="" >
							<Col md={6} >
								
							</Col>
							<Col md={6} >
								<div className="text-right" >
									<Button type="reset" size="sm" variant="default" onClick={this.resetForm}>
										<i className="icofont-close pe-2 border-right"></i>  Reset
									</Button>
									<Button type="submit" size="sm" variant="default"  >
										<i className="icofont-check pe-2 border-right"></i> 
										{this.props.type && this.props.type=='return' ? 'Save Return [Ctrl+S]' : 'Save [Ctrl+S]'}
									</Button>									
								</div>
							</Col>
						</Row>
				</div>
				
				</form>
				
				<Modal show={this.state.showItemModal} className="cb__modal cb__modal__search" size="lg" >
					<Modal.Body>
						
						<SearchProducts 
							afterFinish={this.handleItemSelect} 
							productList={this.state.productList} 
							batchList={this.state.batchList}
						/>
						
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showBatchModal} className="cb__modal " size="md" >
				
					<Modal.Body>
						
						<AddBatch afterFinish={this.handleBatchSelect} productDetail={this.state.productItem} />
						
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default NewPurchaseEntryScreen;
