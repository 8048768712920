import React, { Component } from 'react';
import $ from 'jquery';

import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import { getlsItem, lowerCase } from '../../../utils';
import {Avatar} from 'antd';

import PsContext from '../../../context/PsContext';
import { ModalConfirm } from '../../../modules/components';
import HL_LOGO from '../../../assets/img/hl.png';

class SideNav extends Component {
	
	static contextType = PsContext;
	
  constructor(props) {
    super(props);
    this.state = {
		showModal: false,
    };

    this.handleSidebarCollapse = this.handleSidebarCollapse.bind(this);
	this.handleLogOut = this.handleLogOut.bind(this);
	this.handleLogoutOk = this.handleLogoutOk.bind(this);
  }

	handleLogOut(){
		
		this.setState({showModal: true});
		/* if(!window.confirm('Do you want to logout?')){
			return;
		}
		
		this.context.logout() */;
	}
		
	handleLogoutOk(){
		
		this.setState({showModal: false});		
		this.context.logout();
	}
	
  handleSidebarCollapse() {
    try {
      $('body').removeClass('sidebar-expand');
      $('body').addClass('sidebar-collapse');
    // eslint-disable-next-line no-console
    } catch (error) { console.log(error); }
  }

  render() {
    const navItems = (items) => items.map((item, index) => itemType(item, index));

    const itemType = (item, index) => {
		  if (item.children) {
			  if (item.allowed) {
				  if (lowerCase(item.allowed).indexOf(this.props.role) > -1) return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} role={this.props.role} />;
			  } else {
          return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} role={this.props.role} />;
			  }
		  } else if (item.divider) {
			return <NavDivider key={index} />;
		  } else if (item.allowed) {
				  if (lowerCase(item.allowed).indexOf(this.props.role) > -1) return <NavSingleItem item={item} key={index} />;
			  } else { return <NavSingleItem item={item} key={index}  />; }
    };

    return (
      <div className="sidebar ">
        <div className="sidebar-header">
          <a href="javascript:;" className="logo">
		  <img src={HL_LOGO} alt="Logo" id="main-logo" />		  
          </a>
		  {/*<Avatar size="" style={{backgroundColor: "#0b85e6"}} >P</Avatar>*/}
			 <div className="cb__cmp__name">Hosaid</div>
          <a href="javascript:;" className="nav-link nav-icon rounded-circle ml-auto" data-toggle="sidebar" onClick={this.handleSidebarCollapse}>
            <i className="material-icons">close</i>
          </a>
        </div>
        <div className="sidebar-body">
          <ul className="nav nav-sub" style={{width: '100%'}}  >
            {navItems(this.props.nav.top)}
          </ul>
		  <ul className="nav  nav-sub fixed-bottom bottom-nav" >
			<li className="nav-item">
				<a href="javascript:;" className="nav-link" onClick={this.handleLogOut}  >
					<i className="icofont-logout" ></i>
					<span>Logout</span>
				</a>
			</li>
		  </ul>
        </div>
		
			<ModalConfirm
				type="confirm"
				title="Confirm"
				content="Do you want to Logout ?"
				okText='Yes'
				show={this.state.showModal}
				okClick={this.handleLogoutOk}
			 	cancelClick={()=>{this.setState({showModal: false})}}
		    />
	  
      </div>
    );
  }
}
export default SideNav;
