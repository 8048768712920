export default {
	top: [
		{
			name: 'Dashboard', url: '/app', icon: 'Home', ionicon: 'icofont-ui-home', exact: true, allowed: ['PHARMACY', 'admin', 'cashbook',]
		},
		{
			name: 'Parties', url: '/app/customers', icon: 'Home', ionicon: 'icofont-users', exact: true, allowed: ['PHARMACY', 'admin', 'cashbook'],
		},
		{
			name: 'Products', url: '/app/products', icon: 'Home', ionicon: 'icofont-basket', exact: true, allowed: ['PHARMACY', 'admin'],
			children: [
				{ name: 'Item List', url: '/app/products', icon: 'Home', ionicon: 'icofont-cart', exact: true, allowed: ['pharmacy', 'admin'] },
				{ name: 'Item Groups', url: '/app/products/item-group', icon: 'Home', ionicon: 'icofont-list', exact: true, allowed: ['pharmacy', 'admin'] },
				{ name: 'Brand', url: '/app/products/brand', icon: 'Home', ionicon: 'icofont-list', exact: true, allowed: ['pharmacy', 'admin'] },
			]
		},
		{
			name: 'Purchase', url: '/app/purchase_entry', icon: 'Home', ionicon: 'icofont-cart', exact: false, allowed: ['pharmacy', 'admin'],
			children: [
				{ name: 'Entry', url: '/app/purchase_entry', icon: 'Home', ionicon: 'icofont-cart', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Report', url: '/app/purchase_report', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ divider: true },
				{ name: 'Payment Out', url: '/app/payment_out', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ divider: true },
				{ name: 'Return', url: '/app/purchase_return_entry', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Return List', url: '/app/purchase_return_report', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
			]
		},
		{
			name: 'Sales', url: '/app/sales_entry', icon: 'Home', ionicon: 'icofont-credit-card', exact: false, allowed: ['pharmacy', 'admin'],
			children: [
				{ name: 'Entry', url: '/app/sales_entry', icon: 'Home', ionicon: 'icofont-credit-card', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Report', url: '/app/sales_report', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ divider: true },
				{ name: 'POS Entry', url: '/app/pos/sales_entry', icon: 'Home', ionicon: 'icofont-credit-card', exact: false, allowed: ['pharmacy', 'admin'] },
				{ divider: true },
				{ name: 'Payment In', url: '/app/payment_in', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ divider: true },
				{ name: 'Return Entry', url: '/app/sales_return_entry', icon: 'Home', ionicon: 'icofont-credit-card', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Return Report', url: '/app/sales_return_report', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] }
			]
		},
		/*{
		  name: 'From Prescription', url: '/app/prescription', icon: 'Home', ionicon: 'icofont-tablets', exact: true, allowed: ['PHARMACY', 'admin'],
		},*/
		{
			name: 'Cash & Bank', url: '/app', icon: 'Home', ionicon: 'icofont-table', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'],
			children: [
				{ name: 'Cash In Hand', url: '/app/cash_in_hand', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ name: 'Cash Flow', url: '/app/cash_flow', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ divider: true },
				{ name: 'Payment In', url: '/app/payment_in', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ name: 'Payment Out', url: '/app/payment_out', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ divider: true },
				{ name: 'Bank Accounts', url: '/app/bank_accounts', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ name: 'Bank Transactions', url: '/app/bank_transactions', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ name: 'Bank Statement', url: '/app/bank_statement', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ divider: true },
				{ name: 'Cheques', url: '/app/cheques_transactions', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
			]
		},
		{
			name: 'Report', url: '/app', icon: 'Home', ionicon: 'icofont-table', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'],
			children: [
				{ name: 'Sales Report', url: '/app/sales_report', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Cash In Hand', url: '/app/cash_in_hand', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook', 'cashbook'] },
				{ name: 'Day Book', url: '/app/day_book', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook', 'cashbook'] },
				{ divider: true },
				{ name: 'Party Wise Balance', url: '/app/party_wise_balance', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin', 'cashbook'] },
				{ divider: true },
				{ name: 'Expire Products', url: '/app/expire_product', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Stock Summary', url: '/app/reports/stock_sumnmary', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Item Batch Report', url: '/app/reports/item_batch', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
				{ name: 'Stock Detail', url: '/app/reports/stock_detail', icon: 'Home', ionicon: 'icofont-list', exact: false, allowed: ['pharmacy', 'admin'] },
			]
		},
		{
			name: 'Settings', url: '/app/settings', icon: 'Home', ionicon: 'icofont-gear', exact: true, allowed: ['pharmacy', 'admin'],
			children: [
				{ name: 'Tax', url: '/app/settings', icon: 'Home', ionicon: 'icofont-list', exact: true, allowed: ['pharmacy', 'admin'] },
				{ name: 'Accounting Year', url: '/app/settings/accounting-year', icon: 'Home', ionicon: 'icofont-list', exact: true, allowed: ['pharmacy', 'admin'] },
			]
		},
	],
	bottom: [

	],
};
