import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Spin, message } from 'antd';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import {Api}  from '../../../utils/Variables';
import axios from 'axios';
import {LineLoader} from '../../../views/elements';

import PsContext from '../../../context/PsContext';

class AddBatch extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.keydownHandler = this.keydownHandler.bind(this);
	}
	
	componentDidMount(){		
		
		document.addEventListener('keydown',this.keydownHandler);
	}
	
	componentWillUnmount(){
		
		document.removeEventListener('keydown',this.keydownHandler);
		
	}
	
	keydownHandler(e){
		if(e.keyCode==27){ // Handle Esc button press
			this.handleModalClose();
		}
	}
	
	handleModalClose(){
		
		if(this.props.afterFinish)
			this.props.afterFinish();
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/phar/batch/save', $("#frm_pharBatch").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					document.getElementById("frm_pharBatch").reset();
					this.setState({showLoader: false});
					
					if(this.props.afterFinish){
						document.removeEventListener('keydown',this.keydownHandler);
						this.props.afterFinish(res['data'].data);
					}
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Un expected error');
			this.setState({showLoader: false});
		}
	}
	
	getValue(field){
	
		var d = this.props.productDetail;
		return d[field];
	}
	
	render(){

		return(
			<React.Fragment>
				<div className="cb__modal__header" >
					<Row>
						<Col md={10} >
							Add Batch
						</Col>
						<Col md={2} >
							<div className="text-right" >
								ESC
							</div>
						</Col>
					</Row>
				</div>
				
				<div className="cb__modal__body ml-20 mt-20 mr-20" >
					<LineLoader loading={this.state.showLoader} />
					<Spin spinning={this.state.showLoader} >
						<form action="" method="post" id="frm_pharBatch" onSubmit={this.handleFormSubmit} >
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="productid" value={this.getValue('id')} />
							<input type="hidden" name="tax" value={this.getValue('tax')} />
							<input type="hidden" name="packing_total" value={this.getValue('packing_total')} />
						
						<Row>
							<Col md={5} >
								<label className="font-13"  >Product Name <span className="text-danger">*</span></label>
							</Col>
							<Col md={7} >
								<Form.Control
									type="text"
									name="i__product"
									className="no-arrow" 
									size="sm"
									readOnly
									defaultValue={this.getValue('item_name')}
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={5} >
								<label className="font-13"  >Batch No <span className="text-danger">*</span></label>
							</Col>
							<Col md={7} >
								<Form.Control
									type="text"
									name="i__batch"
									className="no-arrow" 
									size="sm"
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={5} >
								<label className="font-13" >Expiry Month <span className="text-danger">*</span></label>
							</Col>
							<Col md={7} >
								<Form.Control
									type="month"
									name="i__expiry"
									size="sm"
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={5} >
								<label className="font-13" >Purchase Rate <span className="text-danger">*</span></label>
							</Col>
							<Col md={7} >
								<Form.Control
									type="text"
									name="purchase_rate"
									size="sm"
									onKeyPress={decimalKeyPress}
									defaultValue={this.getValue('amount')}
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={5} >
								<label className="font-13" >Sales Rate <span className="text-danger">*</span></label>
							</Col>
							<Col md={7} >
								<Form.Control
									type="text"
									name="sales_rate"
									size="sm"
									onKeyPress={decimalKeyPress}
									defaultValue={this.getValue('sales_rate')}
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={5} >
								<label className="font-13" >MRP <span className="text-danger">*</span></label>
							</Col>
							<Col md={7} >
								<Form.Control
									type="text"
									name="mrp"
									size="sm"
									onKeyPress={decimalKeyPress}
									defaultValue={this.getValue('mrp')}
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={5} >
								
							</Col>
							<Col md={7} >
								<div className="text-right" >
									<Button
										type="submit"
										variant="success"
										size="sm"
									>
										<i className="icofont-check"></i> Save Batch
									</Button>
								</div>
							</Col>
						</Row>
							
						</form>
					</Spin>
				</div>
			</React.Fragment>
		);
	}
	
}
export default AddBatch;
