import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message, Select } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, decimalKeyPress } from '../../../utils';
import { Api } from '../../../utils/Variables';

const AddBankAccount=(props)=>{
	
	const context = useContext(PsContext);
	
	const { Option } = Select;
	
	const [validated, setValidated] = useState(false);
	const [loader, setLoader] = useState(false);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [paymentDate, setPaymentDate] = useState(getToday());
	
	
	 const handleSubmit = (event) => {
		 event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {		  
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoader(true);
		axios.post(`${Api.Url}v1/phar/bankaccount/new`,$("#frm__cb__add_Bank_Acc").serialize()).then(res=>{
			if(res['data'].errorcode == '200' ){
				message.success(res['data'].content || 'Error');
				document.getElementById("frm__cb__add_Bank_Acc").reset();
				
				if(props.afterFinish)
					props.afterFinish();	
				
			}
			else{
				message.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});		
	};
	
	
	
	return(
		<>
			<Form noValidate validated={validated} onSubmit={handleSubmit} id="frm__cb__add_Bank_Acc" >
				
				<div className="cb__modal__body" >
				
				<input type="hidden" name="api" value={context.state.loggedApi} />
				<input type="hidden" name="acyear" value={context.state.loggedAcyear.id} />
				
				
				<Row>
					<Col md={4} >
						<label>Account No <span className="text-danger">*</span></label>
						<Form.Control
							type="text"
							name="acc_no"
							required
						/>
					</Col>
					<Col md={8} >
						<label>Account Display Name <span className="text-danger">*</span></label>
						<Form.Control
							type="text"
							name="display_name"
							required
						/>
					</Col>
				</Row>
				
				<Row className="mt-4" >					
					<Col md={3} >
						<label>Opening Balance <span className="text-danger"></span></label>
						<Form.Control
							type="text"
							name="opening_amount"
							defaultValue="0"
							onKeyPress={decimalKeyPress}
							className="text-right font-16"
							required
						/>
					</Col>
					<Col md={5} >
						<label>As of Date <span className="text-danger"></span></label>
						<Form.Control
							type="date"
							name="opening_date"
							defaultValue={momentDate(new Date(),'YYYY-MM-DD')}
							max={momentDate(new Date(),'YYYY-MM-DD')}
						/>
					</Col>
				</Row>
				
				</div>
				<div className="cb__modal__footer" >
				
						<div className="text-right" >
							<Button
								type="submit"
								size="sm"
								variant="primary"
								disabled={loader}
							>
							{loader ? <>
								<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
							</> : <>
								<i className="icofont-check pe-2 border-right" ></i> SAVE
							</>}
							</Button>
						</div>
				</div>
			</Form>
		</>
	);
};
export default AddBankAccount;