import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { Table } from 'antd';

import $ from 'jquery';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, numberToWords, price_in_words
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Spin, message, Select } from 'antd';
import{ LineLoader} from '../../../views/elements';
import PsContext from '../../../context/PsContext';

const { Option } = Select;

class SalesView extends Component{


static contextType = PsContext;


	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			billid : '',
			fromDate : '',
			dataList: [],
			dataView: [],
			paginationPageSize : 20,
			currentPage: 1,
			pagination: false,
			editData: [],
		}
	
	this.loadData = this.loadData.bind(this);
	
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	loadData()
	{
		try{
			
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedPharmacy','id'));
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('billid',this.props.billid);
			this.setState({dataList : [],dataView : []});
			
			let url = this.props.type && this.props.type=='return'
					? `${Api.Url}v1/phar/sales_return/listsalesbybillid`
					: `${Api.Url}v1/phar/sales/listsalesbybillid`;
			
			axios.post(url, form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
						
					});
					
					if(this.props.autoPrint)
						this.printDocument('invoice_container');
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getValue(fieldName) {
        try {

            var d = this.state.dataList;
            if (Object.keys(d).length > 0) {
                return d[0][fieldName];
            }
            else {
                return '';
            }
        }
        catch (error) {
            console.log(error);
            return '';
        }
    }
	
	getTrHeight(){
		try{
			var d = this.state.dataList;
			if(Object.keys(d).length>0)
			{
				var h = (20*(Object.keys(d).length+1));
				return (200-h)+'px';
			}
			else
			{
				return '200px';
			}
		}
		catch(error){
			console.log(error);
			return '200px';
		}
	}
	
	amountToWords(num){
		//var num =129000.9889;
		var splittedNum =num.toString().split('.')
		var nonDecimal=splittedNum[0]
		var decimal=splittedNum[1]
		var value=price_in_words(Number(nonDecimal))+" rupees ";
		if(parseFloat(decimal)>0){
		 value = value+ " and "+price_in_words(Number(decimal))+" paise"
		}
		return value;
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.afterFinish)
			 this.props.afterFinish();
	}
	
	render(){
		
		let sno=1;
		let pno=1;
		
		const columns = [
			{
				title: 'S.No',
				dataIndex: 'id',
				key: 'id',
				render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
				)
			},
			{
				title: 'Item Name',
				dataIndex: 'itemname',
				key: 'itemname',
			},
			{
				title: 'Quantity ',
				dataIndex: 'qty',
				key: 'qty',
			},
			{
				title: 'Amount',
				dataIndex: 'total',
				key: 'total',
			},
		
		];
		
		return(
			<div style={{display: this.props.autoPrint ? 'none' : 'inline'}} >
				<div id="invoice_container" >
					
					<div style={{width:'760px',background:'#fff',fontFamily:'sans-serif', fontSize:'12px'}} >
						<div style={{border:'0px solid black',height:'100%'}}>
							<table width="100%" align="center" style={{borderBottom:'1px solid grey'}} >
								<tr>
								{/*<td width="25%" >
										<img src={logo} style={{height:'120px',padding:'3px'}} />
								</td>*/}
									<td width="40%" style={{fontSize:'12px'}}  >
										<p style={{fontSize:'14px',fontWeight:'600',margin:'0px'}} >{this.context.state.loggedPharmacy.name}</p>
										<p style={{margin:'0px'}} >{this.context.state.loggedPharmacy.address}, {this.context.state.loggedPharmacy.city} 
										<br />
										{this.context.state.loggedPharmacy.state_name} {this.context.state.loggedPharmacy.country_name} - {this.context.state.loggedPharmacy.pincode}<br />
										Phone: {this.context.state.loggedPharmacy.phone}<br />
										Email: {this.context.state.loggedPharmacy.email}<br />
										
										</p>
									</td>
									<td width="35%" style={{fontSize:'12px'}}  >
										<p style={{fontSize:'13px',fontWeight:'600',margin:'0px'}} >
											<span style={{ fontWeight: 'bold', textTransform: 'uppercase'  }} >{this.getValue('patient_name')}</span> &nbsp;
											{this.getValue('patient_address')}<br />
											{this.getValue('patient_mobile')}<br />
											{this.getValue('customer_gst')?'GSTIN :'+ this.getValue('customer_gst'):''}<br />
											
										</p>
									</td>
									<td valign="top" >
										<p style={{margin:'0px',background:'#fff',color:'balck',padding:'3px',fontSize:'14px',fontWeight:'600'}} >
											TAX INVOICE
										</p>
										<table width="100%" style={{fontSize:'12px'}} >
											<tr><td width="35" >Type</td><td> : </td><td>{upperCase(this.getValue('sales_type'))} {upperCase(this.props.type)}</td></tr>
											<tr><td>No</td><td> : </td><td>{this.getValue('billno')}</td></tr>
											<tr><td>Date</td><td> : </td><td>{momentDate(this.getValue('billdate'), 'DD/MM/YYYY')}</td></tr>
										</table>
									</td>
								</tr>
							</table>
							
							<table width="100%" align="center" style={{border:'1px solid black',borderCollapse:'collapse',fontSize:'12px'}} >
								<thead>
									<tr>
										<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'center'}} height="20" width="45" >
											S.No
										</th>
										<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'center'}}  >	Item Description
										</th>
										<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'right'}} width="60" >
											Qty
										</th>
										<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'right'}} width="80" >
											Rate
										</th>
										{/*<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'right'}} width="70"  >
											Discount
										</th>*/}
										<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'right'}} width="60"  >
											GST(%)
										</th>
										<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'right'}} width="70"  >
											GST
										</th>
										<th style={{borderCollapse:'collapse',background:'#fff',padding:'3px',color:'black',border:'1px solid black',textAlign:'right'}} width="100" >	
											Amount
										</th>
									</tr>
								</thead>
								<tbody valign="top" >
								{this.state.dataList.map((item,i)=>{
									return <tr>
										<td height="20px" style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'center'}} >
											{sno++}
										</td>
										<td style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'left'}}>
											{item.print_name || item.itemname}
										</td>
										<td style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'right'}}>
											{item.qty}
										</td>
										<td style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'right'}}>
											{item.rate_per_uom || item.sales_rate}
										</td>
										{/*<td style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'right'}}>
											{parseFloat(item.itemdiscount)>0 ? <span>&#8377; {item.itemdiscount}</span> : 0.00 }
										</td>*/}
										<td style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'right'}}>
											{item.gst} %
										</td>
										<td style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'right'}}>
											{item.gst_amount}
										</td>
										<td style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'right'}}>
											{item.total}
										</td>
									</tr>;
								})
								}
									
									<tr style={{height:this.getTrHeight()}} >
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} ></td>
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} ></td>
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} ></td>
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} ></td>
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} ></td>
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} ></td>
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} ></td>
									</tr>
									<tr>
										<td colSpan="4" style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'left'}} >
										GST Amount (in words) <br />
											<b> {this.amountToWords(this.getValue('gst_total'))} only.</b><br />
										</td>
										<td colSpan="2" style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} >
											<b>Sub Total</b><br />
											CGST <br />
											SGST <br />
											Discount <br />
											{/*Roundoff <br />*/}
										</td>
										<td style={{borderCollapse:'collapse',padding:'6px',border:'1px solid black',textAlign:'right'}} >
											<b>&#8377; {this.getValue('grand_total')}</b><br />
											&#8377; {((parseFloat(this.getValue('gst_total'))/2).toFixed(2))}<br />
											&#8377; {((parseFloat(this.getValue('gst_total'))/2).toFixed(2))}<br />
											&#8377; {this.getValue('discount')}<br />
												{/*&#8377; {this.getValue('roundoff')}<br />*/}
										</td>
									</tr>
									<tr>
										<td colSpan="4" height="20" style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black',textAlign:'left'}} >
											
											Total Amount (in words) <br />
											<b> {this.amountToWords(this.getValue('net_amount'))} only.</b>
										</td>
										<td colSpan="3" style={{borderCollapse:'collapse',padding:'0px',border:'1px solid black',textAlign:'right'}} >
											<p style={{margin:'0px',background: '#fff',color:'black',fontSize: '13px',fontWeight:'bold',padding:'3px 5px'}}>
												Total &emsp; Rs. {this.getValue('net_amount')}<br />
												<table width="100%" style={{fontSize: '13px'}}>
													<tbody>
													{/*this.state.paymentList.map((item1,i)=>{
														if(item1.entrytype == 'payment'){
															return <tr>
																<td align="right">Advance {pno++} ({item1.refdate}) &emsp; Rs. {item1.amount}</td>
															</tr>
														}
													})
														<tr>
															<td align="right"><b>Balance &emsp; Rs. {parseFloat(this.state.balance).toFixed(2)}</b></td>
														</tr>*/}
													</tbody>	
												</table>
											</p>	
											
										</td>
									</tr>
									<tr>
										<td colSpan="7" height="10" style={{borderCollapse:'collapse',padding:'3px',border:'1px solid black'}} >
										<div style={{float:'left'}} >
											{/*DPCO Items :<br />*/}
											No tax is Payable on reverse charge basis
											<br />
											Customer Signature
										</div>
										<div style={{float:'right'}} >
											<br /><br />
											<b style={{fontSize:'14px'}} >For {this.context.state.loggedPharmacy.name}</b>
										</div>
										</td>
									</tr>
								</tbody>
							</table>
							{/*<table width="100%" align="center" >
								<tr>
									<td colSpan="6" align="center" style={{border:'0px',fontSize:'12px',paddingTop:'5px'}} >
										This is computer generated document
									</td>
								</tr>
							</table>*/}
						</div>
					</div>
					
				</div>
			</div>
		);
	}
}
export default SalesView;
