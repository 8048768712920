import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button, Spinner, Modal, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, upperCase, typeName, startDateOfMonth, endDateOfMonth } from '../../../utils';
import { Api } from '../../../utils/Variables';

import BankWithdrawDeposit from './bankWithdrawDeposit';
import BankAdjust from './bankAdjust';
import PSDropDown from '../components/PSDropDown';
import NoDataFound from '../components/NoDataFound';

const BankTransactions=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [reportDate, setReportDate] = useState(startDateOfMonth());
	const [toDate, setToDate] = useState(endDateOfMonth());
	const [loader, setLoader] = useState(false);
	
	const [showCashAdjust, setCashAdjust] = useState(false);
	const [showBankAdjust, setBankAdjust] = useState(false);
	const [adjustmentType, setAdjustmentTypeashAdjust] = useState('bank_to_cash');
	const [cashInHand, setCashInHand] = useState(0);
	const [data, setData] = useState([]);
	
	const [banks, setBanks] = useState([]);
	const [selectedBank, setSelectedBank] = useState([]);
	
	const loadReport=()=>{
		setData([]);
		
		if(!selectedBank || !selectedBank.id){
			message.error('Please select an account');
			return;
		}
		setLoader(true);
		
		var form = new FormData();
		form.append('api', context.state.loggedApi);
		form.append('from_date',reportDate);
		form.append('to_date',toDate);
		
		form.append('supplier', selectedBank.id);
		form.append('i__accountingYear',context.state.loggedAcyear.id);
		form.append('hospital_id',context.state.loggedCompany.id);
		
		
		axios.post(Api.Url+'v1/phar/purchase/party-balance', form).then(res=>{
			if(res['data'].status=='1'){
				setData(res['data'].data);
				setCashInHand(res['data'].cash_in_hand);
				setLoader(false);
			}
			else{
				setCashInHand(res['data'].cash_in_hand);
				//message.error(res['data'].message || 'Error');
				setLoader(false);
			}
		});
		
	};
	
	const currentAmount=(item)=>{
		if(parseFloat(item.debit_amount)>0){
			return <span className="text-danger">{item.debit_amount}</span>;
		}
		else{
			return <span className="text-success">{item.credit_amount}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {amt}</span>;
		}
		else{
			return <span className="text-danger">&#8377; {amt}</span>;
		}
	};
	
	const loadBanks=()=>{
		setLoader(true);
		setBanks([]);
		axios.get(`${Api.Url}v1/phar/bankaccounts?api=${context.state.loggedApi}&status=all`).then(res=>{
			if(res['data'].errorcode=='200'){
				setBanks(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadBanks();
	},[]);
	
	const bankChange=(e)=>{
		setData([]);
		let id = e.target.value;
		let bnk = banks.filter(item=> item.id==id);
		if(bnk.length>0){
			setSelectedBank(bnk[0]);
		}
	};
	
	return(
		<div className="panel panel-form">
		
			<div className="panel-header">
				<Row>
					<Col md={6} >
						Bank Transactions 						
					</Col>
					<Col md={6} >
						<div className="text-right" >
							<Button
								type="button"
								size="sm"
								variant="primary"
								onClick={()=>{
									setAdjustmentTypeashAdjust('bank_to_cash');
									setCashAdjust(true);
								}}
							>
								<i className="icofont-align-right pe-1 border-right"></i> Bank to Cash Transfer
							</Button>
							&nbsp;
							<Button
								type="button"
								size="sm"
								variant="primary"
								onClick={()=>{
									setAdjustmentTypeashAdjust('cash_to_bank');
									setCashAdjust(true);
								}}
							>
								<i className="icofont-align-left pe-1 border-right"></i> Cash to Bank Transfer
							</Button>
							&nbsp;
							<Button
								type="button"
								size="sm"
								variant="primary"
								onClick={()=>{
									setBankAdjust(true);
								}}
							>
								<i className="icofont-align-center pe-1 border-right"></i> Adjust Bank Balance
							</Button>
						</div>
					</Col>
				</Row>
				
			</div>
			
			<div className="panel-body">
				
				<Row>
					<Col md={3} >
						<InputGroup size="sm">
							<InputGroup.Text>Bank</InputGroup.Text>
							<Form.Control
								as="select"
								size="sm"
								name="supplier"
								onChange={(e)=> bankChange(e)}
							>
								<option value=""> Select </option>
								{banks && banks.length>0 && (banks.map((item,i)=>{
									return <option value={item.id} key={i} > {item.name} </option>;
								}))
								}
							</Form.Control>
						</InputGroup>
					</Col>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={reportDate}
							max={getToday()}
							onChange={(e)=> setReportDate(e.target.value) }
						/>
					</Col>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={toDate}
							max={endDateOfMonth()}
							onChange={(e)=> setToDate(e.target.value) }
						/>
					</Col>
					<Col md={1} >
						<Button
							type="button"
							size="sm"
							variant="default"
							block
							onClick={()=> loadReport() }
						>
							VIEW
						</Button>
					</Col>
				</Row>
					<div className="table-responsive mt-10" >
						<table className="table table-striped-lite table-hover tableFixHead">
							<thead>
								<tr>
									<th>TYPE</th>
									<th>TXN NO</th>
									<th>NAME</th>
									<th width="180"  >DATE</th>
									<th width="180" >AMOUNT</th>
								</tr>
							</thead>
							<tbody>
							{data && data.length>0 && (data.map((item,i)=> {
								return <tr key={i} >
									<td>{typeName(item.voucher_type)}</td>
									<td>{item.invoice_id}</td>
									<td>{item.to_name}</td>
									<td>{momentDate(item.voucher_date, 'DD/MM/YYYY')}</td>
									<td align="right">{currentAmount(item)}</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
					  </center>)}
					
					<NoDataFound data={data} loader={loader} />
				
			</div>
			
			<div className="panel-footer">
				<Row>
					<Col>
						<div className="text-right font-weight-600 f-s-20" >
							{selectedBank && selectedBank.id && (<span>Current Balance :  {showCashInHand(cashInHand)}</span>)}
						</div>
					</Col>
				</Row>
			</div>
			
			<Modal 
				show={showCashAdjust} 
				onHide={()=> setCashAdjust(false) } 
				className="cb__modal__search" 
				size="md" 
				backdrop="static" 
			>
				<Modal.Header closeButton >
				{adjustmentType=='bank_to_cash' ? 'Cash Withdraw' : 'Cash Deposit'}
				</Modal.Header>
				<Modal.Body>
					<BankWithdrawDeposit
						type={adjustmentType}
						afterFinish={()=> loadReport() }
						bankId={selectedBank.id}
					/>
				</Modal.Body>
			</Modal>
			
			<Modal 
				show={showBankAdjust} 
				onHide={()=> setBankAdjust(false) } 
				className="cb__modal__search" 
				size="sm" 
				backdrop="static" 
			>
				<Modal.Header closeButton >
					Bank Adjustment Entry
				</Modal.Header>
				<Modal.Body>
					<BankAdjust
						afterFinish={()=> loadReport() }
						bankId={selectedBank.id}
					/>
				</Modal.Body>
			</Modal>
			
		</div>
	);
};
export default BankTransactions;