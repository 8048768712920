import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, upperCase } from '../../../utils';
import { Api } from '../../../utils/Variables';

import NoDataFound from '../components/NoDataFound';
import SalesView from '../Sales/SalesView';

const DailySales=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [fromDate, setFromDate] = useState(getToday());
	const [toDate, setToDate] = useState(getToday());
	const [loader, setLoader] = useState(false);
	const [cashInHand, setCashInHand] = useState(0);
	const [data, setData] = useState([]);
	
	const [billId, setBillId] = useState(null);
	const [print, setPrint] = useState(false);
	const [view, setView] = useState(false);
	
	const loadReport=()=>{
		setData([]);
		setLoader(true);
		
		var form = new FormData();
		form.append('api', context.state.loggedApi);
		form.append('from_date',fromDate);
		form.append('to_date', toDate);
		form.append('report',"1");
		
		form.append('supplier', '1');
		form.append('i__accountingYear',context.state.loggedAcyear.id);
		form.append('hospital_id',context.state.loggedCompany.id);
		
		axios.post(Api.Url+'v1/phar/sales/listall', form).then(res=>{
			if(res['data'].status=='1'){
				setData(res['data'].data);
				//setCashInHand(res['data'].cash_in_hand);
				setLoader(false);
			}
			else{
				//message.error(res['data'].message || 'Error');
				setLoader(false);
			}
		});
		
	};
	
	const currentAmount=(item)=>{
		if(parseFloat(item.credit_amount)>0){
			return <span className="text-danger">{item.credit_amount}</span>;
		}
		else{
			return <span className="text-success">{item.debit_amount}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {amt}</span>;
		}
		else{
			return <span className="text-danger">&#8377; {amt}</span>;
		}
	};
	
	useEffect(()=>{
		
		loadReport();
	},[]);
	
	return(
		<div className="panel panel-form">
		
			<div className="panel-header">
				<Row>
					<Col md={6} >
						Sales Report
					</Col>
					<Col md={6} >
						<div className="text-right" >
							<Link 
								to="/app/sales_entry"
								className="btn btn-primary btn-xs" 
							>
								<i className="icofont-plus pe-2 border-right"></i> Sales Entry
							</Link>
						</div>
					</Col>
				</Row>
			</div>
			
			<div className="panel-body" >
				<Row>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={fromDate}
							max={getToday()}
							onChange={(e)=> setFromDate(e.target.value) }
						/>
					</Col>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={toDate}
							max={getToday()}
							onChange={(e)=> setToDate(e.target.value) }
						/>
					</Col>
					<Col md={1} >
						<Button
							type="button"
							size="sm"
							variant="default"
							block
							onClick={()=> loadReport() }
						>
							VIEW
						</Button>
					</Col>
				</Row>
			
				
					
					<div className="table-responsive mt-10" >
						<table className="table table-striped-lite table-hover  tableFixHead ">
							<thead>
								<tr>
									<th width="60" >S.NO</th>
									<th>BILL.NO</th>
									<th>BILL DATE</th>
									<th>NAME</th>
									<th>PHONE</th>
									<th width="120"  >AMOUNT</th>
									<th width="100" >MANAGE</th>
								</tr>
							</thead>
							<tbody>
							{data && data.length>0 && (data.map((item,i)=> {
								return <tr key={i} >
									<td>{i+1}</td>
									<td>{item.billno}</td>
									<td>{momentDate(item.billdate, 'DD/MM/YYYY')}</td>
									<td>{item.patient_name}</td>
									<td>{item.patient_mobile}</td>
									<td align="right">{item.net_amount}</td>
									<td align="center">
										<Button type="button" size="xs" variant="purple" onClick={()=>{
											setBillId(item.billid);
											setPrint(true);
										}}
										>
											<i className="icofont-print"></i>
										</Button> &nbsp;
										<Button type="button" size="xs" variant="default" onClick={()=>{
											setBillId(item.billid);
											setView(true);
										}}
										>
											<i className="icofont-eye"></i>
										</Button>
									</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
					 </center>)}
					
					<NoDataFound data={data} loader={loader} />
					
				
			</div>
			
			 {print && (<SalesView 
				billid={billId} 
				afterFinish={()=>{
					setBillId(null);
					setPrint(false);
				}}
				autoPrint 
			/>)}
			
			<Modal show={view} size="lg" className="" onHide={()=>{
				setBillId(null);
				setView(false);
			}} >
				<Modal.Header closeButton>
					VIEW
				</Modal.Header>
				<Modal.Body>
					<SalesView 
						billid={billId} 
						afterFinish={()=>{
							setBillId(null);
							setView(false);
						}}								
					/>
				</Modal.Body>
			</Modal>
			
		</div>
	);
};
export default DailySales;