import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, decimalKeyPress } from '../../../utils';
import { Api } from '../../../utils/Variables';

const BankWithdrawDeposit=(props)=>{
	
	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [loader, setLoader] = useState(false);
	
	const [banks, setBanks] = useState([]);
	const [selectedBank, setSelectedBank] = useState([]);
	
	 const handleSubmit = (event) => {
		  event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {		  
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		//setValidated(false);
		setLoader(true);
		axios.post(`${Api.Url}v1/phar/bankentry/new`, $("#frm_cb__Bank_Adjust").serialize()).then(res=>{
			if(res['data'].errorcode == '200' ){
				message.success(res['data'].content || 'Success');
				document.getElementById("frm_cb__Bank_Adjust").reset();
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				message.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});

	  };
	  
	  
	const loadBanks=()=>{
		setLoader(true);
		setBanks([]);
		axios.get(`${Api.Url}v1/phar/bankaccounts?api=${context.state.loggedApi}&status=all`).then(res=>{
			if(res['data'].errorcode=='200'){
				setBanks(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadBanks();
	},[]);
	
	const bankChange=(e)=>{
		setSelectedBank([]);
		let id = e.target.value;
		let bnk = banks.filter(item=> item.id==id);
		if(bnk.length>0){
			setSelectedBank(bnk[0]);
		}
	};
	
	return(
		<div>
			<Form noValidate validated={validated} onSubmit={handleSubmit} id="frm_cb__Bank_Adjust" >
			
			<div className="cb__modal__body" >
			
				<input type="hidden" name="api" value={context.state.api} />
				<input type="hidden" name="acyear" value={context.state.loggedAcyear.id} />
				
				{props.type=='bank_to_cash' && (<>
					<Row>
						<Col md={6} >
							<label>From <span className="text-danger">*</span></label>
							<Form.Control
								as="select"
								size="sm"
								name="account_from"
								onChange={(e)=> bankChange(e)}
							>
								{banks && banks.length>0 && (banks.map((item,i)=>{
									return <option value={item.id} key={i} selected={item.id==props.bankId ? 'selected' : ''} > {item.name} </option>;
								}))
								}
							</Form.Control>
						</Col>
					
						<Col md={6} >
							<label>To <span className="text-danger">*</span></label>
							<Form.Control
								value="Cash"
							/>	
							<input type="hidden" name="account_to" value="1" />
							<input type="hidden" name="voucher_type" value="cash_withdraw" />
						</Col>
					</Row>
				</>)}
				
				{props.type=='cash_to_bank' && (<>
					<Row>
						<Col md={6} >
							<label>From <span className="text-danger">*</span></label>
							<Form.Control
								value="Cash"
							/>	
							<input type="hidden" name="account_from" value="1" />
							<input type="hidden" name="voucher_type" value="cash_deposit" />
						</Col>
						<Col md={6} >
							<label>To <span className="text-danger">*</span></label>
							<Form.Control
								as="select"
								size="sm"
								name="account_to"
								onChange={(e)=> bankChange(e)}
							>
								{banks && banks.length>0 && (banks.map((item,i)=>{
									return <option value={item.id} key={i} selected={item.id==props.bankId ? 'selected' : ''}  > {item.name} </option>;
								}))
								}
							</Form.Control>
						</Col>				
						
					</Row>
				</>)}
				
				
				
				<Row className="mt-3" >
					<Col md={6} >
						<label>Enter Amount <span className="text-danger">*</span></label>
						<Form.Control
							type="text"
							name="adjustment_amount"
							onKeyPress={decimalKeyPress}
							required
						/>	
					</Col>
					<Col md={6} >
						<label>Adjustment Date <span className="text-danger">*</span></label>
						<Form.Control
							type="date"
							name="adjustment_date"
							defaultValue={momentDate(new Date(), 'YYYY-MM-DD')}
							max={momentDate(new Date(), 'YYYY-MM-DD')}
							required
						/>	
					</Col>
				</Row>
				
				<Row className="mt-3" >
					<Col md={12} >
						<label>Description <span className="text-danger"></span></label>
						<Form.Control
							as="textarea"
							name="adjustment_description"
							rows="3"
						/>	
					</Col>
				</Row>
			
			</div>
			<div className="cb__modal__footer" >
				<Row className="" >
					<Col>
						<div className="text-right" >						
							<Button
								type="submit"
								size="sm"
								variant="primary"
								disabled={loader}
							>
							{loader ? <>
								<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
							</> : <>
								<i className="icofont-check pe-2 border-right" ></i> SAVE ENTRY
							</>}
							</Button>
						</div>
					</Col>
				</Row>
			</div>
			
			</Form>
		</div>
	);
};
export default BankWithdrawDeposit;