import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal, InputGroup, ButtonGroup , } from 'react-bootstrap';

import NewPurchaseEntryScreen from './NewPurchaseEntryScreen';

class NewPurchaseReturnEntry extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			
		}
		
	}
	
	render(){
		return(
		<>
			<NewPurchaseEntryScreen type="return" {...this.props} />
		</>
		);
	}
	
		
}
export default NewPurchaseReturnEntry;
