import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import{ LineLoader} from '../../../views/elements';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Spin, message, Table} from 'antd';
import PsContext from '../../../context/PsContext';
import AddCustomer from './AddCustomer';
import EditCustomer from './EditCustomer';

class Customer extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddModal :false,
			dataList: [],
			dataView: [],
			paginationPageSize : 20,
			currentPage: 1,
			showEditModal: false,
			editData: [],
		}
		
		this.handleAddModalOpen = this.handleAddModalOpen.bind(this);
		this.handleAddModalClose = this.handleAddModalClose.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handleEditModalClose = this.handleEditModalClose.bind(this);

		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleTablePageChange = this.handleTablePageChange.bind(this);
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}
	
	handleTablePageChange(current){
		this.setState({cureentPage: current});
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	handleAddModalOpen(){
		this.setState({showAddModal: true});
	}
	
	handleAddModalClose(){
		this.setState({showAddModal: false});
	}
	
	loadData()
	{
		try{
			
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/phar/customer/listall?api='+this.context.state.api+'&status=all').then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
						showEditModal: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearch(e){
		try{
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var s = d.filter(function(obj){
				return( (upperCase(obj.name).indexOf(v)>-1) ||
				(upperCase(obj.mobile).indexOf(v)>-1) ||
				(upperCase(obj.email).indexOf(v)>-1) );
			});
			this.setState({dataView: s});
		}
		catch(er){console.log(er); }
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to delete the record ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/phar/customer/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showLoader: false});
		}
	}
	
	handleEditModalOpen(record){
		this.setState({
			editData: record,
			showEditModal: true,
		});
	}
	
	handleEditModalClose(){
		this.setState({
			editData: [],
			showEditModal: false,
		});
	}
	
	render(){
	
		const columns = [
		  {
			title: 'S.No',
			dataIndex: 'id',
			key: 'id',
			render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
			)
		  },
		  {
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (text, record)=>(
				<>
				{record.name} - {record.place}
				</>
			)
		  },
		  {
			title: 'Mobile',
			dataIndex: 'mobile',
			key: 'mobile',
		  },
		  {
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		  },
		  {
			title: 'Opening Balance',
			dataIndex: 'opening_balance',
			key: 'opening_balance',
		  },
		  {
			title : 'Manage',
			dataIndex : 'id',
			key : 'id',
			width: '130px',
			render : (text,record)=>(
				<center>
					<Button type="button" size="sm" variant="primary" onClick={this.handleEditModalOpen.bind(this, record)} > 
						<i className="icofont-edit"></i>
					</Button>
					&nbsp;
					<Button type="button" size="sm" variant="danger" onClick={this.handleDeleteActionClick.bind(this, record)} > 
						<i className="icofont-trash"></i>
					</Button>
				</center>
			)
		  }
		];
		
		const showCashInHand=(amt)=>{
			if(parseFloat(amt)>0){
				return <span className="text-success">&#8377; {Math.abs(amt).toFixed(2)} (Cr)</span>;
			}
			else{
				return <span className="text-danger">&#8377; {Math.abs(amt).toFixed(2)} (Dr)</span>;
			}
		};
		
		return(
			<div className="panel panel-form ">
			<div className="panel-header" >
			<Row>
				<Col md={4} >Parties</Col>
				<Col md={8} >
				<div className="text-right" >
				<Button
					size="xs"
					type="button"
					variant="default"
					onClick={this.loadData} 
				>
					<i className="icofont-refresh"></i>
				</Button>
				&nbsp;
				<Button
					size="xs"
					type="button"
					variant="primary"
					onClick={this.handleAddModalOpen} 
				>
					<i className="icofont-plus"></i> Create Party
				</Button>
				</div>
				</Col>
				</Row>
			</div>
				<LineLoader loading={this.state.showLoader} />
				
				{/*<div className="top-sticky-header" >
					
						<Row>
							<Col md={7}>
								<h1 className="page-header" >Parties</h1>
							</Col>
							<Col md={3} >
								<Form.Control type="text" size="sm" name="i__search" placeholder="Search by Party Name, Mobile..." onChange={this.handleSearch} />
							</Col>
							<Col md={2}>
								<div className="text-right" >
									<Button
										size="sm"
										type="button"
										variant="default"
										onClick={this.loadData} 
									>
										<i className="icofont-refresh"></i>
									</Button>
									&nbsp;
									<Button
										size="sm"
										type="button"
										variant="default"
										onClick={this.handleAddModalOpen} 
									>
										<i className="icofont-plus"></i> New
									</Button>
									
								</div>
							</Col>
						</Row>
					
				</div>
				
				*/}
				
				<div className="panel panel-body">
					<div className="">
						
						<Row>							
							<Col md={3} >
								<Form.Control type="text" size="sm" name="i__search" placeholder="Search by Party Name, Mobile..." onChange={this.handleSearch} />
							</Col>
							<Col md={2}>
									
							</Col>
						</Row>
						
						<div className="table-responsive bg-white mt-10 tableFixHead" >
							<table className="table table-bordered" >
								<thead>
									<tr>
										<th width="60" >S.NO</th>
										<th>PARTY NAME</th>
										<th width="140" >MOBILE</th>
										<th>EMAIL</th>
										<th width="120" className="text-uppercase" >Receivable BAL.</th>
										<th width="120" className="text-uppercase" >Payable  BAL.</th>
										<th width="100" align="center" >MANAGE</th>
									</tr>
								</thead>
								<tbody>
								{this.state.dataView.map((item,i)=>{
									return <tr key={i} >
										<td>{i+1}</td>
										<td>{item.name} {item.place ? '-' : ''} {item.place}</td>
										<td>{item.mobile}</td>
										<td>{item.email}</td>
										<td align="right" >{item.current_balance<0 && (showCashInHand(item.current_balance))}</td>
										<td align="right" >{item.current_balance>-1 && (showCashInHand(item.current_balance))}</td>
										<td align="center" >
											<Button type="button" size="xs" variant="purple" onClick={this.handleEditModalOpen.bind(this, item)} > 
												<i className="icofont-edit"></i>
											</Button>
											&nbsp;
											<Button type="button" size="xs" variant="default" onClick={this.handleDeleteActionClick.bind(this, item)} > 
												<i className="icofont-trash"></i>
											</Button>
										</td>
									</tr>;
								})
								}
							
								</tbody>
							</table>
							</div>
							
					</div>

				</div>

				{/*<div className="mt-10 ps-table" >
					<Table 
					size="small"
					columns={columns} 
					bordered
					footer={() => `Total ${Object.keys(this.state.dataView).length} customers in list`} 
					loading={this.state.showLoader}
					dataSource={this.state.dataView}
					rowClassName={ (record, index) => {  return record.active_status=='0' ? 'bg-danger-light' : null; }   }
					pagination={{ 
						pageSize: this.state.paginationPageSize,
						onShowSizeChange: this.handlePaginationSizeChange,
						position: ['bottomRight'],
						onChange: this.handleTablePageChange,
					}} />
				</div>*/}
			
					
				<Modal show={this.state.showAddModal} onHide={this.handleAddModalClose} backdrop="static" className="cb__modal__search" size="md" >
					<Modal.Header closeButton >
						Add Party
					</Modal.Header>
					<Modal.Body>
						<AddCustomer afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditModal} onHide={this.handleEditModalClose} backdrop="static" className="cb__modal__search" size="md" >
					<Modal.Header closeButton >
						Edit Party
					</Modal.Header>
					<Modal.Body>
						<EditCustomer editData={this.state.editData} afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default Customer;
