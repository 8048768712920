import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message, Select } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, decimalKeyPress } from '../../../utils';
import { Api } from '../../../utils/Variables';
import SalesView from '../Sales/SalesView';

const ChequeTransfer=(props)=>{
	
	const context = useContext(PsContext);
	
	const { Option } = Select;
	const { data } = props;
	
	const [validated, setValidated] = useState(false);
	const [loader, setLoader] = useState(false);
	const [paymentType, setPaymentType] = useState(null);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [paymentDate, setPaymentDate] = useState(getToday());
	const [banks, setBanks] = useState([]);
	
	 const handleSubmit = (event) => {
		 event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {		  
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		setLoader(true);
		axios.post(`${Api.Url}v1/phar/cheques/save_trans`,$("#frm__cb__add_Cheque_Trans").serialize()).then(res=>{
			if(res['data'].errorcode == '200' ){
				message.success(res['data'].content || 'Error');
				document.getElementById("frm__cb__add_Cheque_Trans").reset();
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				message.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});		
	};
	
	const loadBanks=()=>{
		setLoader(true);
		setBanks([]);
		axios.get(`${Api.Url}v1/phar/bankaccounts?api=${context.state.loggedApi}&status=all`).then(res=>{
			if(res['data'].errorcode=='200'){
				setBanks(res['data'].data);
			}
			setLoader(false);
		});
	};
	 
	useEffect(()=>{
		
		loadBanks();
	},[]);
	
	
	
	const showBalance=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success font-weight-600">BAL :  &#8377; {Math.abs(amt).toFixed(2)}</span>;
		}
		else{
			return <span className="text-danger font-weight-600">BAL :  &#8377; {Math.abs(amt).toFixed(2)}</span>;
		}
	};
	
	return(
		<>
			<Form noValidate validated={validated} onSubmit={handleSubmit} id="frm__cb__add_Cheque_Trans" >
			
			<div className="cb__modal__body" >
				
				<input type="hidden" name="api" value={context.state.loggedApi} />
				<input type="hidden" name="acyear" value={context.state.loggedAcyear.id} />
				<input type="hidden" name="i__id" value={data.id} />
									
						<Row>
							<Col md={11}>
								<label>
								{data.voucher_type=='payment_out' ? 'Paid To ' : 'Received From'} : <b>{data.to_name}</b>
								</label>
							</Col>
						</Row>
						
						<Row className="mt-3" >
							<Col md={6}>
								<label>
								Cheque Amount : <b>{data.amount}</b>
								</label>
							</Col>
							<Col md={6}>
								<label>
								Cheque Ref.No : <b>{data.ref_no}</b>
								</label>
							</Col>
						</Row>
						
						<Row className="mt-3" >
							<Col md={6} >
								<label>
								{data.voucher_type=='payment_out' ? 'WITHDRAW FROM' : 'DEPOSIT TO'}
								<span className="text-danger">*</span></label>
								<Form.Control
									as="select"
									name="i__bankid"
									required
									onChange={(e)=> setPaymentType(e.target.value) }
								>
									<option value=""> Select </option>
									<option value="1"> Cash </option>
									{banks && banks.length>0 && (banks.map((item,i)=>{
										return <option value={item.id} key={i} > {item.name} </option>;
									}))
									}
								</Form.Control>
							</Col>
						</Row>						
						
						<Row className="mt-2" >
							<Col md={12} >
								<label>Transfer Date</label>
								<Form.Control
									type="date"
									name="i__receiptDate"
									size="sm"
									defaultValue={paymentDate}
									max={getToday()}
								/>
							</Col>
						</Row>
						
						<Row className="mt-2" >
							<Col md={12} >
								<label>Description <span className="text-danger"></span></label>
								<Form.Control
									as="textarea"
									name="i__description"
									rows="3"
								/>
							</Col>
						</Row>
					
				</div>
				<div className="cb__modal__footer" >				
					<Row>
						<Col md={12} >
							<div className="text-right" >
								<Button
									type="submit"
									size="sm"
									variant="primary"
									disabled={loader}
								>
								{loader ? <>
									<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
								</> : <>
									<i className="icofont-check pe-2 border-right" ></i> SAVE ENTRY
								</>}
								</Button>
							</div>
						</Col>
					</Row>
				</div>
				
			</Form>
		</>
	);
};
export default ChequeTransfer;