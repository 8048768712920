import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Modal,
  NavDropdown,
  Spinner,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  getls,
  integerIndMobile,
  lettersOnly,
  integerAadhar,
  integerKeyPress,
  decimalKeyPress,
  upperCase,
  momentDate,
} from "../../../utils";
import { Api } from "../../../utils/Variables";
import axios from "axios";
import { Spin, message, Table } from "antd";
import PsContext from "../../../context/PsContext";
import ProductMenu from "../ProductMenu";
import Sidebar from "./Sidebar";
import AddItem from "./AddItem";
import EditItem from "./EditItem";
import EditBatch from "./EditBatch";
import NoDataFound from "../components/NoDataFound";
import XLSX from "xlsx";

const Products = () => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [viewData, setViewData] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editBatch, setEditBatch] = useState(false);
  const [batches, setBatches] = useState([]);
  const [editData, setEditData] = useState([]);

  const [collapseId, setCollapseId] = useState("");

  const loadData = () => {
    setLoader(true);
    setData([]);
    setViewData([]);
    var form = new FormData();
    form.append("api", context.state.api);
    form.append("status", 1);
    axios.post(Api.Url + "v1/phar/item/listall", form).then((res) => {
      if (res["data"].status == "1") {
        setData(res["data"].data);
        setViewData(res["data"].data);
      }
      setLoader(false);
    });
  };

  const loadBatches = () => {
    setBatches([]);
    setLoader(true);
    axios
      .get(
        Api.Url +
          "v1/phar/reports/batch_stock_summary?api=" +
          context.state.loggedApi
      )
      .then((res) => {
        if (res["data"].errorcode == "200") {
          setBatches(res["data"].data);
          setLoader(false);
        } else {
          //message.error(res['data'].message || 'Error');
          setLoader(false);
        }
      });
  };

  useEffect(() => {
    loadBatches();
    loadData();
  }, []);

  const handleSearch = (e) => {
    var v = upperCase(e.target.value);
    var s = data.filter(function (obj) {
      return (
        upperCase(obj.item_name).indexOf(v) > -1 ||
        upperCase(obj.code).indexOf(v) > -1
      );
    });
    setViewData(s);
  };

  const handleDeleteActionClick = (record) => {
    if (!window.confirm("Do you want to delete the Item?")) {
      return;
    }

    setLoader(true);
    var form = new FormData();
    form.append("api", context.state.api);
    form.append("i__id", record.id);

    axios.post(Api.Url + "v1/phar/item/remove", form).then((res) => {
      if (res["data"].status == "1") {
        var s = data.filter((item) => item.id != record.id);
        setData(s);
        setViewData(s);
        message.success(res["data"].message || "Success");
      } else {
        message.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const onCollapse = (id) => {
    if (collapseId == id) {
      setCollapseId("");
    } else {
      setCollapseId(id);
    }
  };

  const showStock = (item) => {
    if (parseFloat(item.stock) < 0) {
      return <span className="text-danger">{item.stock}</span>;
    } else {
      return <span className="text-success">{item.stock}</span>;
    }
  };

  const batchList = (item) => {
    var v = batches.filter((batch) => batch.product_id == item.id);
    var rv = [];
    v.map((bt, i) => {
      rv.push(
        <tr keyy={i}>
          <td>{bt.batch}</td>
          <td>{bt.expiry}</td>
          <td align="right">&#8377; {bt.purchase_rate}</td>
          <td align="right">&#8377; {bt.mrp}</td>
          <td align="right">&#8377; {bt.piece_sale_rate}</td>
          <td align="right">{showStock(bt)}</td>
          <td align="center">
            <Button
              type="button"
              size="xs"
              onClick={() => {
                var s = bt;
                s["item_name"] = item.item_name;
                setEditData(s);
                setEditBatch(true);
              }}
            >
              <i className="icofont-edit"></i>
            </Button>
            &nbsp;
            <Button type="button" size="xs" onClick={() => deleteBatch(bt)}>
              <i className="icofont-close"></i>
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <table className="table table-sm table-bordered table-hover">
        <thead>
          <tr>
            <th>Batch</th>
            <th>Expiry</th>
            <th width="80">Pur. Price</th>
            <th width="80">MRP</th>
            <th width="80">Sale Price</th>
            <th width="80">Stock Qty</th>
            <th width="80" align="center">
              Manage
            </th>
          </tr>
        </thead>
        <tbody>{rv}</tbody>
      </table>
    );
  };

  const deleteBatch = (item) => {
    if (parseFloat(item.stock) != 0) {
      message.error("You are allowed to delete zero stock items only");
      return;
    }

    if (!window.confirm("Do you want to delte?")) {
      return;
    }

    setLoader(true);
    var form = new FormData();
    form.append("api", context.state.api);
    form.append("i__id", item.batch_uid);

    axios.post(Api.Url + "v1/phar/batch/remove", form).then((res) => {
      if (res["data"].errorcode == "200") {
        var s = batches.filter((bt) => bt.batch_uid != item.batch_uid);
        setBatches(s);
        message.success(res["data"].message || "Success");
      } else {
        message.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const exportToExcel = () => {
    var aoo = [];
    //for(var i = 0; i < 100; ++i) aoo.push({x:i, y:2 * i + Math.random()});
    viewData.map((item, i) => {
      aoo.push({
        CODE: item.code,
        ITEM_NAME: item.item_name,
        PRINT_NAME: item.print_name,
        GROUP_NAME: item.surname,
        CURRENT_STOCK: item.stock,
        UOM: item.umo,
        PURCASE_PRICE: item.purchase_rate,
        PURCASE_PRICE_WITH_TAX: item.purchase_with_tax == "1" ? "Yes" : "No",
        MRP: item.mrp,
        MRP_WITH_TAX: item.mrp_with_tax == "1" ? "Yes" : "No",
        SALE_PRICE: item.sales_rate,
        WHOLE_SALE_PRICE: item.whole_sales_rate,
        LOCATION: item.location,
      });
    });

    var ws = XLSX.utils.json_to_sheet(aoo);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Prodcut_List.xlsx");
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={10}>
          <div className="panel panel-form">
            <div className="panel-header">
              <Row>
                <Col md={6}>Item List</Col>

                <Col md={6}>
                  <div className="text-right">
                    <Button
                      type="button"
                      size="xs"
                      variant="primary"
                      onClick={() => {
                        loadData();
                        loadBatches();
                      }}
                    >
                      <i className="icofont-refresh"></i>
                    </Button>
                    &nbsp;
                    <Button
                      type="button"
                      size="xs"
                      variant="primary"
                      onClick={() => {
                        exportToExcel();
                      }}
                    >
                      <i className="icofont-file-excel me-1 pe-2 border-right"></i>
                      Export
                    </Button>
                    &nbsp;
                    <Button
                      type="button"
                      size="xs"
                      variant="primary"
                      onClick={() => setAddModal(true)}
                    >
                      <i className="icofont-plus pe-2 border-right"></i> Create
                      Item
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="panel-body">
              <Row>
                <Col md={4}>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="Search by Code,Name..."
                    onChange={(e) => handleSearch(e)}
                  />
                </Col>
              </Row>

              <div className="table-responsive mt-10 tableFixHead">
                <table className="table table-striped-lite ">
                  <thead>
                    <tr>
                      <th width="40">Batch</th>
                      <th>Description</th>
                      <th width="120">Packing</th>
                      <th>Group</th>
                      {/*<th>Item Name</th>
									<th width="90" >P.Rt</th>
									<th width="90"  >MRP</th>
									<th width="90"  >SL.Rt</th>
									<th width="90"  >SL.Rt/Piece</th>*/}
                      <th width="90">Stock</th>
                      <th width="60">UOM</th>
                      <th width="90">Retail Rate</th>
                      <th width="120">Wholesale Rate</th>
                      <th width="110">Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {viewData.map((item, i) => {
                      return (
                        <React.Fragment>
                          <tr key={i}>
                            <td>
                              {item.with_batch == "1" && (
                                <a
                                  className="btn btn-xs"
                                  onClick={() => onCollapse(item.id)}
                                >
                                  {collapseId == item.id ? (
                                    <i className="icofont-rounded-down"></i>
                                  ) : (
                                    <i className="icofont-rounded-right"></i>
                                  )}
                                </a>
                              )}
                            </td>
                            <td>
                              <span
                                className={
                                  item.stock < 1 ? "text-danger" : "text-dark"
                                }
                              >
                                {upperCase(item.print_name) ||
                                  upperCase(item.item_name)}
                              </span>
                            </td>
                            <td>
                              {item.packing == item.packing_count
                                ? item.packing_count
                                : `${parseInt(item.packing)} x ${parseInt(
                                    item.packing_count
                                  )}`}
                            </td>
                            <td>{item.surname}</td>
                            {/*<td>{item.code}</td>
									<td>
										<span className={item.stock<1 ? 'text-danger' : 'text-dark'}>
											{item.print_name}
										</span>
									</td>
									<td>
										<span className={item.stock<1 ? 'text-danger' : 'text-dark'}>
											{item.item_name}
										</span>
									</td>
									<td align="right" >{item.purchase_rate}</td>
									<td align="right" >{item.mrp}</td>
									<td align="right" >{item.sales_rate}</td>*/}
                            <td align="right">{item.stock}</td>
                            <td align="">{item.uom}</td>
                            <td align="right">{item.piece_sale_rate}</td>
                            <td align="right">{item.piece_whole_sale_rate}</td>
                            <td align="center">
                              <Button
                                type="button"
                                size="xs"
                                variant="default"
                                onClick={() => {
                                  setEditData(item);
                                  setEditModal(true);
                                }}
                              >
                                <i className="icofont-edit"></i>
                              </Button>
                              &nbsp;
                              <Button
                                type="button"
                                size="xs"
                                variant="default"
                                onClick={() => handleDeleteActionClick(item)}
                              >
                                <i className="icofont-trash"></i>
                              </Button>
                            </td>
                          </tr>
                          {item.with_batch == "1" && (
                            <tr
                              className={
                                collapseId == item.id ? "show" : "collapse"
                              }
                            >
                              <td></td>
                              <td></td>
                              <td></td>
                              <td colSpan="5">{batchList(item)}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {loader && (
                <center>
                  <Spinner animation="border" role="status" />
                </center>
              )}

              {/*<NoDataFound data={viewData} loader={loader} />*/}
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        show={addModal}
        onHide={() => setAddModal(false)}
        backdrop="static"
        className="cb__modal__search "
        size="md"
      >
        <Modal.Header closeButton>Add Item</Modal.Header>
        <Modal.Body>
          <AddItem afterFinish={() => loadData()} />
        </Modal.Body>
      </Modal>

      <Modal
        show={editModal}
        onHide={() => {
          setEditData([]);
          setEditModal(false);
        }}
        backdrop="static"
        className="cb__modal__search"
        size="md"
      >
        <Modal.Header closeButton>Edit Item</Modal.Header>
        <Modal.Body>
          <EditItem editData={editData} afterFinish={() => loadData()} />
        </Modal.Body>
      </Modal>

      <Modal
        show={editBatch}
        onHide={() => {
          setEditData([]);
          setEditBatch(false);
        }}
        backdrop="static"
        className="cb__modal__search"
        size="md"
      >
        <Modal.Header closeButton>Edit Batch</Modal.Header>
        <Modal.Body>
          <EditBatch
            editData={editData}
            afterFinish={() => {
              setEditData([]);
              loadBatches();
              setEditBatch(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default Products;
