import React, { useContext, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';

import IMG from './no-data-found.png';


const NoDataFound = (props)=>{	
	
	return(
		<>
			{(!props.loader)  && props.data && props.data.length<1 && (<div 
					style={{width: '100%', minHeight: 'calc(40vh)' }} 
					className="d-flex justify-content-center align-items-center" 
			>
						<img src={IMG} />
			</div>)}
				
		</>
	);
};
export default NoDataFound;