import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { Table } from 'antd';

import $ from 'jquery';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Spin, message, Select } from 'antd';
import{ LineLoader} from '../../../views/elements';
import PsContext from '../../../context/PsContext';

const { Option } = Select;
class Prescription extends Component{


static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			redirect: false,
			dataList: [],
			dataView: [],
			pid : '',
			patientid : '',
			paginationPageSize : 20,
			currentPage: 1,
		}

		this.handleSearch = this.handleSearch.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleTablePageChange = this.handleTablePageChange.bind(this);
	}
	
	showView(pid,e){
		this.setState({redirect : true, pid : pid});
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}

	handleTablePageChange(current){
		this.setState({currentPage: current});
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			var pid = parseInt($("#patientid").val().substring(3));
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('patient_id',pid);
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('hospital_id',getls('loggedCompany','id'));
			this.setState({dataList : [],dataView : []});
			axios.post(Api.Url+'v1/prescription/listbypatient', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						patientid : res['data'].data[0].patient_id,
						showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}


	handleSearch(e){
		try{
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var s = d.filter(function(obj){
			return( (upperCase(obj.suppliername).indexOf(v)>-1) ||
			(upperCase(obj.billno).indexOf(v)>-1) );
			});
			this.setState({dataView: s});
		}
		catch(er){console.log(er); }
	}

	render(){
		
		if(this.state.redirect){
			return <Redirect to={'/pharmacy/sales_entry/'+this.state.patientid+'/'+this.state.pid}  />
			
		}

		const columns = [
			{
				title: 'S.No',
				dataIndex: 'id',
				key: 'id',
				render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
				)
			},
			{
				title: 'Prescription Id',
				dataIndex: 'prescription_id',
				key: 'prescription_id',
			},
			{
				title: 'Date',
				dataIndex: 'billdate',
				key: 'billdate',
				render : (text,record)=>(
					<center>
						{momentDate(record.created_on,"DD-MM-YYYY")}
					</center>
				)
			},
			{
				title : 'Manage',
				dataIndex : 'id',
				key : 'id',
				width: '150px',
				render : (text,record)=>(
					<center>
						<Button type="button" size="xs" variant="success" onClick={this.showView.bind(this,record.prescription_id)}>
							<i className="icofont-eye"></i>
						</Button>
					</center>
				)
			}
		];


		return(
			<div className="px-15 py-15">
				<LineLoader loading={this.state.showLoader} />
				<Card style={{position:'sticky',top:'56px',zIndex:'1'}} >
					<Card.Body>
						<Row>
							<Col md={2} >
								<Form.Control
									type="text"
									size="sm"
									name="i__patient"
									id="patientid"
									placeholder="Search by Patient Id"
								/>
							</Col>
							<Col md={1} >
								<Button  
									type="button"
									size="sm"
									variant="primary"
									onClick={this.loadData}
								>
								View
								</Button>
							</Col>
							<Col md={3} >
								<div className="text-right" width="100%" >
									<Form.Control type="text" size="sm" name="i__search" placeholder="Search by Billno..." onChange={this.handleSearch} />
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>

				<div className="mt-10 ps-table" >
					<Table
						size="small"
						columns={columns}
						bordered
						footer={() => `Total ${Object.keys(this.state.dataView).length} Sales in list`}
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						rowClassName={ (record, index) => {  return record.status=='0' ? 'bg-danger-light' : null; }   }
						pagination={{
						pageSize: this.state.paginationPageSize,
						onShowSizeChange: this.handlePaginationSizeChange,
						position: ['bottomRight'],
						onChange: this.handleTablePageChange,
					}} />
				</div>
			</div>
		);
	}

}
export default Prescription