import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';

import PsContext from '../../../context/PsContext';

import TaxSettings from './TaxSettings';

class Settings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	render(){

		return(
			<React.Fragment>
				
				<TaxSettings />				
			
			</React.Fragment>
		);
	}
	
}
export default Settings;
