import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal, } from 'react-bootstrap';
import{ Radio, Spin, message, DatePicker, Checkbox } from 'antd';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, nameWithDotAndHypen, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, integerGst
} from '../../../utils';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import moment from 'moment';

import PsContext from '../../../context/PsContext';

class AddCustomer extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Update',
			showLoader: false,
			showAddModal: false,
			balanceType: 'receive',
			openingDate: '',
			openingBalance: '',
			openingDateMoment: '',
			showInPurchase: false,
		}
		
		this.handleBalanceChange = this.handleBalanceChange.bind(this);
		this.handleOpeningDateChange = this.handleOpeningDateChange.bind(this);
		this.handleShowPurchaseChange = this.handleShowPurchaseChange.bind(this);
		this.handleOpeningBalanceChange = this.handleOpeningBalanceChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleBalanceChange(e){
		var d = this.props.editData;
		d['type'] = e.target.value;
		this.setState({balanceType: e.target.value});
	}
	
	handleOpeningDateChange(date, dateString){
		var d = this.props.editData;
		d['as_date'] = dateString;
		this.setState({openingDateMoment: date, openingDate: dateString});
	}
	
	handleShowPurchaseChange(e){
		var d = this.props.editData;
		d['show_in_purchase'] = e.target.checked;
		this.setState({showInPurchase: e.target.checked});
	}
	
	handleOpeningBalanceChange(e){
		var d = this.props.editData;
		d['opening_balance'] = e.target.value;
		this.setState({openingBalance: e.target.value});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if($("#cb__txtCustomerMobile").val().length <10){
				message.error('Enter Valid Mobile No...' || 'Error');
				return;
			}
			/* if($("#cb__customerGstNo").val().length <15){
				message.error('Enter Valid GST No...' || 'Error');
				return;
			} */
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/phar/customer/update', $("#cb__frmUpdateCustomer").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Error');
					document.getElementById("cb__frmUpdateCustomer").reset();
					this.setState({
						balanceType: 'receive',
						openingDate: '',
						openingBalance: '',
						openingDateMoment: '',
						showInPurchase: false,
						showLoader: false,
					});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getData(field){
        var d = this.props.editData;
        return d[field];
    }

	render(){

		return(
			<>
				<Spin spinning={this.state.showLoader} >
				<form 
					action=""
					method="post"
					encType="multipart/form-data"
					id="cb__frmUpdateCustomer"
					onSubmit={this.handleFormSubmit} 
				>
				<div className="cb__modal__body" >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__id" value={this.getData('id')} />
					<input type="hidden" name="cb__openingBalance" value={Math.abs(this.getData('opening_balance'))} />
					<input type="hidden" name="cb__openingType" value={this.getData('type')} />
					<input type="hidden" name="cb__openingDate" value={this.getData('as_date')} />
					<input type="hidden" name="cb__showInPurchase" value={this.getData('show_in_purchase') ? '1' : '0'} />
					
					<Row>
						<Col md={7} >
							<label className="" >Party Name <span className="text-danger">*</span> </label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__txtCustomerName"
								id="cb__txtCustomerName"
								className="text-uppercase"
								required
								defaultValue={this.getData('name')}
								onKeyPress={nameWithDotAndHypen}
							/>
						</Col>
						<Col md={5} >
							<label className="" >Phone No <span className="text-danger">*</span></label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__txtCustomerMobile"
								id="cb__txtCustomerMobile"
								onKeyPress={integerIndMobile}
								required
								defaultValue={this.getData('mobile')}
							/>
						</Col>
					</Row>
					
					<Row className="mt-" >
						<Col md={7} >
							<label className="" >Place <span className="text-danger">*</span> </label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__txtCustomerPlace"
								id="cb__txtCustomerPlace"
								className="text-uppercase"
								required
								onKeyPress={nameWithDotAndHypen}
								defaultValue={this.getData('place')}
							/>
						</Col>
					</Row>

					<Row className="mt-15" >
						<Col md={7} >
							<label className="" >Billing Address</label>
							<Form.Control
								as="textarea"
								size="sm"
								rows="3"
								name="cb__txtBillingAddress"
								id="cb__txtBillingAddress"
								defaultValue={this.getData('address')}
							/>
						</Col>
						<Col md={5} >
							<label className="" >GST.No</label>
							<Form.Control
								type="text"
								size="sm"
								name="cb__customerGstNo"
								id="cb__customerGstNo"
								//onKeyPress={integerGst}
								defaultValue={this.getData('gstno')}
							/>
						</Col>
						<Col md={7} >
							<label className="" >Email Id</label>
							<Form.Control
								type="email"
								size="sm"
								name="cb__customerEmail"
								id="cb__customerEmail"
								defaultValue={this.getData('email')}
							/>
						</Col>
					</Row>
			
			
					<Row className="mt-15" >
						<Col md={7} >
							<label className="" >Opening Balance</label>
							<Form.Control
								type="number"
								step="any"
								size="sm"
								name="cb__customerOpeningAmount"
								id="cb__customerOpeningAmount"
								onChange={this.handleOpeningBalanceChange}
								defaultValue={Math.abs(this.getData('opening_balance'))}
							/>
						</Col>
						<Col md={5} >
							<label className="" >As Of Date</label>
							<DatePicker
								defaultValue={this.getData('as_date')!=null ? moment(this.getData('as_date')) : ''}
								onChange={this.handleOpeningDateChange} 
								style={{width: '100%'}} 
								disabledDate={(current) => {
								  let customDate = moment().add(1, 'days').format("YYYY-MM-DD");
								  return current > moment(customDate, "YYYY-MM-DD");
								}} 
							/>
						</Col>
						{(this.state.openingBalance || this.getData('opening_balance'))? <Col md={7} className="mt-15" >
							  <Radio.Group defaultValue={this.getData('type')} onChange={this.handleBalanceChange} >
							  <Radio value="receive" >To Receive</Radio>
							  <Radio value="pay" >To Pay</Radio>
							</Radio.Group>
						</Col> : null}
						<Col md={7} className="mt-15" >
							<Checkbox 
							onChange={this.handleShowPurchaseChange}	
							defaultChecked={this.getData('show_in_purchase') == 1 ? true : false}
							 >
								Show In Purchase Entry
							</Checkbox >
						</Col>
					</Row>
					
			</div>
			<div className="cb__modal__footer" >
				<div className="text-rights" >
					<Button
						type="submit"
						size="sm"
						variant="default"
					>
						<i className="icofont-check pe-2 border-right"></i> {this.state.btnText}
					</Button>
				</div>
			
				</div>
				
				</form>
				</Spin>
			</>
		);
	}
	
}
export default AddCustomer;