import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, upperCase, groupByMultiple, getAscSortOrder } from '../../../utils';
import { Api } from '../../../utils/Variables';

import NoDataFound from '../components/NoDataFound';

const StockDetails=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [reportDate, setReportDate] = useState(getToday());
	const [toDate, setToDate] = useState(getToday());
	const [loader, setLoader] = useState(false);
	const [cashInHand, setCashInHand] = useState(0);
	const [data, setData] = useState([]);
	const [viewData, setViewData] = useState([]);
	
	const [billId, setBillId] = useState(null);
	const [print, setPrint] = useState(false);
	const [view, setView] = useState(false);
	
	
	const loadReport=()=>{
		setData([]);
		setViewData([]);
		setLoader(true);
		var form = new FormData();
		form.append('api', context.state.loggedApi);
		form.append('from_date', reportDate);
		form.append('to_date', toDate);
		
		axios.post(Api.Url+'v1/phar/reports/stock_details', form).then(res=>{
			if(res['data'].errorcode=='200'){
				let items = [...res['data'].purchase, ...res['data'].sales, ...res['data'].sales_return, ...res['data'].purchase_return];
				setData(items);
				setViewData(items)
				setLoader(false);
			}
			else{
				message.error(res['data'].message || 'Error');
				setLoader(false);
			}
		});
		
	};
	
	const showStock=(item)=>{
		if(parseFloat(item.stock)<0){
			return <span className="text-danger">{item.stock}</span>;
		}
		else{
			return <span className="text-success">{item.stock}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {amt}</span>;
		}
		else{
			return <span className="text-danger">&#8377; {amt}</span>;
		}
	};
	
	useEffect(()=>{
		
		loadReport();
	},[]);
	
	const handleSearch=(e)=>{
		var v = upperCase(e.target.value);
		var s = data.filter(function(obj){
			return(upperCase(obj.item_name).indexOf(v)>-1);
		});
		setViewData(s);
	};
	
	const sumValues=(field)=>{
		
		var t = 0;
		viewData.map((item,i)=>{
			t = parseFloat(t)+parseFloat(item[field]);
		});
		return parseFloat(t).toFixed(2);
	}
		
	const sumOfQty=(item, field)=>{
		let total=0;
		
		item.map((it,i)=>{
			if(it && it[field])
				total = parseFloat(total)+parseFloat(it[field]);
		});
		return parseFloat(total).toFixed(2);
	};
	
	const tableData=(data)=>{
		let rv = [];
		if(data.length>0){
			data.map((item,i)=>{
				rv.push(<tr key={i} >
					<td>{item[0].billdate}</td>
					<td>{item[0].item_name}</td>
					<td align="right" >{item[0].prev_stock}</td>
					<td align="right"  >
					{item[0].packing_total>1 && 
						(<span style={{ float: 'left', fontSize: '11px'}}>Pack ({parseFloat(item[0].packing_total).toFixed(0)})</span>)
					}
						{(parseFloat(sumOfQty(item, 'qty_in')) * parseFloat(item[0].packing_total)).toFixed(2)}
					</td>
					<td align="right"  >{sumOfQty(item, 'qty_out')}</td>
					<td align="right"  >{item[item.length-1].running_stock}</td>
				</tr>);
			});
		}
		return rv;
	};
	
	const getData=(data)=>{
		var rv = [];
		// Group by billdate
		let gitems = groupByMultiple(data, function(obj){
			return [obj.billdate];
		});
		
		if(gitems.length>0){
			gitems.map((item,i)=>{
				//single date items group by product id
				let product_group = groupByMultiple(item, function(obj){
					return [obj.product];
				});
				// asc order by insert_ms value (insert micro second)
				let asc = product_group.sort(getAscSortOrder('insert_ms'));
				if(asc.length>0){
					rv.push(tableData(asc));
				}
			});
		}
		
		return rv;
	};
	
	return(
	<Row>
		<Col md={10} >
		
		<div className="panel panel-form">
		
			<div className="panel-header">
				<Row>
					<Col md={6} >
						Stock Detail
					</Col>
					<Col md={6} >
						<div className="text-right" >
							<Button
								type="button"
								size="xs"
								variant="primary"
								onClick={()=> loadReport() }
							>
								<i className="icofont-refresh pe-1 border-right"></i> Refresh
							</Button>
						</div>
					</Col>
				</Row>
			</div>
			
			<div className="panel-body" >
			<Row>	
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={reportDate}
							onChange={(e)=> setReportDate(e.target.value) }
						/>
					</Col>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={toDate}
							max={getToday()}
							onChange={(e)=> setToDate(e.target.value) }
						/>
					</Col>
					<Col md={1} >
						<Button
							type="button"
							size="sm"
							variant="default"
							block
							onClick={()=> loadReport() }
						>
							VIEW
						</Button>
					</Col>
					<Col md={3} ></Col>
					<Col md={4} >
						<Form.Control
							type="text"
							size="sm"
							placeholder="Search by Item Name"
							onChange={(e)=> handleSearch(e) }
						/>
					</Col>
					
				</Row>
			
				
					
					<div className="table-responsive mt-10 tableFixHead" >
						<table className="table table-striped-lite table-hover   ">
							<thead>
								<tr>
									<th width="110">Date</th>
									<th>Item Name</th>
									<th width="80"  >Begining.Qty</th>
									<th width="150"  >Qty In</th>
									<th width="100"  >Qty Out</th>
									<th width="100" >Closing Qty</th>
								</tr>
							</thead>
							<tbody>
								{viewData && viewData.length>0 && (getData(viewData))}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
					 </center>)}
					
					<NoDataFound data={viewData} loader={loader} />
				
			</div>
			
			<div className="panel-footer" >
			
			</div>
			
		</div>
		
		</Col>
		</Row>
	);
};
export default StockDetails;