import React, { useEffect, useState, useContext } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {Row, Col, Card, Carousel, Form, Button} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Spin, message } from 'antd';
import {Api} from '../../../../utils/Variables';

import PsContext from '../../../../context/PsContext';
import { integerIndMobile, lettersOnly } from '../../../../utils';

import HL from '../../../../assets/img/logo_150.png';
	
const NewAccount=(props)=>{
	
	const context = useContext(PsContext);
	
	const type = props.match.params.type;
	
	const history = useHistory();
	
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	
	const handleFormSubmit=(e)=>{
		e.preventDefault();
		 const form = e.currentTarget;
		  if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
			return;
		  }
		  
		  setLoading(true);
		  axios.post(`${Api.Url}v2/account/register`, $("#frm_user_register").serialize()).then(res=>{
			 if(res['data'].errorcode=='200'){
				message.success(res['data'].content || 'Success');
				document.getElementById("frm_user_register").reset();
				history.push('/');
			 }
			 else{
				message.error(res['data'].message || 'Success'); 
				setLoading(false);
			 }
		  });
	};
		
	return(
	<>
		<Row className="mt-20" >
			<Col md={{span:4, offset: 4}} >
					
				<Form 
					noValidate
					method="post"
					validated={validated}
					id="frm_user_register"
					onSubmit={handleFormSubmit}
				>
					<div className="panel panel-form">
						<div className="panel-header">
							Registration
						</div>
						<div className="panel-body" >
							<Spin spinning={loading} >
							<Row>
								<Col md={12}>
									<label>Company Type <span className="text-danger">*</span></label>
									<Form.Control
										as="select"
										size="sm"
										name="company_type"
									>
										<option value="retail"> Retail </option>
										<option value="bakery"> Bakery </option>
										<option value="pharmacy"> Pharmacy </option>
									</Form.Control>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12}>
									<label>Primary Email-Id <span className="text-danger">*</span></label>
									<Form.Control
										type="email"
										size="sm"
										name="company_email"
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12}>
									<label>Primary Mobile No<span className="text-danger">*</span></label>
									<Form.Control
										type="text"
										size="sm"
										name="company_mobile"
										onKeyPress={integerIndMobile}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12}>
									<label>Company Name <span className="text-danger">*</span></label>
									<Form.Control
										type="text"
										size="sm"
										name="company_name"
										onKeyPress={lettersOnly}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12}>
									<label>Company Address <span className="text-danger">*</span></label>
									<Form.Control
										as="textarea"
										rows="4"
										size="sm"
										name="company_address"
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6}>
									<label>City <span className="text-danger">*</span></label>
									<Form.Control
										type="text"
										size="sm"
										name="company_city"
										required
									/>
								</Col>
								<Col md={6}>
									<label>Country <span className="text-danger">*</span></label>
									<Form.Control
										type="text"
										size="sm"
										name="company_country"
										value="India"
										readonly
									/>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={6}>
									<Link to="/app">Back to Login</Link>
								</Col>
								<Col md={6}>
									<div className="text-right">
										<Button
											type="submit"
											variant="success"
										>
											Register
										</Button>
									</div>
								</Col>
							</Row>
							
							</Spin>
						</div>
					</div>
					
				</Form>
				
			</Col>
		</Row>
		
	</>
	);
	
};
export default withRouter(NewAccount);