import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';

class ProductMenu extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	render(){

		return(
			<ul className="nav nav-tabs">
			  <li className="nav-item">
				<a className="nav-link active" href="#">Product</a>
			  </li>
			  <li className="nav-item">
				<a className="nav-link" href="#">Prodcut Categories</a>
			  </li>
			</ul>
		);
	}
	
}
export default ProductMenu;
