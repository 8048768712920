import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { Table } from 'antd';

import $ from 'jquery';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Spin, message, Select } from 'antd';
import{ LineLoader} from '../../../views/elements';
import PsContext from '../../../context/PsContext';


const { Option } = Select;

class ExpireProductList extends Component{


static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showPrintModal: false,
			billid : '',
			fromDate : '',
			dataList: [],
			dataView: [],
			paginationPageSize : 20,
			currentPage: 1,
			expire: ''
			
			
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleMonthChange = this.handleMonthChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}
	handleMonthChange(value){
		this.setState({expire : value});
	}
	
  
  handleChange(e){
		try{
			if(this.state.expire == ''){
				message.error('Please select Month' || 'Error');
				return;
			}
			var expire = this.state.expire;
			var date = '';
			const current = new Date();
				if(expire == 'thismonth'){
					date = momentDate((new Date()),'YYYY-MM-DD');
				}
				if (expire=='premonth'){
					date = momentDate(current.setMonth(current.getMonth()-1),'YYYY-MM-DD');
				}
				if (expire=='nextmonth'){
					date = momentDate(current.setMonth(current.getMonth()+1),'YYYY-MM-DD');
				}
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('date',date);
			form.append('type', this.state.expire);
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('hospital_id',getls('loggedCompany','id'));
			this.setState({dataList : [],dataView : []});
			axios.post(Api.Url+'v1/phar/pharmacy/expiry-product', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
					dataList: res['data'].data,
					dataView: res['data'].data,
					showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
  
	
	handleSearch(e){
		try{
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var s = d.filter(function(obj){
			return( (upperCase(obj.item_name).indexOf(v)>-1) ||
			(upperCase(obj.batch).indexOf(v)>-1) || (upperCase(obj.code).indexOf(v)>-1) );
			});
			this.setState({dataView: s});
		}
		catch(er){console.log(er); }
	}

	
	render(){
		const columns = [
			{
				title: 'S.No',
				dataIndex: 'id',
				key: 'id',
				render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
				)
			},
			{
				title: 'Product Code',
				dataIndex: 'code',
				key: 'code',
			},
			{
				title: 'Product Name',
				dataIndex: 'item_name',
				key: 'item_name',
			},
			{
				title: 'Batch',
				dataIndex: 'batch',
				key: 'batch',
			},
			{
				title: 'Month & Year',
				dataIndex: 'expiry',
				key: 'expiry'
			}
			
			
		];
		return(
		
		<div className="panel panel-form" >
			<div className="panel-header">
				<Row>
					<Col md={12} >
						Expired Products
					</Col>
				</Row>
			</div>
			
			<div className="panel-body" >
			
				<Spin spinning={this.state.showLoader} >
					<Row>
						<Col md={2}>
							<Select
								showSearch
								style={{ width: 200 }}
								placeholder="Select a Month"
								optionFilterProp="children"
								onChange={this.handleMonthChange}
								value={this.state.expire}
								filterOption={(input, option) =>
								  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								>
								<Option value="thismonth" >This Month</Option>
								<Option value="premonth" >Previous Month</Option>
								<Option value="nextmonth" >Next Month</Option>
							</Select>
						</Col>
						<Col md={1} >
							<Button
								type="button"
								size="sm"
								variant="default"
								onClick={this.handleChange}
								className="btn-block"
							>
								<i className="icofont-search pe-2 border-right"></i> Search
							</Button>
						</Col>
						<Col md={3}>
							<div >
								<Form.Control type="text" size="sm" className="float-right" name="i__search" placeholder="Search by Code,Name,Batch..." onChange={this.handleSearch} />
							</div>
						</Col>
					</Row>
						<div className="mt-10 ps-table tableFixHead" >
							<Table
								size="small"
								columns={columns}
								bordered
								footer={() => `Total ${Object.keys(this.state.dataView).length} Products in list`}
								loading={this.state.showLoader}
								dataSource={this.state.dataView}
								rowClassName={ (record, index) => {  return record.status=='0' ? 'bg-danger-light' : null; }   }
								pagination={{
								pageSize: this.state.paginationPageSize,
								onShowSizeChange: this.handlePaginationSizeChange,
								position: ['bottomRight'],
								onChange: this.handleTablePageChange,
							}} />
						</div>
				</Spin>
				
			</div>
			
			
		</div>

		);
			
		
	}

}
export default ExpireProductList;