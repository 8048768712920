import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, numberToWords
} from '../../../utils';
import{ LineLoader} from '../../../views/elements';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Spin, message, Table} from 'antd';
import PsContext from '../../../context/PsContext';

class PurchasePrint extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddModal :false,
			dataList: [],
			dataView: [],
			paginationPageSize : 20,
			currentPage: 1,
			showEditModal: false,
			editData: [],
		}
		
		this.loadData = this.loadData.bind(this);

		this.printDocument = this.printDocument.bind(this);
	}
	
	printDocument() {
		var content = document.getElementById("invoice_container");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		this.props.afterFinish();
		pri.focus();
		pri.print();
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData()
	{
		try{
			
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('billid',this.props.billid);
			this.setState({dataList : [],dataView : []});
			let url = this.props.type && this.props.type=='return'
					? `${Api.Url}v1/phar/purchase_return/listbybillid` 
					: `${Api.Url}v1/phar/purchase/listbybillid`;
					
			axios.post(url, form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
					if(this.props.autoPrint)
						this.printDocument();
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getValue(fieldName) {
        try {

            var d = this.state.dataList;
            if (Object.keys(d).length > 0) {
                return d[0][fieldName];
            }
            else {
                return '';
            }
        }
        catch (error) {
            console.log(error);
            return '';
        }
    }

    getTrHeight() {
        try {
            var d = this.state.dataList;
            if (Object.keys(d).length > 0) {
                var h = (40 * (Object.keys(d).length + 1));
                return (500 - h) + 'px';
            }
            else {
                return '1000px';
            }
        }
        catch (error) {
            console.log(error);
            return '1000px';
        }
    }
	
	render(){
		
		let sno=1;
		
		return(
			<div className="px-15 py-15">
				<LineLoader loading={this.state.showLoader} />
				
				<div className="mt-10 ps-table" >
					<div id="invoice_container" style={{display : this.props.autoPrint ? 'none' : ''}}>

                            <div style={{ width: '750px', height: '1000px', background: '#fff', fontFamily: 'sans-serif' }} >
                                <div style={{ border: '0px solid black', height: '100%' }}>
                                    <table width="100%" align="center" >
                                        <tbody>
                                            <tr>
                                                <td width="60%">
                                                    <p style={{ fontSize: '17px', fontWeight: '600', margin: '0px' }} >{this.getValue('suppliername')}</p>
                                                    <p style={{ margin: '0px' }} >{this.getValue('address')}<br />
                                                        Phone: {this.getValue('mobile')}
                                                    </p>
                                                </td>
                                                <td valign="top" >
                                                    <p style={{ margin: '0px', background: '#4c4c69', color: '#fff', padding: '8px', fontSize: '16px', fontWeight: '600' }} >
                                                        PURCHASE BILL
													</p>
                                                    <table width="100%">
                                                        <tr><td>Bill No</td><td> : </td><td>{this.getValue('billno')}</td></tr>
                                                        <tr><td>Bill Date</td><td> : </td><td>{this.getValue('billdate')}</td></tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" align="center" style={{ border: '1px solid black', borderCollapse: 'collapse', fontSize: '12px' }} >
                                        <thead>
                                            <tr>
                                                <th style={{ borderCollapse: 'collapse', background: '#4c4c69', padding: '6px', color: '#fff', border: '1px solid black', textAlign: 'center' }} height="40" width="45" >
                                                    S.No
												</th>
                                                <th style={{ borderCollapse: 'collapse', background: '#4c4c69', padding: '6px', color: '#fff', border: '1px solid black', textAlign: 'center' }}  > Item Description
												</th>
                                                <th style={{ borderCollapse: 'collapse', background: '#4c4c69', padding: '6px', color: '#fff', border: '1px solid black', textAlign: 'right' }} width="60" >
                                                    Qty
												</th>
                                                <th style={{ borderCollapse: 'collapse', background: '#4c4c69', padding: '6px', color: '#fff', border: '1px solid black', textAlign: 'right' }} width="80" >
                                                    Rate
												</th>
                                                <th style={{ borderCollapse: 'collapse', background: '#4c4c69', padding: '6px', color: '#fff', border: '1px solid black', textAlign: 'right' }} width="60"  >
                                                    GST(%)
												</th>
                                                <th style={{ borderCollapse: 'collapse', background: '#4c4c69', padding: '6px', color: '#fff', border: '1px solid black', textAlign: 'right' }} width="100" >
                                                    Amount
												</th>
                                            </tr>
                                        </thead>
                                        <tbody valign="top" >
                                            {this.state.dataList.map((item, i) => {
                                                return <tr>
                                                    <td height="40px" style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'center' }} >
                                                        {sno++}
                                                    </td>
                                                    <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'left' }}>
                                                        {item.itemname}
                                                    </td>
                                                    <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }}>
                                                        {item.qty}
                                                    </td>
                                                    <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }}>
                                                        {item.purchase_rate}
                                                    </td>
                                                    <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }}>
                                                        {item.gst} %
													</td>
                                                    <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }}>
                                                        {item.total}
                                                    </td>
                                                </tr>;
                                            })
                                            }

                                            <tr style={{ height: this.getTrHeight() }} >
                                                <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} ></td>
                                                <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} ></td>
                                                <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} ></td>
                                                <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} ></td>
                                                <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} ></td>
                                                <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} ></td>
                                            </tr>
                                            <tr>
                                                <td colSpan="5" style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} >
                                                    <b>Sub Total</b><br />
													Tax GST <br />
													Discount <br />
                                                </td>
                                                <td style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'right' }} >
                                                    <b>{parseFloat(parseFloat(this.getValue('grand_total'))-parseFloat(this.getValue('gst_total'))).toFixed(2)}</b> <br />
													&#8377; {parseFloat(this.getValue('gst_total')).toFixed(2)}<br />
													&#8377; {parseFloat(this.getValue('discount')).toFixed(2)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" height="80" style={{ borderCollapse: 'collapse', padding: '6px', border: '1px solid black', textAlign: 'left' }} >
                                                    Amount (in words) <br />
                                                   <b>{numberToWords(parseFloat(this.getValue('net_amount')))} Rupees only.</b>
                                                </td>
                                                <td colSpan="3" style={{ borderCollapse: 'collapse', padding: '0px', border: '1px solid black', textAlign: 'right' }} >
                                                    <p style={{ margin: '0px', background: '#4c4c69', color: '#fff', fontSize: '14px', fontWeight: 'bold', padding: '3px 5px' }}>
                                                        Total &emsp; Rs. {this.getValue('net_amount')}
                                                    </p>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                    </div>
				</div>
				
			</div>
		);
	}
	
}
export default PurchasePrint;
