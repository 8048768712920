import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {Spin, message} from 'antd';

import PsContext from '../../../context/PsContext';

class LoginForm extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			role: null,
			varlidated: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
		  e.stopPropagation();
		  this.setState({validated: true});
		  return;
		}

		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v2/login', $("#cb__frmLogin").serialize()).then(res=>{
				if(res['data'].errorcode=='200' || res['data'].status==1 ){
					let api = res['data'].data.api;
					let master = res['data'].data.master;
					let user = res['data'].data.user;
					let acyears = [];
					
					axios.get(`${Api.Url}v2/current_accounting_year?api=${api}`).then(res=>{
						if(res['data'].errorcode=='200' || res['data'].status==1){
							this.context.updateLoggedAcyear(res['data'].data);
						}
						this.context.updateCompany(master);
						this.context.updatePharmacy(master);
						this.context.updateUserLogin(user, api);
					});
					
				}
				else{
					message.error(res['data'].content || res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
		
	render(){
		
		
		return(
			<div className="" >
			
						<Form
							noValidate						
							validated={this.state.validated}
							action=""
							method="post"
							encType="multipart/form-data" 
							id="cb__frmLogin"
							onSubmit={this.handleFormSubmit} 
						>
							
						<Spin spinning={this.state.showLoader} >
							
							<Row className="mt-10" >
								<Col md={12} >
									<label>Username</label>
									<Form.Control
										type="text"
										name="username"
										id="cb__txtUsername"
										size="md"
										placeholder="Username"
										autoFocus={true}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12} >
									<label>Password</label>
									<Form.Control
										type="password"
										name="password"
										id="cb__txtPassword"
										size="md"
										placeholder="Password"
										required
									/>
								</Col>
							</Row>
							
							
							<Row className="mt-15 mb-15" >
								<Col md={6} >
									{/*<Link to="/account/new">Create New Account</Link>*/}
								</Col>
								<Col md={6} >
									<div className="text-right" >
										<Button
											size="md"
											variant="primary"
											type="submit"
											className="font-weight-600"
										>
											<i className="icofont-check pe-2 me-2 border-right"></i> Continue
										</Button>										
									</div>
								</Col>
							</Row>
							
					</Spin>
							
				</Form>
				
			</div>
		);
	}
	
}
export default LoginForm;
