const COOKIE_KEY = "yb21";

const Api = {
  Url: "https://books.aramitproducts.com/api/",
  ImageUrl: "https://books.aramitproducts.com/api/public/uploads", // Dont put slash in last
  SiteUrl: "https://books.aramitproducts.com/",
  version: "v1/",
};
/* const Api = {
  Url: 'http://172.17.23.7/~jrnlsco/pharmacy/api/',
  ImageUrl: 'http://172.17.23.7/~jrnlsco/pharmacyapi/public/uploads', // Dont put slash in last
  SiteUrl: 'http://172.17.23.7/~jrnlsco/',
  version: 'v1/',
}; */

/* const Api = {
  Url: 'http://localhost/yesbill.in/api/',
  ImageUrl: 'http://localhost/yesbill.in/api/public/uploads', // Dont put slash in last
  SiteUrl: 'http://localhost/yesbill.in/',
  version: 'v1/',
};
  */

const Var = {
  Title: "Billing",
  Site: "",
  Phone: "9487900120",
  Email: "",
  Address: "",
  City: "Tirunelveli",
  Pincode: " 627002",
  RegularCourseFeeForScSt: 0,
  RegularCourseFeeForOthers: 50,
  SelfCourseFeeForScSt: 50,
  SelfCourseFeeForOthers: 50,
  EnableCashPayment: true,
  EnableOnlinePayment: true,
};

const copyRight = {
  version: "1.0",
  year: "2021",
  mobile: "+91 94879 00120",
  email: "help@peacesoft.in",
};

const Units = [
  { value: "nos", name: "NUMBERS" },
  { value: "pcs", name: "PIECES" },
  { value: "gm", name: "GRAMMES" },
  { value: "kg", name: "KILOGRAMS" },
  { value: "ltr", name: "LITRE" },
  { value: "ml", name: "MILILITRE" },
  { value: "mtr", name: "METERS" },
  { value: "sqf", name: "SQUARE FEET" },
  { value: "sqm", name: "SQUARE METERS" },
  { value: "bag", name: "BAGS" },
  { value: "btl", name: "BOTTLES" },
  { value: "box", name: "BOX" },
  { value: "bundle", name: "BUNDLES" },
  { value: "cans", name: "CANS" },
  { value: "cartons", name: "CARTONS" },
  { value: "packs", name: "PACKS" },
  { value: "rol", name: "ROLLS" },
  { value: "tbs", name: "TABLETS" },
];

export { COOKIE_KEY, Api, Var, copyRight, Units };
