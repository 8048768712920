import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, typeName, upperCase, startDateOfMonth, endDateOfMonth, capitalizeFirst } from '../../../utils';
import { Api } from '../../../utils/Variables';

import ChequeTransfer from './chequeTransfer';

const Expenses=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [fromDate, setFromDate] = useState(startDateOfMonth());
	const [toDate, setToDate] = useState(endDateOfMonth());
	
	const [loader, setLoader] = useState(false);
	const [cashInHand, setCashInHand] = useState(0);
	const [data, setData] = useState([]);
	
	const [item, setItem] = useState([]);
	const [view, setView] = useState(false);
	const [add, setAdd] = useState(false);
	
	const loadReport=()=>{
		setData([]);
		setItem([]);
		setAdd(false);
		setLoader(true);
		
		var form = new FormData();
		form.append('api', context.state.loggedApi);
		form.append('from_date',fromDate);
		form.append('to_date', toDate);
		//form.append('report',"1");
		
		//form.append('supplier', '1');
		//form.append('i__accountingYear',context.state.loggedAcyear.id);
		//form.append('hospital_id',context.state.loggedCompany.id);
		
		axios.post(Api.Url+'v1/phar/cheques/all', form).then(res=>{
			if(res['data'].errorcode == '200'){
				setData(res['data'].data);
				//setCashInHand(res['data'].cash_in_hand);
				setLoader(false);
			}
			else{
				//message.error(res['data'].message || 'Error');
				setLoader(false);
			}
		});
		
	};
	
	const currentAmount=(item)=>{
		if(parseFloat(item.credit_amount)>0){
			return <span className="text-danger">{item.credit_amount}</span>;
		}
		else{
			return <span className="text-success">{item.debit_amount}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {Math.abs(amt).toFixed(2)} (Cr)</span>;
		}
		else{
			return <span className="text-danger">&#8377; {Math.abs(amt).toFixed(2)} (Dr)</span>;
		}
	};
	
	useEffect(()=>{
		
		loadReport();
	},[]);
	
	return(
		<div className="container-fluid">
		
		{/*<div className="top-sticky-header">
				<Row>
					<Col md={8} >
						<h1 className="page-header">Expenses</h1>
					</Col>
					<Col md={4} >
						<div className="text-right" >
							
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={fromDate}
							max={endDateOfMonth()}
							onChange={(e)=> setFromDate(e.target.value) }
						/>
					</Col>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={toDate}
							max={endDateOfMonth()}
							onChange={(e)=> setToDate(e.target.value) }
						/>
					</Col>
					<Col md={1} >
						<Button
							type="button"
							size="sm"
							variant="default"
							block
							onClick={()=> loadReport() }
						>
							VIEW
						</Button>
					</Col>
				</Row>
		</div>*/}
			
			{/*<div className="panel mt-10">
				
					
					<div className="table-responsive" >
						<table className="table table-striped-lite table-hover ">
							<thead>
								<tr>
									<th width="60" >S.NO</th>									
									<th>TYPE</th>
									<th>PARTY NAME</th>
									<th>REF.NO</th>
									<th>DATE</th>
									<th width="120"  >TOTAL</th>
									<th>STATUS</th>
									<th width="120" >ACTION</th>
								</tr>
							</thead>
							<tbody>
							{data && data.length>0 && (data.map((item,i)=> {
								return <tr key={i} >
									<td>{i+1}</td>
									<td>{typeName(item.voucher_type)}</td>
									<td>{item.to_name}</td>
									<td>{item.ref_no}</td>
									<td>{momentDate(item.payment_date, 'DD/MM/YYYY')}</td>
									<td align="right">{item.amount}</td>
									<td align="">{capitalizeFirst(item.cheque_status)}</td>
									<td align="center">
									{item.voucher_type=='payment_out' && item.cheque_status=='open' && (<a 	className="text-primary" onClick={()=>{
										setItem(item);
										setAdd(true);
									}} >
										WITHDRAW
									</a>)}
									
									{item.voucher_type=='payment_in' && item.cheque_status=='open' && (<a 	className="text-primary" onClick={()=>{
										setItem(item);
										setAdd(true);
									}} >
										DEPOSIT
									</a>)}
									
									</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
					 </center>)}
					
					
				
			</div>
			*/}
			
			<div className="mail-box">

				<div className="mail-box-sidebar">

					<div className="mail-box-header">
						<b>Welcome</b>
					</div>
					
					<div className="mail-box-wrapper">
						<h4 className="title m-t-0">Labels</h4>
						<ul className="mail-box-menu">
						<li><a href="javascript:;"><span className="email-label bg-gradient-red"></span> Red</a></li>
						<li><a href="javascript:;"><span className="email-label bg-gradient-orange"></span> License Codes</a></li>
						<li><a href="javascript:;"><span className="email-label bg-gradient-yellow"></span> Yellow</a></li>
						<li><a href="javascript:;"><span className="email-label bg-gradient-green"></span> Finance</a></li>
						<li><a href="javascript:;"><span className="email-label bg-gradient-blue"></span> Homework</a></li>
						<li><a href="javascript:;"><span className="email-label bg-gradient-purple"></span> Research</a></li>
						<li><a href="javascript:;"><span className="email-label bg-gradient-silver"></span> Games</a></li>
						</ul>
					</div>

				</div>
				<div className="mail-box-content">
					<div className="mail-box-toolbar">
						
						<div className="pull-left">
							<b>Expenses</b>
						</div>
						<div className="pull-right hidden-xs">
						<a href="#" className="btn btn-default btn-sm"><i className="ti-archive"></i></a>
						<a href="#" className="btn btn-default btn-sm"><i className="ti-trash"></i></a>
						<a href="#" className="btn btn-default btn-sm"><i className="ti-email"></i></a>
						<a href="#" className="btn btn-default btn-sm"><i className="ti-star"></i></a>
						<a href="#" className="btn btn-default btn-sm"><i className="ti-tag"></i></a>
						<a href="#" className="btn btn-default btn-sm"><i className="ti-layout"></i></a>
						</div>

					</div>
					
					<div className="mail-box-container">
					
					</div>
				</div>
			</div>
			
			
			<Modal show={add} onHide={()=> setAdd(false) } className="cb__modal" size="md" backdrop="static" >
				<Modal.Header closeButton >
					Transfer Cheque
				</Modal.Header>
				<Modal.Body>
					<ChequeTransfer afterFinish={()=> loadReport() } data={item} />
				</Modal.Body>
			</Modal>
			
		</div>
	);
};
export default Expenses;