import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Button, Spinner, Modal } from "react-bootstrap";
import axios from "axios";
import { message } from "antd";
import XLSX from "xlsx";

import PsContext from "../../../context/PsContext";
import {
  momentDate,
  upperCase,
  typeName,
  startDateOfMonth,
  endDateOfMonth,
} from "../../../utils";
import { Api } from "../../../utils/Variables";

import NoDataFound from "../components/NoDataFound";
import CashAdjust from "./cashAdjust";

const CashInHand = () => {
  const context = useContext(PsContext);

  const getToday = () => momentDate(new Date(), "YYYY-MM-DD");

  const [reportDate, setReportDate] = useState(startDateOfMonth());
  const [toDate, setToDate] = useState(endDateOfMonth());
  const [loader, setLoader] = useState(false);

  const [showCashAdjust, setCashAdjust] = useState(false);
  const [cashInHand, setCashInHand] = useState(0);
  const [data, setData] = useState([]);

  const loadReport = () => {
    setData([]);
    setLoader(true);

    var form = new FormData();
    form.append("api", context.state.loggedApi);
    form.append("from_date", reportDate);
    form.append("to_date", toDate);

    form.append("supplier", "1");
    form.append("i__accountingYear", context.state.loggedAcyear.id);
    form.append("hospital_id", context.state.loggedCompany.id);

    axios.post(Api.Url + "v1/phar/purchase/party-balance", form).then((res) => {
      if (res["data"].status == "1") {
        setData(res["data"].data);
        setCashInHand(res["data"].cash_in_hand);
        setLoader(false);
      } else {
        setCashInHand(res["data"].cash_in_hand);
        //message.error(res['data'].message || 'Error');
        setLoader(false);
      }
    });
  };

  const currentAmount = (item) => {
    if (parseFloat(item.debit_amount) > 0) {
      return <span className="text-danger">{item.debit_amount}</span>;
    } else {
      return <span className="text-success">{item.credit_amount}</span>;
    }
  };

  const showCashInHand = (amt) => {
    if (parseFloat(amt) > 0) {
      return <span className="text-success">&#8377; {amt}</span>;
    } else {
      return <span className="text-danger">&#8377; {amt}</span>;
    }
  };

  useEffect(() => {
    loadReport();
  }, []);

  const exportToExcel = () => {
    var aoo = [];
    //for(var i = 0; i < 100; ++i) aoo.push({x:i, y:2 * i + Math.random()});
    data.map((item, i) => {
      aoo.push({
        TYPE: typeName(item.voucher_type),
        TXN_NO: item.invoice_id,
        NAME: item.to_name || item.suppliername,
        DESCRIPTION: item.description,
        DATE: momentDate(item.voucher_date, "DD/MM/YYYY"),
        AMOUNT: item.debit_amount > 0 ? item.debit_amount : item.credit_amount,
      });
    });
    aoo.push({
      TYPE: "",
      TXN_NO: "",
      NAME: "",
      DESCRIPTION: "CASH IN HAND",
      DATE: "",
      AMOUNT: cashInHand,
    });

    var ws = XLSX.utils.json_to_sheet(aoo);
    var wscols = [
      { wch: 20 },
      { wch: 13 },
      { wch: 30 },
      { wch: 30 },
      { wch: 12 },
      { wch: 10 },
    ];

    ws["!cols"] = wscols;
    ws["F2"] = { t: "n", z: "0.00" };

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    var today = momentDate(reportDate, "DD_MM_YYYY");

    XLSX.writeFile(wb, "CASH_IN_HAND_" + today + ".xlsx");
  };

  return (
    <Row>
      <Col md={10}>
        <div className="panel panel-form">
          <div className="panel-header">
            <Row>
              <Col md={8}>Cash In Hand</Col>
              <Col md={4}>
                <div className="text-right">
                  <Button
                    type="button"
                    size="xs"
                    variant="primary"
                    onClick={() => setCashAdjust(true)}
                  >
                    <i className="icofont-align-center pe-1 border-right"></i>{" "}
                    Adjust Cash
                  </Button>
                </div>
              </Col>
            </Row>
          </div>

          <div className="panel-body">
            <Row>
              <Col md={2}>
                <Form.Control
                  type="date"
                  size="sm"
                  value={reportDate}
                  max={getToday()}
                  onChange={(e) => setReportDate(e.target.value)}
                />
              </Col>
              <Col md={2}>
                <Form.Control
                  type="date"
                  size="sm"
                  value={toDate}
                  max={endDateOfMonth()}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Col>
              <Col md={1}>
                <Button
                  type="button"
                  size="sm"
                  variant="default"
                  block
                  onClick={() => loadReport()}
                >
                  VIEW
                </Button>
              </Col>
              <Col md={7}>
                <div className="text-right">
                  <Button
                    type="button"
                    size="sm"
                    variant="default"
                    onClick={(e) => exportToExcel()}
                  >
                    <i className="icofont-file-excel pe-1 me-1 border-right"></i>{" "}
                    Export to Excel
                  </Button>
                </div>
              </Col>
            </Row>

            <div className="table-responsive tableFixHead mt-10">
              <table className="table table-striped-lite table-hover ">
                <thead>
                  <tr>
                    <th width="150">TYPE</th>
                    <th width="130">TXN NO</th>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                    <th width="110">DATE</th>
                    <th width="100">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.length > 0 &&
                    data.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{typeName(item.voucher_type)}</td>
                          <td>{item.invoice_id}</td>
                          <td>{item.to_name || item.suppliername}</td>
                          <td>{item.description}</td>
                          <td>{momentDate(item.voucher_date, "DD/MM/YYYY")}</td>
                          <td align="right">{currentAmount(item)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {loader && (
              <center>
                <Spinner animation="border" role="status" />
              </center>
            )}

            <NoDataFound data={data} loader={loader} />
          </div>
          <div className="panel-footer">
            <Row>
              <Col>
                <div className="text-right font-weight-600 f-s-20">
                  Blanace : {showCashInHand(cashInHand)}
                </div>
              </Col>
            </Row>
          </div>

          <Modal
            show={showCashAdjust}
            onHide={() => setCashAdjust(false)}
            className="cb__modal__search"
            size="sm"
            backdrop="static"
          >
            <Modal.Header closeButton>Adjust Cash</Modal.Header>
            <Modal.Body>
              <CashAdjust afterFinish={() => loadReport()} />
            </Modal.Body>
          </Modal>
        </div>
      </Col>
    </Row>
  );
};
export default CashInHand;
