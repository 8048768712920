import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import { capitalizeFirst } from '../../../utils';

import Batches from './batches';
import UomList from './uomList';

const ItemName=(props)=>{
	
	const context = useContext(PsContext);
	
	const { item, currentCategory } = props;
	
	const [batchModal, setBatchModal] = useState(false);
	const [uomModal, setUomModal] = useState(false);
	
	const itemClick=()=>{
		
		// To validate stock is grater than zero
		// and to verify this product is allowed in zero billing
		if(parseFloat(item.stock)<=0 && parseFloat(item.zero_stock)==0){
			toast.error('Product in zero stock');
			return;
		}
		
		// to check the selectd item with batch wise billing
		// if the item with batch wise stock then open batch modal to select the batch to bill
		if(parseFloat(item.with_batch)==1){
			setBatchModal(true);
			return;
		}
		
		if(item.uom=='kg' || item.uom=='ltr'){
			setUomModal(true);
			return;
		}
		
		if(props.onClick)
			props.onClick(item);
	};
	
	return(
	<>
		<Card className="rounded-0 shadow-hover cursor-pointer bg-light mb-10" onClick={e=>itemClick()} >
			<Card.Body className="px-0 py-0 rounded-0 text-center  font-15" >
				<div className="py-10 px-10" style={{height: '70px'}} >
					{capitalizeFirst(item.print_name || item.item_name)}
				</div>
				<Button className="btn-block rounded-0 font-weight-600" variant="dark" >
					Rs. {item.piece_sale_rate}
				</Button>
			</Card.Body>
		</Card>
		
		
		<Modal 
			show={batchModal}
			onHide={e=>setBatchModal(false)}
			backdrop="static"
		>
			<Modal.Body>
				<Batches 
					onClose={e=> setBatchModal(false)}
					{...props} 
				/>
			</Modal.Body>
		</Modal>
		
		<Modal 
			show={uomModal}
			onHide={e=>setUomModal(false)}
			backdrop="static"
		>
			<Modal.Body>
				<UomList
					onClose={e=> setUomModal(false)}
					{...props}
				/>
			</Modal.Body>
		</Modal>
		
	</>
	);
};
export default ItemName;