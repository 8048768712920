import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import PsContext from '../../../context/PsContext';
import { Api } from '../../../utils/Variables';

const Batches=(props)=>{
	
	const context = useContext(PsContext);
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	
	const { item } = props;
		
	const cancelClick=()=>{
		if(props.onClose)
			props.onClose();
	}
	
	const loadBatches=()=>{
		setLoader(true);
		var form = new FormData();
		form.append('api', context.state.api);
		form.append('product_id', item.id);
		axios.post(Api.Url+'v2/item_wise_batch', form).then(res=>{
			if(res['data'].errorcode=='200'){
				setData(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadBatches();
	},[]);
	
	const itemClick=(bt)=>{
		
		const puid = 'p_'+item.id+'_'+bt.id;
		const product = {
			puid: puid,
			id: item.id,
			item_name: item.item_name,
			print_name: item.print_name,
			purchase_rate: item.purchase_rate,
			sales_rate: item.sales_rate,
			piece_sale_rate: item.piece_sale_rate,
			mrp: item.mrp,
			sales_rate: item.sales_rate,
			tax: item.tax,
			tax_value: item.tax_value,
			//gst: parseFloat(gst).toFixed(2),
			mrp_with_tax: item.mrp_with_tax,
			with_batch: item.with_batch,
			qty: 1,
			uom: item.uom,
			_id: uuidv4(),
			//uom_name: parseFloat(gm) * parseFloat(1000) + (item.uom=='kg' ? ' gm' : ' ml'),
			//uom_qty: 1,
			//uom_amount: (item.piece_sale_rate  * parseFloat(gm)),
		};
		
		if(props.onClick)
			props.onClick(product, bt);
		if(props.onClose)
			props.onClose();
	}
	
	return(
		<>
			<h4 className="font-weight-600 border-bottom mb-10 pb-2" >{item.item_name}</h4>
			<div style={styles.bodyStyle} >
				
				{loader && (<div className="text-center mt-80"> <Spinner animation="border" /> </div>)}
				
				{!loader && data && data.length<1 && (<div className="text-center mt-80 font-30 font-weight-100 text-light" >Sorry no batch availabel.!</div>)}
				
				<Row>
				{!loader && data && data.length>0 && (data.map((item,i)=>{
					return <Col md={4} key={i} >
						<Card className="rounded-0 shadow-hover cursor-pointer bg-light mb-10" onClick={e=>itemClick(item)} >
							<Card.Body className="px-0 py-0 rounded-0 text-center  font-15" >
								<div className="py-10 px-10"  >
									{item.batch} <br />
									<span className="font-13 mt-10">Exp @ {item.expiry}</span>
								</div>
								<Button className="btn-block rounded-0 font-weight-600" variant="dark" >
									Rs. {item.piece_sale_rate}
								</Button>
							</Card.Body>
						</Card>
					</Col>;
				}))}
				</Row>
				
			</div>
			<Row>
				<Col md={8} ></Col>
				<Col md={4} >
					<div className="text-right" >
						<Button 
							type="button"
							size="md"
							variant="danger"
							onClick={e=>cancelClick()}
						>
							<i className="icofont-close me-2"></i> CANCEL
						</Button>	
					</div>
				</Col>
			</Row>
		</>
	);
};
export default Batches;

const styles = {
	bodyStyle: {
		height: 'calc(70vh)',
		overflowY: 'auto',
		overflowX: 'hidden',
	}
}