import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, numberToWords
} from '../../../utils';
import{ LineLoader} from '../../../views/elements';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Spin, message, Table} from 'antd';
import PsContext from '../../../context/PsContext';

class PurchaseView extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataView: [],
			supplier : '',
			paginationPageSize : 20,
			currentPage: 1,
			viewData: this.props.viewData
		}
		this.loadData = this.loadData.bind(this);
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}

	handleTablePageChange(current){
		this.setState({currentPage: current});
	}
	
	getValue(fieldName) {
        try {

            var d = this.state.dataList;
            if (Object.keys(d).length > 0) {
                return d[0][fieldName];
            }
            else {
                return '';
            }
        }
        catch (error) {
            console.log(error);
            return '';
        }
    }
	
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData()
	{
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('billid',this.props.billid);
			this.setState({dataList : [],dataView : []});
			axios.post(Api.Url+'v1/phar/purchase/listbybillid', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}

	render(){
		
		const columns = [
			{
				title: 'S.No',
				dataIndex: 'id',
				key: 'id',
				render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
				)
			},
			
			{
				title: 'Item Name',
				dataIndex: 'itemname',
				key: 'itemname',
			},
			
			{
				title: 'Total',
				dataIndex: 'total',
				key: 'total',
			},
			
			{
				title: 'Amount',
				dataIndex: 'net_amount',
				key: 'net_amount',
			},
		];
		
		return(
		
			<div className="px-15 py-15">
				<LineLoader loading={this.state.showLoader} />
				<div className="mt-10" >
					<div>
						<table width="100%" align="center" >
							<tbody>
								<tr>
									<td >
									<table width="60%" align="left">
										<tr><td><b>Supplier Name</b> </td>  :  <td>{this.getValue('suppliername')}</td></tr>
										<tr><td><b>Address</b></td>  :  <td>{this.getValue('address')}</td></tr>
										<tr><td><b>Phone</b> </td>  :  <td>{this.getValue('mobile')}</td></tr>
										   
										</table>
									</td>
									<td >
										<table width="60%" align="right">
											<tr><td><b>Bill No</b></td>  : <td>{this.getValue('billno')}</td> </tr>
											<tr><td><b>Bill Date</b></td> : <td>{this.getValue('billdate')}</td></tr>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Table pagination= {false}
						size="small"
						columns={columns}
						bordered
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						rowClassName={ (record, index) => {  return record.status=='0' ? 'bg-danger-light' : null; }   }
					/>
                </div>
            </div>
		);	
	}
	
}
export default PurchaseView;
