import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Spin, message } from 'antd';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../../utils';
import {Api}  from '../../../../utils/Variables';
import axios from 'axios';
import {LineLoader} from '../../../../views/elements';

import PsContext from '../../../../context/PsContext';

class AddBrand extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/phar/brand/save', $("#frm_pharBrand").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					document.getElementById("frm_pharBrand").reset();
					this.setState({showLoader: false});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Un expected error');
			this.setState({showLoader: false});
		}
	}
	
	render(){

		return(
			<React.Fragment>
				<LineLoader loading={this.state.showLoader} />
				<Spin spinning={this.state.showLoader} >
					<form action="" method="post" id="frm_pharBrand" onSubmit={this.handleFormSubmit} >
					
					<div className="cb__modal__body" >
					
						<input type="hidden" name="api" value={this.context.state.api} />
					
					
					<Row>
						<Col md={5} >
							<label className=""  >Brand Name <span className="text-danger">*</span></label>
						</Col>
						<Col md={7} >
							<Form.Control
								type="text"
								name="i__name"
								className="no-arrow" 
								size="sm"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={5} >
							<label className="" >Remarks </label>
						</Col>
						<Col md={7} >
							<Form.Control
								as="textarea"
								name="i__remarks"
								size="sm"
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={5} >
							
						</Col>
						<Col md={7} >
							<label className="" >
								<input type="checkbox" value="1" name="i__active" /> Active Status
							</label>
						</Col>
					</Row>
					
					
					</div>
					<div className="cb__modal__footer" >
						<Row>
							<Col md={5} >
								
							</Col>
							<Col md={7} >
								<div className="text-right" >
									<Button
										type="submit"
										variant="default"
										size="sm"
									>
										<i className="icofont-check pe-2 border-right"></i> Save Brand
									</Button>
								</div>
							</Col>
						</Row>
					</div>
					
					</form>
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default AddBrand;
