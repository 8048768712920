import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Row, Col, Form, Button, Spinner, Modal, Card } from 'react-bootstrap';
import { Spin, message } from 'antd';
import axios from 'axios';
import toast from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import { momentDate, typeName, upperCase, startDateOfMonth, endDateOfMonth, capitalizeFirst, groupByMultiple } from '../../../utils';
import { Api } from '../../../utils/Variables';

import CategoryName from './categoryName';
import ItemName from './itemName';
import BilledItemName from './billedItemName';

const PosSales=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [fromDate, setFromDate] = useState(startDateOfMonth());
	const [toDate, setToDate] = useState(endDateOfMonth());
	
	const [loader, setLoader] = useState(false);
	
	const [products, setProducts] = useState([]);
	const [catWiseProduct, setCatWiseProduct] = useState([]);
	const [grouped, setGrouped] = useState([]);
	const [batches, setBathces] = useState([]);
	const [billNo, setBillNo] = useState(null);
	
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState([]);
	
	const loadProducts=()=>{
		setLoader(true);
		axios.get(Api.Url+'v1/phar/item/getactive?api='+context.state.api).then(res=>{
			if(res['data'].status=='1'){
				setProducts(res['data'].data);
				setCatWiseProduct(res['data'].data);
				var d = groupByMultiple(res['data'].data, function(obj){
					return [obj.item_group];
				});
				setGrouped(d);
			}
			setLoader(false);
		});
	};
	
	const loadBatches=()=>{
		setLoader(true);
		axios.get(Api.Url+'v1/phar/batch/listall?api='+context.state.api).then(res=>{
			if(res['data'].status=='1'){
				setBathces(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadBillNo=()=>{
		setLoader(true);
		var form = new FormData();
			form.append('api', context.state.api);
			form.append('hospital_id',context.state.loggedCompany.id);
			form.append('i__accountingYear',context.state.loggedAcyear.id);
			
			let url = `${Api.Url}v1/phar/sales/getbillno`;
		axios.post(url, form).then(res=>{
			if(res['data'].status=='1'){
				setBillNo(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	useEffect(()=>{
		
		loadProducts();
		loadBatches();
		loadBillNo();
	},[]);
	
	
	const getProduct=(item)=>{
		return <Col md={4} >
			
		</Col>;
	};
	
	const categoryClick=(catItem)=>{		
		var s = null;
		var d = products;
		if(catItem){
			s = catItem.item_group;
			d = d.filter(item=>item.item_group==catItem.item_group);
		}
		setSelectedCategory(s);
		setCatWiseProduct(d);
	};
	
	const itemClick=(item, batch=false)=>{
		
		if(Object.keys(item).length>0){
			
			/*product.uom_name = parseFloat(gm) * parseFloat(1000) + (item.uom=='kg' ? ' gm' : ' ml');
		product.uom_qty = 1;
		product.qty = gm;
		product.uom_amount = (item.piece_sale_rate  * parseFloat(gm));
		product.item_total = (item.piece_sale_rate  * parseFloat(gm)) * 1;*/
		
			var gst = item.piece_sale_rate*item.tax_value/100;
			var total = parseFloat(item.piece_sale_rate)+parseFloat(gst);
			if(item.mrp_with_tax=='1'){
				total = parseFloat(item.piece_sale_rate);
			}
			
			if(batch)
			{
				gst = batch.piece_sale_rate* item.tax_value/100;
				total = parseFloat(batch.piece_sale_rate)+parseFloat(gst);
					if(item.mrp_with_tax=='1'){
					total = parseFloat(batch.piece_sale_rate);
				}
			}
			
			if(item.uom_name && item.uom_qty)
			{
				total = parseFloat(item.uom_amount)+parseFloat(gst);
					if(item.mrp_with_tax=='1'){
						total = parseFloat(item.uom_amount);
				}
			}
			/* var s = selectedProduct;
			var itemIndex = s.filter(obj => obj.puid==item.puid);
			
			if(itemIndex && itemIndex.length>0)
			{
				var exItem = itemIndex[0];
				var qty = exItem.qty
				s[itemIndex]['qty'] = (parseFloat(qty)+1).toFixed(0);
				s[itemIndex]['gst'] = (parseFloat(gst) * (parseFloat(qty)+1)).toFixed(2);
				s[itemIndex]['total'] = (parseFloat(total) * (parseFloat(qty)+1)).toFixed(2);
			}
			else
			{ */
				//let bt = item.uom_name ? item.uom_name : ' ';
				let bt = batch && batch.batch ? '@ '+batch.batch+' - '+batch.expiry : ' ';
				var m = {
					id: item.id,
					puid: item.puid,
					add_notes: 0,
					notes: '',
					batch: (batch ?  batch.id : ''),
					batch_name: (batch ? batch.batch : ''),
					expiry: (batch ? batch.expiry : ''),
					name: item.item_name,
					print_name: item.print_name,
					purchase_rate: (batch ? batch.purchase_rate : item.purchase_rate),
					amount: (batch ? batch.sales_rate : item.sales_rate),
					piece_sale_rate: (batch ? batch.piece_sale_rate : item.piece_sale_rate),
					mrp: (batch ? batch.mrp : item.mrp),
					sales_rate: (batch ? batch.sales_rate : item.sales_rate),
					tax: item.tax,
					gst_percentage: item.tax_value,
					gst: parseFloat(gst).toFixed(2),
					mrp_with_tax: item.mrp_with_tax,
					with_batch: item.with_batch,
					qty: item.qty || 1,
					total: parseFloat(total).toFixed(2),
					uom: item.uom,
					description: bt,
					uom_amount: item.uom_amount || false,
					uom_qty: item.uom_qty || false,
					uom_name: item.uom_name || false,
				};
				setSelectedProduct([...selectedProduct, m]);
			//}		
		}
		
	};
	
	const totalAmount=()=>{
		let total = 0;
		selectedProduct.map( item => total += parseFloat(item.total) );
		return parseFloat(total).toFixed(2);
	};
	
	const gstTotalAmount=()=>{
		let total = 0;
		selectedProduct.map( item => total += parseFloat(item.gst) );
		return parseFloat(total).toFixed(2);
	};
	
	const removeItem=(item)=>{
		let m = selectedProduct.filter(obj => obj.id!=item.id && obj.puid!=item.puid);
		setSelectedProduct(m);
	};
	
	const handleCancelClick=()=>{
		setSelectedProduct([]);
		setSelectedCategory(null);
		categoryClick();
	};
	
	
	const handleFormSubmit=()=>{
		//e.preventDefault();
		
		if(selectedProduct.length<1){
			toast.error('Please select a product to save!');
			return;
		}
		setLoader(true);
		var form = new FormData();
		form.append('api', context.state.api);
		form.append('hospital_id', context.state.loggedCompany.id);
		form.append('user_id', context.state.loggedUser.id);
		form.append('sales_man_id', context.state.loggedUser.id);
		form.append('sales_man_name', context.state.loggedUser.name);
		form.append('round_off', 0);
		form.append('i__discount', 0);
		form.append('i__accountingYear', context.state.loggedAcyear.id);
		form.append('i__billItems', JSON.stringify(selectedProduct));
		form.append('grand_total', totalAmount());
		form.append('i__netAmount', totalAmount());
		form.append('gst_total', gstTotalAmount());
		form.append('patient', '1');
		form.append('supplier', '1');
		form.append('sales_type', 'cash');
		form.append('patient_name', 'cash');
		form.append('patient_mobile', '');
		form.append('patient_address', '');
		form.append('bill_from', 'web_pos');
		form.append('i__billDate', momentDate(new Date(), 'YYYY-MM-DD'));
							
		axios.post(`${Api.Url}v1/phar/sales/save`, form).then(res=>{
			if(res['data'].status=='1'){
				toast.success(res['data'].message || 'Bill Saved Successfully');
				handleCancelClick();
			}
			else{
				toast.error(res['data'].message || 'Error');
			}
			setLoader(false);
		});
	}
	
	return(
		<div className="container-fluid">
						
			<Row>
			
				<Col md={4} >
					<Spin spinning={loader} >
					<form action="" method="post" encType="multipart/form-data" id="frm_pos_sales_entry" >
					
						<input type="hidden" name="api" value={context.state.api} />
						<input type="hidden" name="hospital_id" value={context.state.loggedCompany.id} />
						<input type="hidden" name="user_id" value={context.state.loggedUser.id} />
						<input type="hidden" name="i__accountingYear" value={context.state.loggedAcyear.id} />
						<input type="hidden" name="i__billItems" value={JSON.stringify(selectedProduct)} />
					
						<Card className="rounded-0" >
						<Card.Header className="rounded-0"  >
							<h5>Cash Bill
							<span className="float-end font-weight-600">#{billNo}</span>
							</h5>
						</Card.Header>
						<Card.Body className="rounded-0 font-14" style={{height: 'calc(78vh)', overflowY:'auto'}} >
							{selectedProduct && selectedProduct.length>0 && (selectedProduct.map((item,i)=>{
								return <BilledItemName product={item} onRemove={removeItem} />;
							}))
							}
							
						</Card.Body>
						<Card.Footer className="font-15 py-0" >
							<Row className="border-bottom font-13" >
								<Col md={4} className="d-flex align-items-center justify-content-state" >
									Rs. {totalAmount()}
								</Col>
								<Col md={4} className="d-flex align-items-center justify-content-state" >@ Discount</Col>
								<Col md={4} className="" >
									<button type="button" className="btn btn-block text-right btn-light" >
										0.00
									</button>
								</Col>
							</Row>
							<Row>
								<Col md={8} className="d-flex align-items-center justify-content-state" >Total to Pay</Col>
								<Col md={4} className="" >
									<button type="button" className="btn btn-block btn-lg font-weight-600 text-right" >
										Rs. {totalAmount()}
									</button>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
					</form>
					</Spin>
				</Col>
				
				<Col md={2} className="px-0" >
					<Card className="rounded-0" >
						<Card.Header className="rounded-0"  >
							<Card.Title>Categories</Card.Title>
						</Card.Header>
						<Card.Body className="rounded-0" style={{height: 'calc(91vh)', overflowY:'auto'}} >
							<Button className="btn-block"  variant={`${selectedCategory==null ? 'secondary' : 'outline-dark'}`} onClick={e=>categoryClick(null)} >
								Show All
							</Button>
							{grouped && grouped.length>0 && (grouped.map(item => <CategoryName 
								item={item[0]} 
								currentCategory={selectedCategory}
								onClick={categoryClick}
							/>))}
						</Card.Body>
					</Card>
				</Col>
				
				<Col md={6} >
					<Spin spinning={loader} >
					<Card className="rounded-0" >
						<Card.Header className="rounded-0"  >
							<Card.Title>Products</Card.Title>
						</Card.Header>
						<Card.Body className="rounded-0" style={{height: 'calc(83vh)', overflowY:'auto'}} >
							<Row>
								{catWiseProduct && catWiseProduct.length>0 && (catWiseProduct.map( item => <Col md={4}>
									<ItemName item={item} onClick={itemClick} />
								</Col>))}
							</Row>
						</Card.Body>
						<Card.Footer className="px-0 py-0" >
							<Row>
								<Col md={1} className="px-0 py-0" >
									<Link to="/app" className="btn btn-lg btn-block rounded-0" >
										<i className="icofont-home" ></i>
									</Link>
								</Col>
								<Col md={1} >
								</Col>
								<Col md={3} className="px-0 py-0" >
									<Button className="btn-block rounded-0" size="lg" variant="danger" onClick={e=>handleCancelClick()} >
										Cancel
									</Button>
								</Col>
								<Col md={7} className="px-0 py-0"  >
									<Button className="btn-block rounded-0" size="lg"  onClick={e=>handleFormSubmit()} >
										SAVE
									</Button>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
					</Spin>
				</Col>
				
				
			</Row>
			
			
			
		</div>
	);
};
export default PosSales;