import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {Spin, message } from 'antd';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import {Api}  from '../../../../utils/Variables';
import axios from 'axios';
import {LineLoader} from '../../../../views/elements';

import PsContext from '../../../../context/PsContext';

class AddTax extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if($("#i__endDate").val() < $("#i__startDate").val() ){
				message.error('End Date should be greater than Start Date' || 'Error');
				return;
			}
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/phar/accountingyear/save', $("#frm_pharAcYear").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					document.getElementById("frm_pharAcYear").reset();
					this.setState({showLoader: false});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Un expected error');
			this.setState({showLoader: false});
		}
	}
	
	render(){

		return(
			<React.Fragment>
				<LineLoader loading={this.state.showLoader} />
				<Spin spinning={this.state.showLoader} >
					<form action="" method="post" id="frm_pharAcYear" onSubmit={this.handleFormSubmit} >
						<input type="hidden" name="api" value={this.context.state.api} />
					<Row>
						<Col md={5} >
							<label className="font-13" >Start Date <span className="text-danger">*</span></label>
						</Col>
						<Col md={7} >
							<Form.Control
								type="date"
								name="i__startDate"
								id="i__startDate"
								size="sm"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={5} >
							<label className="font-13" >End Date <span className="text-danger">*</span></label>
						</Col>
						<Col md={7} >
							<Form.Control
								type="date"
								name="i__endDate"
								id="i__endDate"
								size="sm"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={5} >
							
						</Col>
						<Col md={7} >
							<label className="font-13" >
								<input type="checkbox" value="1" name="i__active" /> Active Status
							</label>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={5} >
							
						</Col>
						<Col md={7} >
							<div className="text-right" >
								<Button
									type="submit"
									variant="success"
									size="sm"
								>
									<i className="icofont-check"></i> Save
								</Button>
							</div>
						</Col>
					</Row>
						
					</form>
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default AddTax;
