import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, numberToWords
} from '../../../utils';
import{ LineLoader} from '../../../views/elements';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Spin, message, Table} from 'antd';
import PsContext from '../../../context/PsContext';

class SalesPaymentView extends Component{
    
    static contextType = PsContext;
    
    constructor(props){
        super(props);
        this.state = {
            showLoader: false,
            dataView: [],
            supplier : '',
            paginationPageSize : 20,
            currentPage: 1,
            dataView: this.props.dataView,
        }
		this.onSavePayment = this.onSavePayment.bind(this);
    }
	
	onSavePayment(e){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('data',JSON.stringify(this.state.dataView));
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('hospital_id',getls('loggedCompany','id'));
			form.append('type','sales_payment');
			form.append('description','Sales Payment');
			axios.post(Api.Url+'v1/phar/savevoucher', form).then(res=>{
				if(res['data'].status=='1'){	
					message.success(res['data'].message || 'Success');
					this.setState({showLoader : false});
					this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
    
    render(){
        
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'id',
                key: 'id',
                render:(text, record, index)=>(
                <>
                {(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
                </>
                )
            },
            
            {
				title: 'Bill No',
				dataIndex: 'billno',
				key: 'billno',
			},
			
			{
				title: 'Bill Date',
				dataIndex: 'billdate',
				key: 'billdate',
			},

            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
            },
        ];
        
        return(
        
            <div className="px-15 py-15">
                <LineLoader loading={this.state.showLoader} />
                <div className="mt-10" >
                    <Table pagination= {false}
                        size="small"
                        columns={columns}
                        bordered
                        loading={this.state.showLoader}
                        dataSource={this.state.dataView}
                        rowClassName={ (record, index) => {  return record.status=='0' ? 'bg-danger-light' : null; }   }
                    />
					<Button variant="success" size="sm" className="mt-5" style={{float : 'right'}} onClick={this.onSavePayment}>Save Payment</Button>
                </div>
            </div>
        );  
    }
    
}
export default SalesPaymentView;
