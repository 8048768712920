import React, { useEffect, useState, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {Row, Col, Card, Carousel} from 'react-bootstrap';

import PsContext from '../../../context/PsContext';

import NewAccount from './newAccount';

const Account=(props)=>{
	
	const context = useContext(PsContext);
	
	const type = props.match.params.type;
		
	return(
	<>
		
		{type=='new' &&(<NewAccount {...props} />)}
		
	</>
	);
	
};
export default withRouter(Account);