import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { Table } from 'antd';

import $ from 'jquery';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Spin, message, Select } from 'antd';
import{ LineLoader} from '../../../views/elements';
import PsContext from '../../../context/PsContext';

import NewSalesEntryScreen from './NewSalesEntryScreen';
import SalesPrint from './SalesPrint';
import SalesView from './SalesView';
const { Option } = Select;

class SalesReport extends Component{


static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showPrintModal: false,
			billid : '',
			fromDate : '',
			dataList: [],
			dataView: [],
			paginationPageSize : 20,
			currentPage: 1,
		}


		this.handleSearch = this.handleSearch.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleTablePageChange = this.handleTablePageChange.bind(this);
		this.onFromDateChange = this.onFromDateChange.bind(this);
		this.handleSupplierChange = this.handleSupplierChange.bind(this);
	}
	
	onFromDateChange(e){
		$("#stodate").val(momentDate((new Date()),'YYYY-MM-DD'));
		this.setState({fromDate : e.target.value});
	}
	
	showPrint(billid){
		
		this.setState({showPrintModal : true, billid : billid});
	}
	showView(billid){
		
		this.setState({showViewModal : true, billid : billid});
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}

	handleTablePageChange(current){
		this.setState({currentPage: current});
	}

	handleSupplierChange(value){
		var s = this.state.supplierList;
		var sdata = s.filter(function(obj){
			return(obj.id == value);
		});
		if(sdata.length > 0) {
			this.setState({selectedSupplier : sdata[0]});
		}
		this.setState({supplier : value});
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('from_date',$("#sfromdate").val());
			form.append('to_date',$("#stodate").val());
			form.append('report',"1");
			form.append('supplier', this.state.supplier);
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('hospital_id',getls('loggedCompany','id'));
			this.setState({dataList : [],dataView : []});
			
			let url = this.props.type && this.props.type=='return' 
					? `${Api.Url}v1/phar/sales_return/listall`
					: `${Api.Url}v1/phar/sales/listall`;
					
			axios.post(url, form).then(res=>{
				if(res['data'].status=='1'){
				//this.setState({purchaseList : res['data'].data,showLoader : false});
					this.setState({
					dataList: res['data'].data,
					dataView: res['data'].data,
					showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}


	handleSearch(e){
		try{
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var s = d.filter(function(obj){
			return(
			(upperCase(obj.patient_mobile).indexOf(v)>-1) ||
			(upperCase(obj.patient_name).indexOf(v)>-1) ||
			(upperCase(obj.billno).indexOf(v)>-1) );
			});
			this.setState({dataView: s});
		}
		catch(er){console.log(er); }
	}
	componentDidMount(){
		this.loadSuppliers();
	}
	loadSuppliers(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));
		
			
			axios.post(Api.Url+'v1/phar/get_supplier', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({supplierList : res['data'].data,showLoader : false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}

	render(){

		const columns = [
			{
				title: 'S.No',
				dataIndex: 'id',
				key: 'id',
				render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
				)
			},
			{
				title: 'Bill No',
				dataIndex: 'billno',
				key: 'billno',
			},
			{
				title: 'Bill Date',
				dataIndex: 'billdate',
				key: 'billdate',
			},
			{
				title: 'Patient Name',
				dataIndex: 'patient_name',
				key: 'patient_name',
			},
			{
				title: 'Patient Mobile',
				dataIndex: 'patient_mobile',
				key: 'patient_mobile',
			},
			{
				title: 'Amount',
				dataIndex: 'net_amount',
				key: 'net_amount',
			},
			{
				title : 'Manage',
				dataIndex : 'id',
				key : 'id',
				width: '150px',
				render : (text,record)=>(
					<center>
						<Button type="button" size="sm" variant="warning" onClick={this.showPrint.bind(this,record.billid)}>
							<i className="icofont-print"></i>
						</Button> &nbsp;
						<Button type="button" size="sm" variant="success" onClick={this.showView.bind(this,record.billid)}>
							<i className="icofont-eye"></i>
						</Button>
					</center>
				)
			}
		];


		return(
			<div className="panel panel-form">
				<LineLoader loading={this.state.showLoader} />
				
				<div className="panel-header" >
				<Row>
					<Col md={12}>
						{this.props.type && this.props.type=='return' ? 'Sales Return Report' : 'Sales Report'}
					</Col>
				</Row>
				</div>
				
				<div className="panel-body" >
						<Row>
						
							<Col md={3} >
								<table width="100%">
									<tr>
										<td  >
											<label className="font-13">From Date</label>
										</td>
										<td>
											<Form.Control
											type="date"
											size="sm"
											name="i__billDate"
											id="sfromdate"
											max={momentDate((new Date()),'YYYY-MM-DD')}
											onChange={this.onFromDateChange}
											/>
										</td>
									</tr>
								</table>
							</Col>
							<Col md={3}>
								<table width="100%" >
									<tr>
										<td>
											<label className="font-13">To Date</label>
										</td>
										<td>
											<Form.Control
											type="date"
											size="sm"
											name="i__billDate"
											id="stodate"
											min={momentDate((this.state.fromDate),'YYYY-MM-DD')}
											max={momentDate((new Date()),'YYYY-MM-DD')}
											/>
										</td>
									</tr>
								</table>
							</Col>
							<Col md={1} >
								<Button  
									type="button"
									size="sm"
									variant="primary"
									onClick={this.loadData}
								>
								View
								</Button>
							</Col>
							<Col md={5} >
								<div className="text-right" width="100%" >
									<Form.Control type="text" size="sm" name="i__search" placeholder="Search by Billno, Patient Name, Mobile..." onChange={this.handleSearch} />
								</div>
							</Col>
						</Row>
					
				<div className="mt-10 ps-table" >
					<Table
						size="small"
						columns={columns}
						bordered
						footer={() => `Total ${Object.keys(this.state.dataView).length} Sales in list`}
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						rowClassName={ (record, index) => {  return record.status=='0' ? 'bg-danger-light' : null; }   }
						pagination={{
						pageSize: this.state.paginationPageSize,
						onShowSizeChange: this.handlePaginationSizeChange,
						position: ['bottomRight'],
						onChange: this.handleTablePageChange,
					}} />
				</div>
				
				</div>
				
				<Modal show={this.state.showPrintModal} onHide={() => {this.setState({billid : '' ,showPrintModal : false})} } backdrop="static" className="cb__modal" size="lg" >
					<Modal.Header closeButton >
						Print
					</Modal.Header>
					<Modal.Body>
						<SalesView billid={this.state.billid} afterFinish={() => {this.setState({billid : '' ,showPrintModal : false})} } type={this.props.type} autoPrint />
					</Modal.Body>
				</Modal>
			
				<Modal show={this.state.showViewModal} onHide={() => {this.setState({billid : '' ,showViewModal : false})} } backdrop="static" className="cb__modal" size="lg" >
					<Modal.Header closeButton >
						View
					</Modal.Header>
					<Modal.Body>
						<SalesView billid={this.state.billid} afterFinish={() => {this.setState({billid : '' ,showViewModal : false})} } />
					</Modal.Body>
				</Modal>
			</div>
		);
	}

}
export default SalesReport;