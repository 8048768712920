import Dashboard from './Dashboard/dashboard';
import Products from './Products';
import Settings from './Settings';
import AccountingYear from './Settings/AccountingYear';
import ItemGroup from './Products/ItemGroup';
import Brand from './Products/Brand';
import Customers from './Customer';
import PurchaseEntry from './Purchase';
import PurchaseReport from './Purchase/PurchaseReport';
import PurchasePayment from './Purchase/PurchasePayment';

import SalesEntry from './Sales';
import SalesReport from './Sales/SalesReport';
import SalesPayment from './Sales/SalesPayment';

import PosSales from './PosSales';

import NewPurchaseReturnEntry from './Purchase/NewPurchaseReturnEntry';
import PurchaseReturnReport from './Purchase/PurchaseReturnReport';
import NewSalesReturnEntry from './Sales/NewSalesReturnEntry';
import SalesReturnReport from './Sales/SalesReturnReport';

import ExpireProductList from './Products/ExpireProductList';
import PartyWiseBalance from './Purchase/PartyWiseBalance';

import Prescription from './Prescription';

import CashInHand from './Reports/cashInHand';
import DailySales from './Reports/dailySales';
import BankTransactions from './Reports/bankTransactions';
import DayBook from './Reports/dayBook';
import CashFlow from './Reports/cashFlow';
import BankStatement from './Reports/bankStatement';
import StockSummary from './Reports/stockSummary';
import ItemBatchReport from './Reports/itemBatchReport';
import StockDetails from './Reports/stockDetails';

import PaymentOut from './PaymentOut';
import PaymentIn from './PaymentIn';
import BankAccounts from './BankAccounts';
import Cheques from './Cheques';
import Expenses from './Expenses';

const pageList = [
  { name: 'Dashboard', path: '/app', component: Dashboard, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Products', path: '/app/products', component: Products, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Settings', path: '/app/settings', component: Settings, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'AccountingYear', path: '/app/settings/accounting-year', component: AccountingYear, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'ItemGroup', path: '/app/products/item-group', component: ItemGroup, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Brand', path: '/app/products/brand', component: Brand, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Customers', path: '/app/customers', component: Customers, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Purchase Entry', path: '/app/purchase_entry', component: PurchaseEntry, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Purchase Report', path: '/app/purchase_report', component: PurchaseReport, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Purchase Payment', path: '/app/purchase_payment', component: PurchasePayment, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Sales Entry', path: '/app/sales_entry/:id?/:pid?', component: SalesEntry, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'POS Sales Entry', path: '/app/pos/sales_entry/:id?/:pid?', component: PosSales, allowed: ['admin', 'app', 'PHARMACY'], layout: 'empty'},
  //{ name: 'Sales Report', path: '/app/sales_report', component: SalesReport, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Sales Payment', path: '/app/sales_payment', component: SalesPayment, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Purchase Return Entry', path: '/app/purchase_return_entry', component: NewPurchaseReturnEntry, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Purchase Return Report', path: '/app/purchase_return_report', component: PurchaseReturnReport, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Sales Return Entry', path: '/app/sales_return_entry', component: NewSalesReturnEntry, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Sales Return Report', path: '/app/sales_return_report', component: SalesReturnReport, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Party Wise Balance', path: '/app/party_wise_balance', component: PartyWiseBalance, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Expire Products', path: '/app/expire_product', component: ExpireProductList, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Prescription', path: '/app/prescription', component: Prescription, allowed: ['admin', 'app', 'PHARMACY']},
  
  { name: 'Cash In Hand', path: '/app/cash_in_hand', component: CashInHand, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Daily Sales Report', path: '/app/sales_report', component: DailySales, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Payment Out', path: '/app/payment_out', component: PaymentOut, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Payment In', path: '/app/payment_in', component: PaymentIn, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Bank Accounts', path: '/app/bank_accounts', component: BankAccounts, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Bank Transactions', path: '/app/bank_transactions', component: BankTransactions, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Day Book', path: '/app/day_book', component: DayBook, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Cash Flow', path: '/app/cash_flow', component: CashFlow, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Cheques', path: '/app/cheques_transactions', component: Cheques, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Bank Statement', path: '/app/bank_statement', component: BankStatement, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Expenses', path: '/app/expenses', component: Expenses, allowed: ['admin', 'app', 'PHARMACY','cashbook']},
  { name: 'Stock Summary', path: '/app/reports/stock_sumnmary', component: StockSummary, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Item Batch Report', path: '/app/reports/item_batch', component: ItemBatchReport, allowed: ['admin', 'app', 'PHARMACY']},
  { name: 'Stock Details', path: '/app/reports/stock_detail', component: StockDetails, allowed: ['admin', 'app', 'PHARMACY']},
  
 
];

export default pageList;
