import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import PsContext from '../../../context/PsContext';
import { Api } from '../../../utils/Variables';

const UomList=(props)=>{
	
	const context = useContext(PsContext);
	const [loader, setLoader] = useState(false);
	const [data, setData] = useState([]);
	
	const { item } = props;
	
	const cancelClick=()=>{
		if(props.onClose)
			props.onClose();
	}
	
	const itemClick=(bt)=>{
		//item['gm_value'] = 
		if(props.onClick)
			props.onClick(bt);
		if(props.onClose)
			props.onClose();
	}
	
	const showGm=(gm)=>{
		let puid = 'p_'+item.id+'_'+gm;
		let product = {
			puid: puid,
			id: item.id,
			item_name: item.item_name,
			print_name: item.print_name,
			purchase_rate: item.purchase_rate,
			sales_rate: item.sales_rate,
			piece_sale_rate: item.piece_sale_rate,
			mrp: item.mrp,
			sales_rate: item.sales_rate,
			tax: item.tax,
			tax_value: item.tax_value,
			//gst: parseFloat(gst).toFixed(2),
			mrp_with_tax: item.mrp_with_tax,
			with_batch: item.with_batch,
			qty: gm,
			uom: item.uom,
			_id: uuidv4(),
			uom_name: parseFloat(gm) * parseFloat(1000) + (item.uom=='kg' ? ' gm' : ' ml'),
			uom_qty: 1,
			uom_amount: (item.piece_sale_rate  * parseFloat(gm)),
		};
		
		return <Col md={4} >
			<Card className="rounded-0 shadow-hover cursor-pointer bg-light mb-10" onClick={e=>itemClick(product)} >
				<Card.Body className="px-0 py-0 rounded-0 text-center  font-15" >
					<div className="py-10 px-10"  >
						{product.uom_name}
					</div>
					<Button className="btn-block rounded-0 font-weight-600" variant="dark" >
						Rs. {product.uom_amount}
					</Button>
				</Card.Body>
			</Card>
		</Col>;
	};
	
	
	const showKg=(gm, displayValue)=>{
		let puid = 'p_'+item.id+'_'+gm;
		let product = {
			id: item.id,
			puid: puid,
			item_name: item.item_name,
			print_name: item.print_name,
			purchase_rate: item.purchase_rate,
			sales_rate: item.sales_rate,
			piece_sale_rate: item.piece_sale_rate,
			mrp: item.mrp,
			sales_rate: item.sales_rate,
			tax: item.tax,
			tax_value: item.tax_value,
			//gst: parseFloat(gst).toFixed(2),
			mrp_with_tax: item.mrp_with_tax,
			with_batch: item.with_batch,
			qty: gm,
			uom: item.uom,
			_id: uuidv4(),
			uom_name: displayValue + (item.uom),
			uom_qty: 1,
			uom_amount: (item.piece_sale_rate  * parseFloat(gm)),
		};
		
		return <Col md={3} >
			<Card className="rounded-0 shadow-hover cursor-pointer bg-light mb-10" onClick={e=>itemClick(product)} >
				<Card.Body className="px-0 py-0 rounded-0 text-center  font-15" >
					<div className="py-10 px-10"  >
					{displayValue} {item.uom}<br />
					</div>
					<Button className="btn-block rounded-0 font-weight-600" variant="dark" >
						Rs. {(item.piece_sale_rate  * parseFloat(gm))}
					</Button>
				</Card.Body>
			</Card>
		</Col>;
	};
	
	
	return(
		<>
			<h4 className="font-weight-600 border-bottom mb-10 pb-2" >{item.item_name}</h4>
			<div style={styles.bodyStyle} >
				
				<Row className="border-bottom mb-20 pb-10" >
					{showKg(0.25, '1/4')}
					{showKg(0.5, '1/2')}
					{showKg(0.75, '3/4')}
					{showKg(1, '1')}
				</Row>
				<Row className="" >
					{showGm(0.05)}
					{showGm(0.1)}
					{showGm(0.15)}
					{showGm(0.20)}
					{showGm(0.30)}
					{showGm(0.40)}
				</Row>
				<Row>
					
				</Row>
			</div>
			<Row>
				<Col md={8} ></Col>
				<Col md={4} >
					<div className="text-right" >
						<Button 
							type="button"
							size="md"
							variant="danger"
							onClick={e=>cancelClick()}
						>
							<i className="icofont-close me-2"></i> CANCEL
						</Button>	
					</div>
				</Col>
			</Row>
		</>
	);
};
export default UomList;

const styles = {
	bodyStyle: {
		height: 'calc(70vh)',
		overflowY: 'auto',
		overflowX: 'hidden',
	}
}