import React, { useState, useContext, useEffect } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { Row,Col, Card, Form, FormCheck, Button, Modal, Spinner, } from 'react-bootstrap';
import {Spin, message, Select} from 'antd';

import PsContext from '../../../context/PsContext';
import { getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, capitalizeFirst } from '../../../utils';
import { Api, Units }  from '../../../utils/Variables';

const AddItem=(props)=>{
	
	const context = useContext(PsContext);
	const [validated, setValidated] = useState(false);
	const [loader, setLoader] = useState(false);
	
	const  [taxes, setTaxes] = useState([]);
	const  [brand, setBrand] = useState([]);
	const  [group, setGroup] = useState([]);	
	
	const loadItemGroups=()=>{
		setLoader(true);
		axios.get(Api.Url+'v1/phar/itemgroup/listall?api='+context.state.api+'&status=1').then(res=>{
			if(res['data'].status=='1'){
				setGroup(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadBrands=()=>{
		setLoader(true);
		axios.get(Api.Url+'v1/phar/brand/listall?api='+context.state.api+'&status=1').then(res=>{
			if(res['data'].status=='1'){
				setBrand(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadTaxes=()=>{
		setLoader(true);
		axios.get(Api.Url+'v1/phar/tax/listall?api='+context.state.api+'&status=1').then(res=>{
			if(res['data'].status=='1'){
				setTaxes(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	  const handleSubmit = (event) => {
		  event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {		  
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		//setValidated(false);
		setLoader(true);
		axios.post(`${Api.Url}v1/phar/pharmacy/save_item`, $("#frm_Cb_AddProduct").serialize()).then(res=>{
			if(res['data'].errorcode == '200' ){
				message.success(res['data'].content || 'Success');
				document.getElementById("frm_Cb_AddProduct").reset();
				if(props.afterFinish)
					props.afterFinish();
			}
			else{
				message.error(res['data'].content || 'Error');
			}
			setLoader(false);
		});

	  };
	  
	  useEffect(()=>{
		  
		loadItemGroups();
		loadBrands();
		loadTaxes();
	  },[]);
	  
	return(
	<>
		<Form noValidate validated={validated} onSubmit={handleSubmit} id="frm_Cb_AddProduct" >
			
			<div className="cb__modal__body" >
			
			
			<input type="hidden" name="api" value={context.state.api} />
			
			<Row>
				<Col md={2} ><label className="control-label" >Item Name <span className="text-danger">*</span></label></Col>
				<Col md={10}>
					<Form.Control
						name="i__itemName"
						size="sm"
						className="text-uppercase"
						required
						autoFocus={true}
					/>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Print Name <span className="text-danger"></span></label></Col>
				<Col md={10}>
					<Form.Control
						name="i__printName"
						size="sm"
						className="text-uppercase"
					/>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Item Code <span className="text-danger"></span></label></Col>
				<Col md={4}>
					<Form.Control
						type="text"
						name="i__code"
						size="sm"
					/>
				</Col>
				
				<Col md={2} ><label className="control-label" >HSN </label></Col>
				<Col md={4}>
					<Form.Control
						type="text"
						name="i__hsn"
						size="sm"
					/>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Item Group  <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						as="select"
						name="i__itemGroup"
						size="sm"
						required
					>
						<option value=""> Select </option>
						{group && group.length>0 && (group.map((item,i)=>{
							return <option value={item.id} key={i} >{item.group_name}</option>;
						}))
						}
					</Form.Control>
				</Col>
				<Col md={2} ><label className="control-label" >Brand <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						as="select"
						name="i__brand"
						size="sm"
						required
					>
						<option value=""> Select </option>
						{brand && brand.length>0 && (brand.map((item,i)=>{
							return <option value={item.id} key={i} >{item.name}</option>;
						}))
						}
					</Form.Control>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Tax (%) <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						as="select"
						name="i__tax"
						size="sm"
						required
					>
						<option value=""> Select </option>
						{taxes && taxes.length>0 && (taxes.map((item,i)=>{
							return <option value={item.id} key={i} >{item.tax_value}</option>;
						}))
						}
					</Form.Control>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >PUR. Rate <span className="text-danger">*</span></label></Col>
				<Col md={2}>
					<Form.Control
						name="i__purchaseRate"
						id="i__purchaseRate"
						size="sm"
						className="text-right"
						required
						onKeyPress={decimalKeyPress}
					/>
				</Col>
				<Col md={2} >
					<label className="mt-2"  >
						<FormCheck.Input type="radio" name="i__purchaseTax" value="1" defaultChecked={true}  /> With Tax
					</label>
				</Col>
				<Col md={2} >
					<label className="mt-2" >
						<FormCheck.Input type="radio" name="i__purchaseTax" value="0" /> Without Tax
					</label>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >MRP <span className="text-danger">*</span></label></Col>
				<Col md={2}>
					<Form.Control
						name="i__mrp"
						id="i__mrp"
						size="sm"
						className="text-right"
						onKeyPress={decimalKeyPress}
						required
					/>
				</Col>
				<Col md={2} >
					<label className="mt-2"  >
						<FormCheck.Input type="radio" name="i__mrpTax" value="1" defaultChecked={true}  /> With Tax
					</label>
				</Col>
				<Col md={2} >
					<label className="mt-2" >
						<FormCheck.Input type="radio" name="i__mrpTax" value="0" /> Without Tax
					</label>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Retail Rate <span className="text-danger">*</span></label></Col>
				<Col md={2}>
					<Form.Control
						size="sm"
						name="i__salesPrice"
						id="i__salesPrice"
						className="text-right"
						onKeyPress={decimalKeyPress}
						required
					/>
				</Col>
				<Col md={3} ><label className="control-label" >Whole Sale Rate <span className="text-danger">*</span></label></Col>
				<Col md={2}>
					<Form.Control
						size="sm"
						name="i__WholeSalesPrice"
						id="i__WholeSalesPrice"
						className="text-right"
						onKeyPress={decimalKeyPress}
						required
					/>
				</Col>
			</Row>
			
			{/*<input type="hidden" name="i__packing" value="1" />
			<input type="hidden" name="i__packingCount" value="1" />
			<input type="hidden" name="i__packingTotal" value="1" />*/}
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Packing <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Row>
						<Col md={5}>
							<Form.Control
								name="i__packing"
								onKeyPress={integerKeyPress}
								size="sm"
								defaultValue="1"
								required
							/>
						</Col>
						<Col  md={1}  className="d-flex align-items-center "  ><b> x </b></Col>
						<Col  md={5}>
							<Form.Control
								name="i__packingCount"
								onKeyPress={integerKeyPress}
								defaultValue="1"
								size="sm"
								required
							/>
						</Col>
						{/*<Col  md={1} className="d-flex align-items-center " ><b> = </b></Col>
						<Col  md={4}>
							<Form.Control
								name="i__packingTotal"
								onKeyPress={integerKeyPress}
								defaultValue="1"
								size="sm"
								required
							/>
						</Col>*/}
					</Row>
				</Col>
				<Col  md={2} className="d-flex align-items-center " >Min Stock alert</Col>
				<Col md={4} >
					<Form.Control
						name="i__minStockAlert"
						onKeyPress={integerKeyPress}
						defaultValue="1"
						size="sm"
						required
					/>
				</Col>
			</Row>
			
			<Row className="mt-2" >
				<Col md={2} className="" ><label className="control-label" >UOM <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						as="select"
						name="i__uom"
						size="sm"
						required
					>
					{Units.map(item => <option value={item.value} > {item.name} ({capitalizeFirst(item.value)})</option>)}
					</Form.Control>
				</Col>
				<Col md={2} className="text-right" ><label className="control-label " >Location <span className="text-danger"></span></label></Col>
				<Col md={4}>
					<Form.Control
						type="text"
						name="i__location"
						size="sm"
					/>
				</Col>
			</Row>
			
			<Row className="mt-2" >				
				<Col md={2} ><label className="control-label" >Zero Billing <span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						as="select"
						name="i__zeroStock"
						size="sm"
						required
					>
						<option value="0"> NO </option>
						<option value="1"> YES </option>
					</Form.Control>
				</Col>
				
				<Col md={2} className="pr-0" ><label className="control-label" >Batch wise stock<span className="text-danger">*</span></label></Col>
				<Col md={4}>
					<Form.Control
						as="select"
						name="with_batch"
						size="sm"
						required
					>
						<option value="0"> NO </option>
						<option value="1"> YES </option>
					</Form.Control>
				</Col>
			</Row>
			
			
			
			<Row className="mt-2" >
				<Col md={2} ><label className="control-label" >Combination <span className="text-danger"></span></label></Col>
				<Col md={10}>
					<Form.Control
						as="textarea"
						name="i__combination"
						placeholder="Values Separated by comma"
						size="sm"
						rows="5"
					/>
				</Col>
			</Row>
			
			</div>
			
			<div className="cb__modal__footer" >
			<Row className="" >
				<Col>
					<div className="text-right" >
						
						<Button
							type="submit"
							size="sm"
							variant="default"
							disabled={loader}
						>
						{loader ? <>
							<Spinner animation="border" size="sm" /> <span className="ps-2 border-left">WAIT..</span>
						</> : <>
							<i className="icofont-check pe-2 border-right" ></i> SAVE ITEM
						</>}
						</Button>
					</div>
				</Col>
			</Row>
			
			</div>
	
		</Form>
	</>
	);
};
export default AddItem;