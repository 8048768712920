import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Button, Modal, InputGroup, ButtonGroup, } from 'react-bootstrap';
import { Helmet } from "react-helmet";

import {
	getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';
import PsContext from '../../../context/PsContext';
import { Spin, message, Select } from 'antd';
import SearchProducts from '../Purchase/SearchProducts';
import ModalConfirm from '../components/ModalConfirm';
import SalesView from './SalesView';
import $ from 'jquery';

const { Option } = Select;
class NewSalesEntryScreen extends Component {

	static contextType = PsContext;


	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,
			showItemModal: false,
			billId: null,
			showPrint: false,
			billedItems: [],
			selectedPatient: [],
			productList: [],
			patientList: [],
			productItem: [],
			batchList: [],
			prescriptionList: [],
			patient: '',
			billNo: '',
			prescriptionId: '',
			billType: 'Bill',
			supplierId: '1',
			netAmount: 0,
			roundOff: 0,
			salesType: 'cash',
			isWholeSale: false,
			suppliers: [],
			selectedSupplier: [],
		}
		
		this.handleProductSearch = this.handleProductSearch.bind(this);
		this.handleItemSelect = this.handleItemSelect.bind(this);
		this.handleBatchSelect = this.handleBatchSelect.bind(this);
		this.handlePatientChange = this.handlePatientChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleBatchChange = this.handleBatchChange.bind(this);
		this.resetForm = this.resetForm.bind(this);
	}

	resetForm(e) {
		this.setState({ billedItems: [], patient: '', selectedPatient: [] });
		document.getElementById('frm_addSales').reset();
	}

	loadParties() {

		this.setState({ suppliers: [] });
		axios.get(`${Api.Url}v1/phar/customer/listall?api=${this.context.state.loggedApi}&status=all`).then(res => {
			if (res['data'].status == '1') {
				this.setState({ suppliers: res['data'].data });
			}
		});
	};

	handleBatchChange(index, e) {

		try {
			var s = this.state.billedItems;
			s[index]['batch'] = e;
			var b = this.state.batchList;

			var bindex = b.findIndex(function (obj) {
				return (obj.id == e);
			});

			if (bindex > -1) {

				var rate = s[index]['qty'] * b[bindex].sales_rate;
				var gst = rate * s[index]['gst_percentage'] / 100;
				var total = parseFloat(rate) + parseFloat(gst);
				s[index]['purchase_rate'] = b[bindex].purchase_rate;
				s[index]['amount'] = b[bindex].sales_rate;
				s[index]['mrp'] = b[bindex].mrp;
				s[index]['gst'] = parseFloat(gst).toFixed(2);
				s[index]['total'] = parseFloat(total).toFixed(2);
			}
			this.setState({
				billedItems: s
			});
		}
		catch (er) { }
	}

	handlePatientChange(value) {
		var s = this.state.patientList;
		var sdata = s.filter(function (obj) {
			return (obj.id == value);
		});
		if (sdata.length > 0) {
			this.setState({ selectedPatient: sdata[0] });
		}
		this.setState({ patient: value });
	}

	handleFormSubmit(e) {
		e.preventDefault();
		try {
			/* if(this.state.patient == ''){
				message.error('Please select patient' || 'Error');
				return;
			} */
			if (this.state.supplierId == '') {
				alert('Please select a supplier');
				return;
			}

			if (this.state.billedItems.length == 0) {
				message.error('Please select product' || 'Error');
				return;
			}

			if (!window.confirm('Do you want to save?')) {
				return false;
			}

			this.setState({ showLoader: true });
			let url = this.props.type && this.props.type == 'return'
				? `${Api.Url}v1/phar/sales_return/save`
				: `${Api.Url}v1/phar/sales/save`;

			axios.post(url, $("#frm_addSales").serialize()).then(res => {
				if (res['data'].status == '1') {
					this.setState({
						billId: res['data'].billid,
						showLoader: false,
						billedItems: [],
						patient: '',
						selectedPatient: [],
						showPrint: true,
					});
					//message.success(res['data'].message || 'Success');
					document.getElementById('frm_addSales').reset();

					this.loadBillNo();
					this.loadProducts();
					this.loadBatches();

					if (this.props.afterFinish)
						this.props.afterFinish();
				}
				else {
					message.error(res['data'].message || 'Error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	componentDidMount() {

		//$("input.select-on-focus").focus(function() { $(this).select(); } );

		this.loadParties();
		this.loadProducts();
		this.loadBatches();
		//this.loadPatients();
		this.loadBillNo();
		/*if(this.props.match.params){
			this.setState({prescriptionId : this.props.match.params.pid});
			//this.loadPrescription(this.props.match.params.id,this.props.match.params.pid);
			//this.handlePatientChange(this.props.match.params.id);
		}*/
	}

	loadPrescription(patient, prescriptionId) {
		try {
			this.setState({ showLoader: true });


			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id', getls('loggedCompany', 'id'));
			form.append('i__accountingYear', getls('loggedAcyear', 'id'));
			form.append('patient_id', patient);
			form.append('prescription_id', prescriptionId);

			axios.post(Api.Url + 'v1/prescription/listbyprescriptionid', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ prescriptionList: res['data'].data, showLoader: false });
					var p = res['data'].data;
					var s = [];
					for (var i = 0; i < p.length; i++) {

						var count = parseInt(p[i].morning) + parseInt(p[i].noon) + parseInt(p[i].evening) + parseInt(p[i].night);
						var qty = parseInt(p[i].days) * count;
						var rate = qty * p[i].sales_rate;
						var gst = rate * p[i].tax_value / 100;
						var total = parseFloat(rate) + parseFloat(gst);

						s.push({
							id: p[i].product_id,
							add_notes: 0,
							notes: '',
							batch: '',
							name: p[i].productname,
							purchase_rate: p[i].purchase_rate,
							amount: p[i].sales_rate,
							mrp: p[i].mrp,
							tax: p[i].tax,
							gst_percentage: p[i].tax_value,
							gst: parseFloat(gst).toFixed(2),
							qty: qty,
							total: parseFloat(total).toFixed(2),
						});
					}

					this.setState({
						billedItems: s,
					});
				}
				else {
					//message.error(res['data'].message || 'Error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	loadBillNo() {
		try {
			this.setState({ showLoader: true });
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id', getls('loggedCompany', 'id'));
			form.append('i__accountingYear', getls('loggedAcyear', 'id'));

			let url = this.props.type && this.props.type == 'return'
				? `${Api.Url}v1/phar/sales_return/billno`
				: `${Api.Url}v1/phar/sales/getbillno`;
			axios.post(url, form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ billNo: res['data'].data, showLoader: false });
				}
				else {
					message.error(res['data'].message || 'Error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	loadPatients() {
		try {
			this.setState({ showLoader: true });
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id', getls('loggedCompany', 'id'));

			axios.post(Api.Url + 'v1/get_active_patient', form).then(res => {
				if (res['data'].status == '1') {
					this.setState({ patientList: res['data'].data, showLoader: false });
					if (this.props.match.params) {
						this.setState({ patient: this.props.match.params.id });
					}
				}
				else {
					message.error(res['data'].message || 'Error');
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			console.log(error);
			this.setState({ showLoader: false });
		}
	}

	loadProducts() {
		try {

			this.setState({ showLoader: true });
			axios.get(Api.Url + 'v1/phar/item/getactive?api=' + this.context.state.api).then(res => {
				if (res['data'].status == '1') {
					this.setState({
						productList: res['data'].data,
						showLoader: false,
					});
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}

	loadBatches() {
		try {

			this.setState({ showLoader: true });
			axios.get(Api.Url + 'v1/phar/batch/listall?api=' + this.context.state.api).then(res => {
				if (res['data'].status == '1') {
					this.setState({
						batchList: res['data'].data,
						showLoader: false,
					});
				}
				else {
					this.setState({ showLoader: false });
				}
			});
		}
		catch (error) {
			this.setState({ showLoader: false });
		}
	}

	handleProductSearch() {
		this.setState({ showItemModal: true });
	}

	handlePatientSearchClick() {
		this.setState({ showPatientModal: true });
	}

	handleRemoveItems(index, e) {
		try {
			var s = this.state.billedItems;
			s.splice(index, 1);
			this.setState({ billedItems: s });
		}
		catch (er) { }
	}

	handleDescChange(index, field, e) {
		try {
			var s = this.state.billedItems;
			var val = e.target.value;
			s[index][field] = val;
			this.setState({
				billedItems: s
			});
		}
		catch (er) { }
	}

	handleQtyChange(index, e) {
		try {
			var s = this.state.billedItems;

			var qty = e.target.value;
			s[index]['qty'] = qty;
			var amt = qty * s[index].piece_sale_rate;
			var gst = amt * s[index]['gst_percentage'] / 100;
			var total = parseFloat(s[index]['piece_sale_rate']) + parseFloat(gst);
			if (s[index]['mrp_with_tax'] == '1') {
				total = parseFloat(s[index]['piece_sale_rate']);
			}
			total = parseFloat(total) * parseFloat(qty);
			s[index]['gst'] = gst;
			s[index]['total'] = parseFloat(total).toFixed(2);
			this.setState({
				billedItems: s
			});
		}
		catch (er) { }
	}

	handleDiscount(field, e) {
		try {
			var val = e.target.value;
			var total = parseFloat(this.getTotal() - val).toFixed(2);
			//$("#i__netAmount").val(total);
			//this.setState({netAmount: total});
		}
		catch (er) { }
	}

	handleItemSelect(itemdata, batch = false) {
		try {
			var item=itemdata;
			if(this.state.isWholeSale)
			item.piece_sale_rate=itemdata.piece_whole_sale_rate;

			var s = this.state.billedItems;
			if (Object.keys(item).length > 0) {

				var gst = item.piece_sale_rate * item.tax_value / 100;
				var total = parseFloat(item.piece_sale_rate) + parseFloat(gst);
				if (item.mrp_with_tax == '1') {
					total = parseFloat(item.piece_sale_rate);
				}

				if (batch) {
					gst = batch.piece_sale_rate * item.tax_value / 100;
					total = parseFloat(batch.piece_sale_rate) + parseFloat(gst);
					if (item.mrp_with_tax == '1') {
						total = parseFloat(batch.piece_sale_rate);
					}
				}

				var itemIndex = s.findIndex(function (obj) {
					return (obj.id == item.id && obj.batch == batch.id);
				});

				if (itemIndex > -1) {
					var qty = s[itemIndex]['qty'];
					s[itemIndex]['qty'] = (parseFloat(qty) + 1).toFixed(0);
					s[itemIndex]['gst'] = (parseFloat(gst) * (parseFloat(qty) + 1)).toFixed(2);
					s[itemIndex]['total'] = (parseFloat(total) * (parseFloat(qty) + 1)).toFixed(2);
				}
				else {
					s.push({
						id: item.id,
						add_notes: 0,
						notes: '',
						batch: (batch ? batch.id : ''),
						batch_name: (batch ? batch.batch : ''),
						expiry: (batch ? batch.expiry : ''),
						name: item.item_name,
						print_name: item.print_name,
						purchase_rate: (batch ? batch.purchase_rate : item.purchase_rate),
						amount: (batch ? batch.sales_rate : item.sales_rate),
						piece_sale_rate: (batch ? batch.piece_sale_rate : item.piece_sale_rate),
						mrp: (batch ? batch.mrp : item.mrp),
						sales_rate: (batch ? batch.sales_rate : item.sales_rate),
						tax: item.tax,
						gst_percentage: item.tax_value,
						gst: parseFloat(gst).toFixed(2),
						mrp_with_tax: item.mrp_with_tax,
						with_batch: item.with_batch,
						qty: 1,
						total: parseFloat(total).toFixed(2),
					});
				}

			}
			//$(".select-on-focus:last").focus();
			this.setState({
				billedItems: s,
				showItemModal: false,
			});

		}
		catch (er) { console.log(er); }
	}

	handleBatchSelect(item) {

		try {
			var s = this.state.billedItems;
			var b = this.state.batchList;

			if (Object.keys(item).length > 0) {
				b.push(item[0]);
				var index = s.findIndex((obj) => {
					return (obj.id == item[0].product_id);
				});
				if (index > -1) {
					var gst = item[0].piece_sale_rate * s[index].tax_value / 100;
					var total = parseFloat(item[0].piece_sale_rate) + parseFloat(gst);
					s[index]['batch'] = item[0].id;
					s[index]['purchase_rate'] = item[0].purchase_rate;
					s[index]['amount'] = item[0].piece_sale_rate;
					s[index]['mrp'] = item[0].mrp;
					s[index]['gst'] = parseFloat(gst).toFixed(2);
					s[index]['total'] = parseFloat(total).toFixed(2);
				}
			}
			this.setState({
				billedItems: s,
				batchList: b,
				showBatchModal: false,
			});
		}
		catch (er) { }
	}

	setAddNotes(index) {
		try {
			var s = this.state.billedItems;
			s[index]['add_notes'] = s[index]['add_notes'] == '0' ? '1' : '0';
			this.setState({
				billedItems: s,
			});
		}
		catch (er) { }
	}

	getTotal() {
		try {
			var s = this.state.billedItems;
			var t = 0;
			var discount = $("#i__discount").val();
			s.map((item, i) => {
				t = parseFloat(t) + parseFloat(item.total);
			});

			var total = t - discount;
			//$("#i__netAmount").val(total.toFixed(2));
			//this.setState({netAmount: total.toFixed(2)});

			return t.toFixed(2);
		}
		catch (er) { }
	}

	getNetAmount() {
		try {
			var s = this.state.billedItems;
			var t = 0;
			//var discount = $("#i__discount").val();
			s.map((item, i) => {
				t = parseFloat(t) + parseFloat(item.total);
			});

			//var total = t-discount;
			var ro = Math.round(t);

			return ro.toFixed(2);
		}
		catch (er) { }
	}


	getRoundOff() {
		try {
			var s = this.state.billedItems;
			var t = 0;
			//var discount = $("#i__discount").val();
			s.map((item, i) => {
				t = parseFloat(t) + parseFloat(item.total);
			});

			//var total = t-discount;
			var ro = Math.round(t);

			return (parseFloat(ro) - parseFloat(t)).toFixed(2);
		}
		catch (er) { }
	}


	getPatient(field) {
		try {
			var d = this.state.selectedPatient;
			return d[field];
		}
		catch (er) { return ''; }
	}

	getTotalByField(field) {
		var total = 0;
		this.state.billedItems.map((item, i) => {
			total = parseFloat(total) + parseFloat(item[field]);
		});
		return parseFloat(total).toFixed(2);
	}

	handleSupplierChange(e) {
		try {
			var s = this.state.suppliers.filter(item => item.id == e);

			this.setState({
				selectedSupplier: s[0],
				supplierId: s[0].id,
			});
		}
		catch (er) { }
	}

	handleSalesTypeChange(e) {

		this.setState({
			salesType: e.target.value,
			supplierId: e.target.value == 'cash' ? '1' : '',
			selectedSupplier: []
		});

		if (e.target.value == 'cash') {
			setTimeout(function () {
				document.getElementById("patient_address").value = '';
				document.getElementById("patient_mobile").value = '';
			}, 300);
		}
	}
	handleSalesPriceTypeChange(e) {
		
		if (e.target.value == 'wholeSalesPrice') {
			
			this.setState({
				isWholeSale: true,
			});
			
		}
		else {
			this.setState({
				isWholeSale:false,
			});
			
		}
		
	}

	render() {

		let gstTotal = 0;

		return (
			<div className="panel panel-form" >
				<div className={this.props.type && this.props.type == 'return' ? 'panel-header bg-gradiant-danger' : "panel-header"} >

					<Row>
						<Col md={6} >
							{this.props.type && this.props.type == 'return' ? 'Sales Return Entry' : 'Sales Entry'}
						</Col>
						<Col md={6} >
							<div className="text-right" >
								<a
									className="btn btn-sm last-icon"
									onClick={() => window.open('Calculator:///')}
								>
									<i className="icofont-calculator"></i>
								</a>
							</div>
						</Col>
					</Row>
				</div>


				<form action="" method="post" encType="multipart/form-data" id="frm_addSales" onSubmit={this.handleFormSubmit}>


					<input type="hidden" name="api" value={this.context.api()} />
					<input type="hidden" name="hospital_id" value={getls('loggedCompany', 'id')} />
					<input type="hidden" name="user_id" value={getls('loggedUser', 'id')} />
					<input type="hidden" name="i__accountingYear" value={getls('loggedAcyear', 'id')} />
					<input type="hidden" name="i__billItems" value={JSON.stringify(this.state.billedItems)} />
					<input type="hidden" name="patient" value={this.state.patient} />
					<input type="hidden" name="supplier" value={this.state.supplierId} />
					<Spin spinning={this.state.showLoader} >

						<div className="panel-body ">

							<Row>
								<Col md={9} >
									<table width="" >
										<tr>
											<td width="78" ><label className="">
												{this.props.type && this.props.type == 'return' ? 'Return Type' : 'Sales Type'}</label></td>
											<td width="150" >
												{/*<Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a Patient"
										  optionFilterProp="children"
										  onChange={this.handlePatientChange}
										  style={{width: '100%'}}
										  value={this.state.patient}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.patientList && this.state.patientList.map((item) => (
												<Option key={item.id} value={item.id}>{item.patient_name}</Option>
										  ))}
										</Select>*/}
												<select name="sales_type" className="form-control form-control-sm" onChange={this.handleSalesTypeChange.bind(this)} >
													<option value="cash">Cash Sales</option>
													<option value="credit">Credit Sales</option>
												</select>

											</td>
											<td width="150" >
												<select name="sales_price_type" id="sales_price_type" className="form-control form-control-sm" onChange={this.handleSalesPriceTypeChange.bind(this)} >
													<option value="salesPrice">Sales</option>
													<option value="wholeSalesPrice">Whole Sales</option>
												</select>
											</td>
										</tr>
									</table>
								</Col>

								<Col md={3} >
									<table width="100%" >
										<tr>
											<td width="72">
												<label className="">Bill.No</label>
											</td>
											<td>
												<Form.Control
													type="text"
													size="sm"
													name="i__billNo"
													value={this.state.billNo}
													readOnly
												/>
											</td>
										</tr>
									</table>
								</Col>
							</Row>

							<Row>
								<Col md={9} >
									<table width="100%" >
										<tr>
											<td width="78" ><label className="">Customer</label></td>
											<td width="300" >
												{this.state.salesType == 'cash' ? <Form.Control
													type="text"
													size="sm"
													name="patient_name"
													placeholder="Name"
													defaultValue="Cash"
													style={{ textTransform: 'uppercase' }}
												/>
													: <>
														<Select
															style={{ width: '100%' }}
															showSearch
															filterOption={(input, option) =>
																option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
															}
															onChange={this.handleSupplierChange.bind(this)}
														>
															{this.state.suppliers.map((item, i) => {
																return <Option value={item.id} >{item.name}</Option>;
															})}
														</Select>
														<input type="hidden" name="patient_name" value={this.state.selectedSupplier.name} />
													</>
												}
											</td>
											<td width="125" >
												{this.state.salesType == 'cash' ? <Form.Control
													type="number"
													size="sm"
													placeholder="Mobile"
													name="patient_mobile"
													id="patient_mobile"
												/> : <Form.Control
													type="text"
													size="sm"
													placeholder="Mobile"
													name="patient_mobile"
													value={this.state.selectedSupplier.mobile}
												/>
												}
											</td>
											<td>
												{this.state.salesType == 'cash' ? <Form.Control
													type="text"
													size="sm"
													name="patient_address"
													id="patient_address"
													placeholder="Address"
												/> : <Form.Control
													type="text"
													size="sm"
													name="patient_address"
													placeholder="Address"
													value={this.state.selectedSupplier.place}
												/>
												}
											</td>
										</tr>
									</table>
								</Col>

								<Col md={3} >
									<table width="100%" >
										<tr>
											<td width="72" >
												<label className="">Bill.Date</label>
											</td>
											<td>
												<Form.Control
													type="date"
													size="sm"
													name="i__billDate"
													max={momentDate((new Date()), 'YYYY-MM-DD')}
													defaultValue={momentDate((new Date()), 'YYYY-MM-DD')}
												/>
											</td>
										</tr>
									</table>
								</Col>
							</Row>


							<table className="table table-sm table-bordered mt-10 mb-0 table-invoice"  >
								<thead className="thead-primary" >
									<tr>
										<th width="40" className="font-weight-600" >S.NO</th>
										<th className="font-weight-600" >PARTICULARS</th>
										<th width="90" className="font-weight-600" >BATCH</th>
										<th width="90" className="font-weight-600" >EXP</th>
										<th width="60" className="font-weight-600" >QTY</th>
										<th width="90" className="font-weight-600" >MRP</th>
										<th width="90" className="font-weight-600" >SL.RT</th>
										<th width="90" className="font-weight-600" >GST(%)</th>
										<th width="90" className="font-weight-600" >GST(Rs)</th>
										<th width="90" className="font-weight-600" >TOTAL</th>
										<th width="60" className="font-weight-600" >#</th>
									</tr>
								</thead>
							</table>

							<div style={{ height: 'calc(100vh - 340px)', overflowY: 'auto' }} className="bg-white" >
								<table className="table table-sm table-bordered table-invoice" >
									<tbody>
										{this.state.billedItems.map((item, i) => {
											gstTotal += parseFloat(item.gst);
											return <tr key={i} >
												<td width="40" align="center" >{i + 1}</td>
												<td >
													<Form.Control
														size="sm"
														value={item.print_name || item.name}
													/>
													{/*<a href="javascript:;" 
													className="font-11 font-weight-600 pr-5" 
													style={{position: 'static',marginTop: '-23px',float: 'right'}} 
													onClick={this.setAddNotes.bind(this,i)} 
												>
													Add Notes
												</a> */}
													{item.add_notes == 1 ? <Form.Control
														type="text"
														size="sm"
														className="font-12"
														value={item.description}
														onChange={this.handleDescChange.bind(this, i, 'description')}
														placeholder="Description.."
													/> : null}
												</td>
												<td width="90">
													{item.batch_name}
												</td>
												<td width="90" align="center" >
													{item.expiry}
												</td>
												<td width="60" align="center" >
													<Form.Control
														type="text"
														size="sm"
														onKeyPress={decimalKeyPress}
														value={item.qty}
														className="text-right select-on-focus"
														onChange={this.handleQtyChange.bind(this, i)}
													/>
												</td>
												<td width="90" align="right" >{item.piece_sale_rate || item.mrp}</td>
												<td width="90" align="right" >{item.piece_sale_rate || item.amount}</td>
												<td width="90" align="right" >{item.gst_percentage}</td>
												<td width="90" align="right" >{item.gst}</td>
												<td width="90" align="right" >{item.total}</td>
												<td width="60" align="center" >
													<div onClick={this.handleRemoveItems.bind(this, i)} >
														<i className="icofont-trash"></i>
													</div>
												</td>
											</tr>;
										})
										}
										<tr>
											<td width="40" ></td>
											<td colSpan=""  >
												<Form.Control
													type="text"
													size="sm"
													placeholder="Search Product.."
													value=""
													onChange={this.handleProductSearch}
												/>
											</td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="60" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="90" ></td>
											<td width="60" ></td>
										</tr>
									</tbody>
									<input type="hidden" name="gst_total" value={gstTotal} />
								</table>
							</div>
							<table className="table table-bordered" >
								<tbody>
									<tr className="font-weight-600" style={{ backgroundColor: '#aee9ff' }} >
										<td width="40" ></td>
										<td></td>
										<td width="90" ></td>
										<td width="90" ></td>
										<td width="60" align="right"  >{this.getTotalByField('qty')}</td>
										<td width="90" ></td>
										<td width="90" ></td>
										<td width="90" ></td>
										<td width="90" align="right" >{this.getTotalByField('gst')}</td>
										<td width="90" align="right"  >{this.getTotalByField('total')}</td>
										<td width="60" ></td>
									</tr>
								</tbody>
							</table>

							<input type="hidden" name="grand_total" value={this.getTotal()} />
							<input type="hidden" name="i__netAmount" value={this.getNetAmount()} />
							<input type="hidden" name="i__discount" value="0" />

							<Row>
								<Col md={8} >

								</Col>
								<Col md={2} >
									<InputGroup size="sm" >
										<InputGroup.Text  >Round Off</InputGroup.Text>
										<Form.Control
											type="text"
											name="round_off"
											value={this.getRoundOff()}
											className="text-right font-weight-400"
										/>
									</InputGroup>
								</Col>
								<Col md={2} >
									<div className="text-right font-weight-600 pe-3 " style={{ fontSize: '25px' }}>
										&#8377; {this.getNetAmount()}
									</div>
								</Col>
							</Row>

						</div>

						<div className="panel-footer ">
							<Row className="" >
								<Col md={3} >
									<input type="hidden" name="sales_man_id" value={this.context.state.loggedUser.id} />
									<InputGroup size="sm" >
										<InputGroup.Text style={{ fontSize: '11px' }} >Sales Man</InputGroup.Text>
										<Form.Control
											type="text"
											name="sales_man_name"
											value={this.context.state.loggedUser.name}
										/>
									</InputGroup>
								</Col>
								<Col md={3} >

								</Col>
								<Col md={6} >
									<div className="text-right" >
										<Button type="reset" size="sm" variant="default" onClick={this.resetForm}>
											<i className="icofont-close pe-2 border-right me-2" ></i> Reset
										</Button>
										<Button type="submit" size="sm" variant="default" disable={this.state.showLoader} >
											<i className="icofont-check pe-2 border-right  me-2" ></i> Save Entry
										</Button>
									</div>
								</Col>

							</Row>
						</div>

					</Spin>
				</form>



				<Modal show={this.state.showItemModal} className="cb__modal__search cb__modal cb__modal__search" size="lg" backdrop="static" >
					<Modal.Body>

						<SearchProducts
							afterFinish={this.handleItemSelect}
							productList={this.state.productList}
							batchList={this.state.batchList}
							sales={this.props.type && this.props.type == 'return' ? false : true}
							wholesale={this.state.isWholeSale}
						/>

					</Modal.Body>
				</Modal>

				{this.state.showPrint && (<SalesView billid={this.state.billId} afterFinish={() => { this.setState({ billId: '', showPrint: false }) }} autoPrint />)}

				{/*<ModalConfirm 
					title="Confirm"
					content="Do you want to Print ?"
					confirmText="Yes"
					cancelText="No"
				/>*/}

			</div>
		);

	}

}
export default NewSalesEntryScreen;
