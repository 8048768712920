import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
import axios from 'axios';
import { message } from 'antd';

import PsContext from '../../../context/PsContext';
import { momentDate, upperCase } from '../../../utils';
import { Api } from '../../../utils/Variables';
import SalesView from '../Sales/SalesView';

import NoDataFound from '../components/NoDataFound';
import AddBankAccount from './addBankAccount';
import EditBankAccount from './editBankAccount';

const BankAccounts=()=>{
	
	const context = useContext(PsContext);
	
	const getToday=()=> momentDate((new Date()),'YYYY-MM-DD');
	
	const [fromDate, setFromDate] = useState(getToday());
	const [toDate, setToDate] = useState(getToday());
	const [loader, setLoader] = useState(false);
	const [cashInHand, setCashInHand] = useState(0);
	const [data, setData] = useState([]);
	
	const [billId, setBillId] = useState(null);
	const [viewData, setViewData] = useState([]);
	const [print, setPrint] = useState(false);
	const [view, setView] = useState(false);
	const [add, setAdd] = useState(false);
	const [edit, setEdit] = useState(false);
	
	const loadReport=()=>{
		setData([]);
		setLoader(true);
		
		axios.get(Api.Url+'v1/phar/bankaccounts?api='+context.state.api).then(res=>{
			if(res['data'].errorcode == '200'){
				setData(res['data'].data);
				setLoader(false);
			}
			else{
				//message.error(res['data'].message || 'Error');
				setLoader(false);
			}
		});
		
	};
	
	const currentAmount=(item)=>{
		if(parseFloat(item.credit_amount)>0){
			return <span className="text-danger">{item.credit_amount}</span>;
		}
		else{
			return <span className="text-success">{item.debit_amount}</span>;
		}
	};
	
	const showCashInHand=(amt)=>{
		if(parseFloat(amt)>0){
			return <span className="text-success">&#8377; {amt}</span>;
		}
		else{
			return <span className="text-danger">&#8377; {amt}</span>;
		}
	};
	
	useEffect(()=>{
		
		loadReport();
	},[]);
	
	return(
		<div className="panel panel-form">
		
			<div className="panel-header">
				<Row>
					<Col md={4} >
						Bank Accounts
					</Col>
					<Col md={8} >
						<div className="text-right" >
							<Button 
								type="button"
								size="sm"
								variant="primary"
								onClick={()=> loadReport() }
							>
								<i className="icofont-refresh pe-2 border-right"></i> Refresh
							</Button>
							&nbsp;
							<Button 
								type="button"
								size="sm"
								variant="primary"
								onClick={()=> setAdd(true) }
							>
								<i className="icofont-plus pe-2 border-right"></i> Add Bank Account
							</Button>
						</div>
					</Col>
				</Row>
				{/*<Row>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={fromDate}
							max={getToday()}
							onChange={(e)=> setFromDate(e.target.value) }
						/>
					</Col>
					<Col md={2} >
						<Form.Control
							type="date"
							size="sm"
							value={toDate}
							max={getToday()}
							onChange={(e)=> setToDate(e.target.value) }
						/>
					</Col>
					<Col md={1} >
						<Button
							type="button"
							size="sm"
							variant="default"
							block
							onClick={()=> loadReport() }
						>
							VIEW
						</Button>
					</Col>
				</Row>*/}
			</div>
			
			<div className="panel-body ">
				
					
					<div className="table-responsive mt-5" >
						<table className="table table-striped-lite table-hover tableFixHead ">
							<thead>
								<tr>
									<th width="60" >S.NO</th>									
									<th>BANK NAME</th>
									<th>ACCOUNT NO</th>
									<th width="120"  >BALANCE</th>
									<th width="100" >MANAGE</th>
								</tr>
							</thead>
							<tbody>
							{data && data.length>0 && (data.map((item,i)=> {
								return <tr key={i} >
									<td>{i+1}</td>
									<td>{item.name}</td>
									<td>{item.gstno}</td>
									<td align="right">{showCashInHand(item.current_balance)}</td>
									<td align="center">
										<Button 
											type="button"
											size="xs"
											variant="purple"
											onClick={()=>{
												setViewData(item);
												setEdit(true);
											}}
										>
										<i className="icofont-edit"></i>
										</Button>
									</td>
								</tr>;
							})
							)}
							</tbody>
						</table>						
					</div>					
					
					{loader && (<center>
						<Spinner animation="border" role="status" />
					 </center>)}
					
					<NoDataFound data={data} loader={loader} />
					
				
			</div>
			
			
			
			 {print && (<SalesView 
				billid={billId} 
				afterFinish={()=>{
					setBillId(null);
					setPrint(false);
				}}
				autoPrint 
			/>)}
			
			<Modal show={view} size="lg" onHide={()=>{
				setBillId(null);
				setView(false);
			}} >
				<Modal.Header closeButton>
					VIEW
				</Modal.Header>
				<Modal.Body>
					<SalesView 
						billid={billId} 
						afterFinish={()=>{
							setBillId(null);
							setView(false);
						}}								
					/>
				</Modal.Body>
			</Modal>
			
			<Modal show={add} onHide={()=> setAdd(false) } className="cb__modal__search" size="md" backdrop="static" >
				<Modal.Header closeButton >
					Add Bank Account
				</Modal.Header>
				<Modal.Body>
					<AddBankAccount afterFinish={loadReport} />
				</Modal.Body>
			</Modal>
			
			<Modal show={edit} onHide={()=> setEdit(false) } className="cb__modal__search" size="md" backdrop="static" >
				<Modal.Header closeButton >
					Edit Bank Account
				</Modal.Header>
				<Modal.Body>
					<EditBankAccount data={viewData} afterFinish={loadReport} />
				</Modal.Body>
			</Modal>
			
		</div>
	);
};
export default BankAccounts;