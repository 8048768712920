import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Spinner, Card } from 'react-bootstrap';

import PsContext from '../../../context/PsContext';
import { Api } from '../../../utils/Variables';
import API from '../../../utils/API';

import ZeroStock from './zeroStock';

const Dashboard=()=>{
	
	const context = useContext(PsContext);
	const [loader, setLoader] = useState(false);
	
	const [count, setCount] = useState([]);
	const [itemCount, setItemCount] = useState([]);
	const [bank, setBank] = useState([]);
	
	const loadCount=()=>{
		setLoader(true);
		//axios.get(`${Api.Url}/v1/phar/dashboard/count?api=${context.state.api}`).then(res=>{
		API.get(`/v1/phar/dashboard/count`).then(res=>{
			if(res['data'].errorcode=='200'){
				setCount(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadItemCount=()=>{
		setLoader(true);
		axios.get(`${Api.Url}/v1/phar/dashboard/count_2?api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setItemCount(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	const loadBanks=()=>{
		setLoader(true);
		axios.get(`${Api.Url}/v1/phar/dashboard/cash_bank_balance?api=${context.state.api}`).then(res=>{
			if(res['data'].errorcode=='200'){
				setBank(res['data'].data);
			}
			setLoader(false);
		});
	};
	
	
	useEffect(()=>{
		
		loadCount();
		loadItemCount();
		loadBanks();
	},[]);
	
	const countWithIcon=(title, amount, icon='icofont-money-bag' )=>{
		return <Card>
			<div className="widget-list-container">
				<div className="widget-list-media text-center">
					<i className={icon + " bg-gradient-orange"} style={{ padding: '6px 10px',fontSize: '40px'}} ></i>
				</div>
				<div className="widget-list-content">
					<div className="widget-desc mt-2">{title}</div>
					<h4 className="widget-title font-weight-600 mt-2">&#8377; {amount || '0.00'}</h4>
				</div>
			</div>
		</Card>;
	};

	const itemCountWithIcon=(title, count, icon='icofont-money-bag' )=>{
		return <div className="widget-list-container">
			<div className="widget-list-media icon icon-sm">
			 <i className={icon+" bg-muted text-dark font-16"}></i>
			</div>
			<div className="widget-list-content font-14 font-weight-600">
				{title}
			</div>
			<div className="widget-list-action">
				<div className="switcher switcher-success pull-left font-16">
					{count || ''}
				</div>
			</div>
		</div>;
	};
	
	const partyBalance=(title, amount, icon='icofont-money-bag' )=>{
		return <div className="widget-list-container">
			<div className="widget-list-media icon icon-sm">
			 <i className={icon+" bg-muted text-dark font-16"}></i>
			</div>
			<div className="widget-list-content font-12 text-uppercase font-weight-600" style={{width:'65%'}} >
				{title}
			</div>
			<div className="font-16 text-right" style={{width:'35%'}} >
				&#8377; {amount || ''}
			</div>
		</div>;
	};
	
	return(
	<>
		<Row>
			<Col md={9} >
				<div className="panel panel-form">
					<div className="panel-header">
						Dashboard
					</div>
					<div className="panel-body py-30 px-20 bg-gradient-dark-blue-light" style={{ height: 'auto' }} >
						<Row>
							<Col md={3} >
								{countWithIcon('Sales', count.sales, 'icofont-database')}
							</Col>	
							<Col md={3} >
								{countWithIcon('Purchase', count.purchase, 'icofont-basket')}
							</Col>	
							<Col md={3} >
								{countWithIcon('Payment In', count.paymentin)}
							</Col>	
							<Col md={3} >
								{countWithIcon('Payment Out', count.paymentout, 'icofont-pay')}
							</Col>	
						</Row>
					</div>
				</div>
				
				<Row>
					<Col md={5} >
						<div className="panel panel-form" >
							<div className="panel-header">Cash & Bank Balance</div>
							<div className="panel-body" style={{height:'141px', overflowY:'auto'}} >
								{bank && bank.length>0 && (bank.map((item,i)=>{
								 return partyBalance(item.name,item.current_balance);
								}))
								}
							</div>
						</div>
					</Col>
					
					<Col md={4} >
						<div className="panel panel-form" >
							<div className="panel-header">Total</div>
							<div className="panel-body" style={{height:'auto'}} >
								{itemCountWithIcon('Total Parties',itemCount.customers)}
								{itemCountWithIcon('Total Products',itemCount.products)}
								{itemCountWithIcon('Products in Zero Stock',itemCount.zerostock)}
							</div>
						</div>
					</Col>
				</Row>
				
			</Col>	
			
			<Col md={3} >
				<ZeroStock />
			</Col>
			
		</Row>
		
	</>
	);
};

export default Dashboard;