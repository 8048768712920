import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Modal } from 'react-bootstrap';
import { Table } from 'antd';


import $ from 'jquery';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, typeName
} from '../../../utils';
import axios from 'axios';
import {Api} from '../../../utils/Variables';
import {Spin, message, Select } from 'antd';
import{ LineLoader} from '../../../views/elements';
import PsContext from '../../../context/PsContext';

import NoDataFound from '../components/NoDataFound';

const { Option } = Select;

class PartyWiseBalance extends Component{


static contextType = PsContext;

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showPrintModal: false,
			showViewModal: false,
			showBalanceModal: false,
			billid : '',
			fromDate : '',
			supplier : '',
			supplierList: [],
			dataList: [],
			dataView: [],
			paginationPageSize : 20,
			currentPage: 1,
		}
		this.handleSearch = this.handleSearch.bind(this);
		this.loadData = this.loadData.bind(this);
		this.loadSuppliers = this.loadSuppliers.bind(this);
		this.handleSupplierChange = this.handleSupplierChange.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleTablePageChange = this.handleTablePageChange.bind(this);
		this.onFromDateChange = this.onFromDateChange.bind(this);
	}  
	
	onFromDateChange(e){
		
		$("#todate").val(momentDate((new Date()),'YYYY-MM-DD'));
		this.setState({fromDate : e.target.value});
		
	}
	
	showPrint(billid){
		
		this.setState({showPrintModal : true, billid : billid});
		 
	}
	
	showView(billid){
		
		this.setState({showViewModal : true, billid : billid});
		
	}
	
	showBalance(billid){
		
		this.setState({showBalanceModal : true, billid : billid});
		
	}

	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}

	handleTablePageChange(current){
		this.setState({currentPage: current});
	}
	
	handleSupplierChange(value){
		var s = this.state.supplierList;
		var sdata = s.filter(function(obj){
			return(obj.id == value);
		});
		if(sdata.length > 0) {
			this.setState({selectedSupplier : sdata[0]});
		}
		
		this.setState({
			supplier : value,
			dataList: [],
			dataView: [],
		});
	}


	componentDidMount(){
		this.loadSuppliers();
	} 

	loadData(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('from_date',$("#fromdate").val());
			form.append('to_date',$("#todate").val());
			
			form.append('supplier',this.state.supplier);
			form.append('i__accountingYear',getls('loggedAcyear','id'));
			form.append('hospital_id',getls('loggedCompany','id'));
			this.setState({dataList : [],dataView : []});
			axios.post(Api.Url+'v1/phar/purchase/party-balance', form).then(res=>{
				if(res['data'].status=='1'){
				this.setState({purchaseList : res['data'].data,showLoader : false});
					this.setState({
					dataList: res['data'].data,
					dataView: res['data'].data,
					showLoader: false,
					});
				}
				else{
					//message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	loadSuppliers(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.api());
			form.append('hospital_id',getls('loggedCompany','id'));

			axios.post(Api.Url+'v1/phar/get_supplier', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({supplierList : res['data'].data,showLoader : false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}


	handleSearch(e){
		try{
			var d = this.state.dataList;
			var v = upperCase(e.target.value);
			var s = d.filter(function(obj){
			return( (upperCase(obj.billno).indexOf(v)>-1) );
			});
			this.setState({dataView: s});
		}
		catch(er){console.log(er); }
	}


	render(){

		const columns = [
			{
				title: 'S.No',
				dataIndex: 'id',
				key: 'id',
				render:(text, record, index)=>(
				<>
				{(this.state.currentPage - 1) * this.state.paginationPageSize + (index+1)}
				</>
				)
			},
			  
			{
				title: 'Bill No',
				dataIndex: 'serial_number',
				key: 'serial_number',
			},
			
			{
				title: 'Bill Date',
				dataIndex: 'voucher_date',
				key: 'voucher_date',
			},
			
			{
				title: 'Type',
				dataIndex: 'description',
				key: 'description',
			},
			
			{
				title: 'Debit',
				dataIndex: 'debit_amount',
				key: 'debit_amount',
				render:(text, record, index)=>(
				<>
				{record.voucher_type == 'purchase_payment' ? record.credit_amount : 0}
				</>
				)
			},
			
			{
				title: 'Credit',
				dataIndex: 'credit_amount',
				key: 'credit_amount',
				render:(text, record, index)=>(
				<>
				{record.voucher_type == 'credit_purchase' ? record.credit_amount : 0}
				</>
				)
			},
			
		];

		const runningBalance=(v)=>{
			if(parseFloat(v)>0){
				return <span>{Math.abs(v).toFixed(2)} (Cr)</span>;
			}
			else{
				return <span>{Math.abs(v).toFixed(2)} (Dr)</span>;
			}
		}

		return(
		<div className="row">
			<div className="col-md-10" >
			
			<div className="panel panel-form">
				<LineLoader loading={this.state.showLoader} />
				
				<div className="panel-header" >
					<Row>
						<Col md={12} >
							Party Wise Statement
						</Col>
					</Row>
				</div>
				
				<div className="panel-body" >
						<Row>
						<Col md={2}>
										<Select
										  showSearch
										  style={{ width: 180 }}
										  title="Supplier"
										  placeholder="Select a Supplier"
										  optionFilterProp="children"
										  onChange={this.handleSupplierChange}
										  style={{width: '100%'}}
										  value={this.state.supplier}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.supplierList && this.state.supplierList.map((item) => (
												<Option key={item.id} value={item.id}>{item.name}</Option>
										  ))}
										</Select>
										</Col>
							<Col md={3}>
										<table width="100%" >
									<tr>		
										<td>
											<label className="font-13">From Date</label>
										</td>
										<td>
											<Form.Control
											type="date"
											size="sm"
											name="i__billDate"
											id="fromdate"
											max={momentDate((new Date()),'YYYY-MM-DD')}
											onChange={this.onFromDateChange}
											/>
										</td>
									</tr>
								</table>
							</Col>
							<Col md={3}>
								<table width="100%" >
									<tr>
										<td>
											<label className="font-13">To Date</label>
										</td>
										<td>
											<Form.Control
											type="date"
											size="sm"
											name="i__billDate"
											id="todate"
											min={momentDate((this.state.fromDate),'YYYY-MM-DD')}
											max={momentDate((new Date()),'YYYY-MM-DD')}
											/>
										</td>
									</tr>
								</table>
							</Col>
							<Col md={1}>
								<Button
									type="button"
									size="sm"
									variant="primary"
									onClick={this.loadData}
								>
								View
								</Button>
							</Col>
							<Col md={3}>
								<div className="text-right">
									<Form.Control type="text" size="sm" name="i__search" placeholder="Search by Billno..." onChange={this.handleSearch} />
								</div>
							</Col>
					</Row>
				
			
				
				{/*<div className="mt-10 ps-table" >
					<Table
						size="small"
						columns={columns}
						bordered
						footer={() => `Total ${Object.keys(this.state.dataView).length} data in list`}
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						rowClassName={ (record, index) => {  return record.status=='0' ? 'bg-danger-light' : null; }   }
						pagination={{
						pageSize: this.state.paginationPageSize,
						onShowSizeChange: this.handlePaginationSizeChange,
						position: ['bottomRight'],
						onChange: this.handleTablePageChange,
					}} />
				</div>*/}
				
						<div className="table-responsive mt-10">
							<table className="table table-striped-lite tableFixHead" >
								<thead>
									<tr>
										<th width="120"  >DATE</th>
										<th>TXN TYPE</th>
										<th>REF.NO</th>
										<th>PAYMENT TYPE</th>
										<th width="120" >DEBIT</th>
										<th width="120" >CREDIT</th>
										<th width="120" >CUR. BALANCE</th>
									</tr>
								</thead>
								<tbody>
								{this.state.dataView.map((item,i)=>{
									return <tr key={i} >
										<td>{momentDate(item.voucher_date, 'DD/MM/YYYY')}</td>
										<td>{typeName(item.voucher_type)}</td>
										<td>{item.invoice_id}</td>
										<td>{item.payment_type}</td>
										<td align="right" >{item.debit_amount}</td>
										<td align="right"  >{item.credit_amount}</td>
										<td align="right"  >{runningBalance(item.running_balance)}</td>
									</tr>;
								})
								}
								</tbody>
							</table>
						</div>
						
						
						<NoDataFound data={this.state.dataView} loader={this.state.showLoader} />
				</div>
				
			</div>
			
			</div>
		</div>
		);
	}
}

export default PartyWiseBalance