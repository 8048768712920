import React from 'react';
import { NavLink } from 'react-router-dom';
import NavBadge from './NavBadge';
import {Tooltip} from 'antd';

const NavSingleItem = ({ item }) => {
  const Icon =  null;
  const ionIcon = item.ionicon ? <i className={item.ionicon} ></i> : null;
  if (item.external) {
    return (
      <li className="nav-item">
        <a href={item.url}>
			{/*{item.icon && Icon && <Icon className="side-nav-icon" />}*/}
				{ionIcon}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
        </a>
      </li>
    );
  } else {
    return (
      <li className="nav-item">
	  {/*		<Tooltip title={item.name}  placement="topLeft" > */}
			<NavLink to={item.url} className="nav-link" activeClassName="active" exact={item.exact} >
				{ionIcon} {item.name}
			</NavLink>
			{/*</Tooltip>*/}
      </li>
    );
  }
};

export default NavSingleItem;
