import React, { useContext, useState } from 'react';
import { Link, NavLink, withRouter, useHistory } from 'react-router-dom';
import { NavDropdown, Nav } from 'react-bootstrap';
import PsContext from '../../../../context/PsContext';
import { upperCase } from '../../../../utils';
import LOGO from '../../../../assets/img/logo_150.png';

const Header = (props) => {

	const context = useContext(PsContext);
	const [show, setShow] = useState(false);
	const history = useHistory();

	const logoutClick = () => {
		if (!window.confirm('Do you want to logout?')) {
			return;
		}
		context.logout();
	};

	if (context.state.isLogged != 'YES') {

		history.push('/');
		return null;
	}
	else {

		const dopdownChild = (items) => items.map((item) => {
			if(item.divider)
			return <li className="divider" ></li> 
			else{
				return item.allowed.indexOf(props.role.toLowerCase()) > -1 ?
				 <li><NavLink to={item.url} exact={item.exact} >{item.name}</NavLink></li>:null
			}
			
		}
		);

		const dropdownMenu = (item) => {


			return <li className="drop-down" >
				<a>{item.name}</a>
				<ul>
					{dopdownChild(item.children)}
				</ul>
			</li>;



		};

		const topMenu = (item) => {
			if (item.children && item.children.length > 0) {
				return dropdownMenu(item);
			}
			else {
				return <li>
					<NavLink
						to={item.url}
						exact={item.exact}
					>
						{item.name}
					</NavLink>
				</li>;
			}
		};


		const showDropdown = (e) => {
			setShow(!show);
		}
		const hideDropdown = e => {
			setShow(false);
		}

		return (
			<div id="header" className="header navbar navbar-default fixed-top">
				<div className="container-fluid">

					<div className="navbar-header">
						<Link to="/" className="navbar-brand border-end">
							<img src={LOGO} style={{ width: '80px' }} />
							{/*<b><span className="text-info">YES</span> <i className="icofont-checked"></i> <span className="text-danger">BILL</span></b>*/}
						</Link>
					</div>

					{/*<Nav className="me-auto">
						{props.nav.top.map(item => topMenu(item))}
				</Nav>*/}

					<nav className="nav-menu me-auto">
						<ul>
							{props.nav.top.map(item => item.allowed.indexOf(props.role.toLowerCase()) > -1 ? topMenu(item) : null)}
						</ul>
					</nav>

					<div className="navbar-xs-justified ">
						{/*<NavDropdown title={<button className="btn btn-sm text-dark font-weight-600">
								{upperCase(context.state.loggedPharmacy.name)} <i className="icofont-ui-user ps-1 ms-1 border-left"></i>
							</button>} >
							  <NavDropdown.Item href="" >My Profile</NavDropdown.Item>
							  <NavDropdown.Divider />
							  <a className="dropdown-item" onClick={()=> logoutClick() } >Logout</a>
					</NavDropdown>*/}
						<nav className="nav-menu">
							<ul>
								<li className="drop-down">
									<a>{upperCase(context.state.loggedPharmacy.name)}</a>
									<ul>
										<li><a>My Profile</a></li>
										<li><a onClick={() => logoutClick()} >Logout</a></li>
									</ul>
								</li>
							</ul>
						</nav>

					</div>

				</div>


				{/*<div id="headers" className="header navbar navbar-default " style={{top:'45px',zIndex:'9'}} >

				<div className="container-fluid">
					
				</div>
				</div>*/}

			</div>
		);
	}
};
export default withRouter(Header);