import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import NewSalesEntryScreen from './NewSalesEntryScreen';

class NewSalesReturnEntry extends Component{

	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			
		}
	}
	
	render(){


		return(
			<Row>
			
				<Col md={10} >
				
					<NewSalesEntryScreen type="return" />
					
				</Col>
				
			</Row>
		);
	}

}
export default NewSalesReturnEntry;