import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

class ModalConfirm extends Component{
		
	constructor(props){
		super(props);
		this.state = {
			
		}
		
		
	}
	
	cancelClick(){
		if(this.props.cancelClick)
			this.props.cancelClick();
	}
	
	okClick(){
		if(this.props.okClick)
			this.props.okClick();
	}
	
	onClose(){
		if(this.props.onClose)
			this.props.onClose();
	}
	
	render(){
		
		return(
			<div >
				<Modal 
					show={this.props.show} 
					onHide={this.onClose.bind(this)} 
					className="cb__modal"  
					backdrop="static" 
					size="md" 
				>
					<Modal.Header >
						Hosaid
					</Modal.Header>
					<Modal.Body >
							
						{!this.props.ajax && (<Row>
							<Col md={2} >
								<div className="text-right font-20" style={{ marginTop: -4 }} >
								{this.props.type=='confirm' && (<i className="icofont-question text-warning"></i>)}
								{this.props.type=='warning' && (<i className="icofont-warning-alt text-warning"></i>)}
								{this.props.type=='error' && (<i className="icofont-close-squared-alt text-danger"></i>)}
								{!this.props.type && (<i className="icofont-check-circled text-success"></i>)}
								</div>
							</Col>
							<Col md={10} >
								<span style={{ fontWeight: '500' }} >{this.props.title}</span>
								<div style={{ fontSize: '13px' }}>
									{this.props.content}
								</div>
							</Col>
						 </Row>)}
						
						{!this.props.ajax && (<Row className="mt-20" >
							<Col md={12} >
								<div className="text-right" >
								{this.props.type=='confirm' && (<>
									<Button type="button" size="sm" variant="light" className="rounded-5 px-10" onClick={this.cancelClick.bind(this)}  >
										{this.props.cancelText || 'Cancel'}	
									</Button>
									&nbsp;
									<Button type="button" size="sm" className="rounded-5 px-10" onClick={this.okClick.bind(this)}  >
										{this.props.okText || 'Ok'}
									</Button>
								</>)}
								{this.props.type != 'confirm'&& (
									<Button type="button" size="sm" className="rounded-5 px-10" onClick={this.okClick.bind(this)} >
										{this.props.okText || 'Ok'}
									</Button>
								)}
								</div>
							</Col>
						</Row>)}
						
						{this.props.ajax && (<Row>
							<Col md={12} >
								<div className="text-center" >
									<div className="spinner-border" role="status">
									  <span className="sr-only">Loading...</span>
									</div>
									<br /><br />{this.props.ajaxText || 'Processing...'}
									<br /><br />
								</div>								
							</Col>
						</Row>)}
					
					</Modal.Body>
				</Modal>
			</div>
		);
	}
	
}
export default ModalConfirm;
