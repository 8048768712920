import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import PsContext from '../../../context/PsContext';
import { capitalizeFirst, upperCase } from '../../../utils';

import Batches from './batches';

const BilledItemName=(props)=>{
	
	const context = useContext(PsContext);
	
	const { product } = props;
	
	const [deleteModal, setDeleteModal] = useState(false);
	
	const handleRemove=()=>{
		
		if(props.onRemove)
			props.onRemove(product);
		setDeleteModal(false);
	};
	
	return(
	<>
		<Row className="border-bottom pb-1 mb-1 font-12" >
			<Col md={9} >
				<div className="font-weight-600 font-13">{upperCase(product.name)}</div>
				{product.uom_name ? product.uom_name+' - ' : ''}  Rs.{product.uom_amount || product.piece_sale_rate} x {product.uom_qty || product.qty}
				{product.description}
			</Col>
			<Col md={2} className="d-flex align-items-center justify-content-end" >
				{product.total}
			</Col>
			<Col md={1} className="d-flex align-items-center justify-content-end" >
				<Button size="xs" variant="light" onClick={e=>setDeleteModal(true)} >
					<i className="icofont-trash"></i>
				</Button>
			</Col>
		</Row>
			
		<Modal
			show={deleteModal}
			onHide={()=>setDeleteModal(false)}
			backdrop="static"
			size="sm"
		>
			<Modal.Header >Delete Confirmation</Modal.Header>
			<Modal.Body className='text-center' >
				Do you want to delete the Item
				<h3>{upperCase(product.name)}</h3>
				<div className="row mt-15">
					<div className="col-sm-6">
						<Button
							type="button"
							className="btn-block"
							size="lg"
							onClick={e=>handleRemove()}
						>
							YES
						</Button>
					</div>
					<div className="col-sm-6">
						<Button
							type="button"
							className="btn-block"
							variant="danger"
							size="lg"
							onClick={e=>setDeleteModal(false)}
						>
							NO
						</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</>
	);
};
export default BilledItemName;